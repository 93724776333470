const filterTransactions = (transactions, transactionType, transactionSearch) => {
    if (transactionType) {
      return transactions.filter((value) => value.method === transactionType);
    }
  
    const searchTerm = transactionSearch?.toLowerCase();
    return transactions.filter((value) => {
      const date = value.transaction_date?.toLowerCase();
      const title = value.service?.toLowerCase();
      const transactionId = value.transaction_id?.toLowerCase();
      const consumer = value.consumer_name?.toLowerCase();
      const vendor = value.vendor_name?.toLowerCase();
      const amount = String(value.amount)?.toLowerCase();
      const serviceName = value.service_name?.toLowerCase();
  
      return (
        date?.includes(searchTerm) ||
        transactionId?.includes(searchTerm) ||
        title?.includes(searchTerm) ||
        consumer?.includes(searchTerm) ||
        vendor?.includes(searchTerm) ||
        amount?.includes(searchTerm) ||
        serviceName?.includes(searchTerm)
      );
    });
  };
  
  export default filterTransactions;