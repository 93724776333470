export const filterDataByDateRange = (data, period) => {
  const now = new Date();

  return data.filter((item) => {
    const transactionDate = new Date(item.transaction_date);

    if (period === "week") {
      const weekAgo = new Date();
      weekAgo.setDate(now.getDate() - 7); 
      return transactionDate >= weekAgo && transactionDate <= now;
    }

   
    if (period === "month") {
      const monthAgo = new Date();
      monthAgo.setMonth(now.getMonth() - 1); 
      return transactionDate >= monthAgo && transactionDate <= now;
    }

    if (period === "year") {
      const yearAgo = new Date();
      yearAgo.setFullYear(now.getFullYear() - 1); 
      return transactionDate >= yearAgo && transactionDate <= now;
    }


    return true;
  });
};

export const filterProjectDataByDateRange = (data, range) => {
  const now = new Date();

  return data.filter((item) => {
    const itemDate = new Date(item.start_date); 
    if (range === "week") {
      const oneWeekAgo = new Date(now.setDate(now.getDate() - 7));
      return itemDate >= oneWeekAgo;
    } else if (range === "month") {
      const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
      return itemDate >= oneMonthAgo;
    } else if (range === "year") {
      const oneYearAgo = new Date(now.setFullYear(now.getFullYear() - 1));
      return itemDate >= oneYearAgo;
    } else {
      return true; 
    }
  });
};
