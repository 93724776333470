import React, { useEffect, useState } from "react";
import ConsumeSide from "./consumerSide";
import VendorSide from "./vendorsSide";
import ResolutionDecision from "./resolutionDesionsCheckboxes";
import SucessModalDispute from "../../sucessModal";

const InnerCard = ({reviewData,reviwTrigger}) => {

  const [activeIndex, setActiveIndex] = useState(0);

const date = new Date(reviewData?.date_of_complain)
const formattedDate = date.toDateString()

const resolutiondate = new Date(reviewData?.resolution_date)
const dateOfResolution = resolutiondate.toDateString()
  return (
    <div className="">
      <div className="rounded-[17px] bg-white drop-shadow-md">
        <div className="flex flex-col border-b-[0.5px] border-[#AEAEAE] px-5 py-6 text-[18px]">
        <div className="flex items-center justify-between ">
          <div className="">
            <h3 className="text-[18px] text-[#5F5F5F]">Dispute ID</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">
              {reviewData?.dispute_id||'-'}
            </span>
          </div>
          <div className="">
            <h3 className="text-[18px] text-[#5F5F5F]">Project ID</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">
              {reviewData?.project_id ||'-'}
            </span>
          </div>
          <div className="">
            <h3 className="text-[18px] text-[#5F5F5F]">Project Title</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">
              {reviewData?.project_title ||'-'}
            </span>
          </div>
          <div className="">
            <h3 className="text-[18px] text-[#5F5F5F]">Date of Complain</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">
              {reviewData&&formattedDate ||'-'}
            </span>
          </div>
        </div>
        {
          reviewData?.status === "resolved" && <div className="mt-5 flex items-center gap-32">  
            <div className="">
          <h3 className="text-[18px] text-[#5F5F5F]">Date of Resolution</h3>
          <span className="text-[20px] font-medium text-[#002D3A]">
            {reviewData&&dateOfResolution||'-'}
          </span>
        </div>
        <div className="">
            <h3 className="text-[18px] text-[#5F5F5F]">Amount Refunded</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">
            ₦{reviewData?.decision  === "consumer" ? reviewData?.amount_to_consumer :reviewData?.decision  === "vendor" ? reviewData?.amount_to_vendor :`₦${reviewData?.amount_to_consumer}  ₦${reviewData?.amount_to_vendor}` ||'-' }
            </span>
          </div>
          <div className="">
            <h3 className="text-[18px] text-[#5F5F5F]">Resolution reached</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">
            Awarded to {reviewData?.decision||'-'}
            </span>
          </div>
          </div>
        }
        </div>
        <div className="flex border-b-[0.5px] border-[#AEAEAE] px-5 py-3">
          <button
            className={`w-[50%] rounded-[5px] px-4 py-2 ${activeIndex === 0 ? "bg-[#0096C1] text-white" : "bg-white text-[#5F5F5F]"}`}
            onClick={() => setActiveIndex(0)}
          >
            Consumer side
          </button>
          <button
            className={`w-[50%] rounded-[5px] px-4 py-2 ${activeIndex === 1 ? "bg-[#0096C1] text-white" : "bg-white text-[#5F5F5F]"}`}
            onClick={() => setActiveIndex(1)}
          >
            Vendor side
          </button>
        </div>
        <div className="">{activeIndex === 0 && <ConsumeSide reviewData={reviewData} reviwTrigger={reviwTrigger}/>}</div>
        <div className="">{activeIndex === 1 && <VendorSide reviewData={reviewData} reviwTrigger={reviwTrigger}/>}</div>
      </div>
    {
      reviewData?.status === "resolved" ?<SucessModalDispute/> : <ResolutionDecision reviewData={reviewData}/>
    }
    </div>
  );
};

export default InnerCard;
