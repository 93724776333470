import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Alert = () => {
  const location = useLocation();
  const [fill, setFill] = useState("#5F5F5F"); // Initialize with a default value
  const [pathname, setPathname] = useState(location.pathname); // Initialize with location.pathname
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]); // Dependency array should include location.pathname

  useEffect(() => {
    const path = pathname?.substring(pathname.lastIndexOf("/") + 1);
    if (path === "alerts" && !isMobile) {
      setFill("#fff");
    }
  else if (path === "alerts" && isMobile) {
      setFill("#0096C1");
    } else {
      setFill("#5F5F5F");
    }
  }, [pathname]);

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
        <path
        d="M20 17H22V19H2V17H4V10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10V17ZM18 17V10C18 6.68629 15.3137 4 12 4C8.68629 4 6 6.68629 6 10V17H18ZM9 21H15V23H9V21Z"
        fill={fill}
      />
    </svg>
  );
}

export default Alert;

