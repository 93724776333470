import React from "react";
import { location, calendar } from "../../../../../constants/images";
const TrackJobsCard = ({ title, loation, posted_date, budget, applications,onClick }) => {
  return (
    <div className="border-t-2 py-3 cursor-pointer"onClick={onClick}>
      <h3 className="md:text-[20px] text-[16px] font-medium text-[#002D3A]">{title}</h3>
      <div className="mt-1 flex items-center space-x-2 text-[16px] font-medium text-[#5F5F5F]">
        <div>
          <img src={location} alt="location" />
        </div>
        <span>{loation}</span>
      </div>
      <div className="flex items-center space-x-2 text-[16px] font-medium text-[#002D3A] md:hidden">
          <div>
            <img src={calendar} alt="calendar" />
          </div>
          <span>{posted_date}</span>
        </div>
      <div className=" mt-1 flex items-center justify-between">
        <div className="md:flex items-center space-x-2 text-[16px] font-medium text-[#002D3A] hidden">
          <div>
            <img src={calendar} alt="calendar" />
          </div>
          <span>{posted_date}</span>
        </div>
        <span className="text-[16px] font-medium text-[#002D3A]">{budget}</span>
        <span className="text-[14px] text-[#002D3A]">
          Applications: <span className="text-[16px] font-medium ">{applications}</span>
        </span>
      </div>
    </div>
  );
};

export default TrackJobsCard;
