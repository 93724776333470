import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../../../../utils/dateUtils";
import { GroupCircle, singleCircle } from "./images";
const ProjectTimeLine = ({ projectDetails }) => {
  const accessToken = useSelector((state) => state?.token.states.accessToken)
  const projectId = useSelector((state) => state.modalOverlay.states.projectId);
  const [projecttiemLine, setProjecttimeline] = useState();

  const fetchProjectTimeLine = async (id) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/request-service/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setProjecttimeline(data || []);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchProjectTimeLine(projectId);
  }, [projectId]);
  return (
    <div>
      <div className="flex flex-col gap-y-10 px-2 py-2 ">
        <div className="h-fit w-full border border-[#AEAEAE]">
          {projectDetails?.status !== "cancelled" && (
            <div className="flex flex-col gap-y-5 px-4  py-2 pb-12">
              <span className="text-lg font-semibold text-secondary">Project timeline</span>
              <div className="flex flex-col gap-y-8">
                <div className="flex items-center gap-x-3">
                  {projecttiemLine?.vendor_confirmation ? (
                    <img src={GroupCircle} alt="timeline" />
                  ) : (
                    <img src={singleCircle} alt="timeline" />
                  )}
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-lightgray">Vendor confirmation</span>
                    <span className="text-xs font-normal text-lightgray ">
                      {formatDate(projecttiemLine?.vendor_confirmation)}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col ">
                  <div className="flex  items-center gap-x-3">
                    {projecttiemLine?.funded ? (
                      <img src={GroupCircle} alt="timeline" />
                    ) : (
                      <img src={singleCircle} alt="timeline" />
                    )}
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-lightgray">
                        Funded (Escrow protection)
                      </span>
                      <span className="text-xs font-normal text-lightgray ">
                        {formatDate(projecttiemLine?.funded)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center gap-x-3">
                    {projecttiemLine?.delivered ? (
                      <img src={GroupCircle} alt="timeline" />
                    ) : (
                      <img src={singleCircle} alt="timeline" />
                    )}
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-lightgray">Delivered</span>

                      <span className="text-xs font-normal text-lightgray">
                        {formatDate(projecttiemLine?.delivered)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center gap-x-3">
                    {projecttiemLine?.accepted ? (
                      <img src={GroupCircle} alt="timeline" />
                    ) : (
                      <img src={singleCircle} alt="timeline" />
                    )}
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-lightgray">Accepted</span>
                      <span className="text-xs font-normal text-lightgray">
                        {formatDate(projecttiemLine?.accepted)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {projectDetails?.status === "cancelled" && (
            <div className="flex flex-col gap-y-5 px-4  py-2 pb-12">
              <span className="text-lg font-semibold text-secondary">Project timeline</span>
              <div className="flex flex-col gap-y-8">
                <div className="flex items-center gap-x-3">
                  {projecttiemLine?.vendor_confirmation ? (
                    <img src={GroupCircle} alt="timeline" />
                  ) : (
                    <img src={singleCircle} alt="timeline" />
                  )}
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-lightgray">Vendor confirmation</span>
                    <span className="text-xs font-normal text-lightgray ">
                      {formatDate(projecttiemLine?.vendor_confirmation)}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col ">
                  <div className="flex  items-center gap-x-3">
                    {projecttiemLine?.funded ? (
                      <img src={GroupCircle} alt="timeline" />
                    ) : (
                      <img src={singleCircle} alt="timeline" />
                    )}
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-lightgray">
                        Funded (Escrow protection)
                      </span>
                      <span className="text-xs font-normal text-lightgray ">
                        {formatDate(projecttiemLine?.funded)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center gap-x-3">
                    {projecttiemLine?.delivered ? (
                      <img src={GroupCircle} alt="timeline" />
                    ) : (
                      <img src={singleCircle} alt="timeline" />
                    )}
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-lightgray">Delivered</span>

                      <span className="text-xs font-normal text-lightgray">
                        {formatDate(projecttiemLine?.delivered)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center gap-x-3">
                    {projecttiemLine?.accepted ? (
                      <img src={GroupCircle} alt="timeline" />
                    ) : (
                      <img src={singleCircle} alt="timeline" />
                    )}
                    <div className="flex flex-col">
                      <span className="text-sm font-medium text-lightgray">Accepted</span>
                      <span className="text-xs font-normal text-lightgray">
                        {formatDate(projecttiemLine?.accepted)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {projectDetails?.status === "cancelled" && (
            <div className="flex flex-col gap-y-5 px-10 py-2 pb-12">
              <span className="text-lg font-semibold text-secondary">Project timeline</span>
              <div className="flex flex-col gap-y-8">
                <div className="flex items-center gap-x-3">
                  <img src={""} alt="timeline" />
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-lightgray">Vendor confirmation</span>
                    <span className="text-xs font-normal text-lightgray">18 Dec 2023</span>
                  </div>
                </div>
                <div className="flex items-center gap-x-3">
                  <img src={""} alt="timeline" />
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-lightgray">Cancelled</span>
                    <span className="text-xs font-normal text-lightgray">21 Dec 2023</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {projectDetails?.status === "cancelled" && (
          <div className="h-fit w-5/6 border border-[#AEAEAE]">
            <div className="flex flex-col gap-y-2 px-10 py-2 pb-12">
              <span className="text-sm font-medium text-lightgray">Reason for cancellation</span>
              <span className="text-lg font-medium text-[#181818]">
                I’ve got a better deal elsewhere
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectTimeLine;
