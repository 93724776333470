
import React, { useState } from "react";
import Container from "../container";
import { jobscartoonimage } from "../../../constants/images";
import PostJobForm from "./components/forms";

const Jobs = () => {
  const [currentStep, setCurrentStep] = useState(1); // Manage the step state in the parent

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  return (
    <Container>
      <div className="md:px-10 px-5 py-20">
        <div className="flex items-center justify-between">
          <div className="md:w-[40%] md:block hidden">
            <div>
              <img src={jobscartoonimage} alt="jobscartoonimage" />
            </div>
            <h4 className="text-xl font-semibold text-secondary">Need a Specific Service?</h4>
            <p className="text-lg leading-7">
              For a personalized approach, post your specific job requirements directly.{" "}
            </p>
          </div>
          <div className="md:w-[60%] w-full">
            <PostJobForm currentStep={currentStep} nextStep={nextStep} prevStep={prevStep} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Jobs;
