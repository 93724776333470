import React from "react";
import { useDispatch } from "react-redux";
import { setShowAddPermission } from "../../../../../../redux/slice/modalOverlay";

const Navbar = () => {
  const dispatch = useDispatch();

  const handleAddNewAdvert = () => {
    dispatch(setShowAddPermission(true));
  };

  return (
    <div className="mb-10 w-full rounded-[10px] rounded-bl-none rounded-br-none bg-primary shadow-lg">
      <div className="flex w-full items-center  justify-between px-12 py-4">
        <div className="text-[25px] font-medium text-secondary">Permissions</div>
        <span className="text-base text-lightblue cursor-pointer" onClick={handleAddNewAdvert}>
          Add new
        </span>
      </div>
    </div>
  );
};

export default Navbar;
