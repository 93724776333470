import React, { useState } from "react";
import Navbar from "../../_components/ui/navbar";
import SideBar from "../../_components/ui/sidebar";
import { useSelector } from "react-redux";
import HomeContent from "../home/_components/main-content";
import SearchContent from "../home/_components/search_content";
import InputSearch from "../../_components/input_search";
import UserSwitchModal from "../../_components/ui/user_switch-modal";

const VendorDashboardHome = () => {
  const [hideSearch, setHideSearch] = useState();
  const searchValue = useSelector((state) => state?.search.vendorSearch);
  const serviceRequestValue = useSelector((state) => state?.overlay.displayStates.servicerequest);
  const showVendorSwitch = useSelector((state) => state?.overlay.displayStates.showVendorSwitch);
  const sendHideSearch = (value) => {
    setHideSearch(value);
  };
  return (
    <div>
      <Navbar />
      <SideBar />
      {showVendorSwitch && <UserSwitchModal />}
      <div className={`block w-full px-8 pt-6 md:w-5/6 md:pt-0 ${serviceRequestValue && "hidden"}`}>
        <h1 className="py-4 text-[25px] font-semibold text-secondary">Hello, John</h1>
        <div className="block md:hidden">
          <InputSearch />
        </div>
      </div>

      {searchValue === "" ? (
        <HomeContent sendHideSearch={sendHideSearch} />
      ) : (
        <SearchContent searchValue={searchValue} />
      )}
    </div>
  );
};

export default VendorDashboardHome;
