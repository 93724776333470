export const groupByKeys = (data, keys, aggregateFields = ["amount", "commission"]) => {
  return data.reduce((acc, item) => {
    const formattedDate = new Date(item.transaction_date).toISOString().split("T")[0];
    const key = keys.includes("transaction_date")
      ? formattedDate
      : keys.map((k) => item[k]).join("_");

    if (!acc[key]) {
      acc[key] = { ...item, transaction_date: formattedDate };
      aggregateFields.forEach((field) => (acc[key][field] = 0));
    }

    aggregateFields.forEach((field) => {
      acc[key][field] += item[field];
    });

    return acc;
  }, {});
};
