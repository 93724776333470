import React, { useState } from "react";
import { Button } from "../../../../../../../../../components/ui";

const DisputeModal = ({ setShowModal }) => {
  return (
    <div className="">
      <div className="">
        <h3 className="text-[20px] font-semibold text-[#002D3A]">
          Would you like to raise a dispute on this job
        </h3>
        <div className="mt-8 flex space-x-6">
          <Button children="No" backgroundColor="#FFFFFF" textColor="#0096C1" className="w-full" />
          <Button
            children="Yes"
            className="w-full"
            onClick={() => {
              setShowModal("diputeform");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DisputeModal;
