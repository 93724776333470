import React from "react";
import { editIcon, vendorProfileBg } from "../../../../../../constants/images";
import ProfileContentModal from "../profile_content-modal";
import ProfileTabs from "../profile-tabs";
import { Button } from "../../../../../../components/ui";
import EditServiceModal from "../edit_service-modal";
import { useSelector } from "react-redux";

const MainDashboard = () => {
  const editServices = useSelector((state) => state?.overlay.displayStates.editServices);
  return (
    <div className="relative">
      <div className="relative mr-0 h-[120vh] w-full max-w-full items-start justify-center pl-0 pr-0 pt-10 md:float-right md:mx-auto md:ml-auto md:w-[81%] md:pt-20">
        <div className="w-full">
          <div className="relative">
            <img
              src={vendorProfileBg}
              alt="profile_background"
              className="fixed left-0 right-0 top-0 h-[204px] w-full md:relative md:h-full"
            />
            <img
              src={editIcon}
              alt="edit"
              className="absolute right-6 top-16 w-[25px] cursor-pointer md:top-[15rem] md:w-[40px]"
            />
            <div className="w-full justify-between pt-16">
              <ProfileContentModal />
              <ProfileTabs />
            </div>
          </div>
        </div>
        <div className="bottom- absolute mt-40 w-full px-6 md:hidden">
          <Button className="mx-auto w-full" backgroundColor="transparent" textColor="#0096C1">
            Edit profile details
          </Button>
        </div>
      </div>
      {editServices && (
        <>
          <div className="fixed z-[99] h-screen w-screen bg-black bg-opacity-35" />
          <div className="fixed bottom-0 top-[10%] z-[999] h-screen w-full md:left-[32%] md:right-[50%] md:w-[40%]">
            <EditServiceModal />
          </div>
        </>
      )}
    </div>
  );
};

export default MainDashboard;
