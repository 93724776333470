import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, SuccessModal } from "../../../../../../components";
import { cancel, dropdown, mark } from "../../../../../../../constants/images";
import { cancelIcon, Spinner, tickIcon } from "../../../../../../constants/images";
import {
  setPermissionDetails,
  setRefreshPermission,
  setShowAddPermission,
} from "../../../../../../redux/slice/modalOverlay";
const availablePositions = [
  {
    id: 0,
    value: "Super Admin",
  },
  {
    id: 1,
    value: "Dispute Resolution",
  },
  {
    id: 2,
    value: "Sales",
  },
  {
    id: 3,
    value: "ID Confirmation",
  },
  {
    id: 4,
    value: "Contractor",
  },
];

const allPermissions = [
  "Dispute resolution",
  "Banned accounts",
  "VAT",
  "Commission",
  "Adverts",
  "Discounts",
  "Agents",
  "ID confirmation",
  "Permissions",
];
const positionAccess = {
  "Super Admin": [
    {
      id: 0,
      title: "Dispute resolution",
    },
    {
      id: 1,
      title: "Banned accounts",
    },
    {
      id: 2,
      title: "VAT",
    },
    {
      id: 3,
      title: "Commission",
    },
    {
      id: 4,
      title: "Adverts",
    },
    {
      id: 5,
      title: "Discounts",
    },
    {
      id: 6,
      title: "Agents",
    },
    {
      id: 7,
      title: "ID confirmation",
    },
    {
      id: 8,
      title: "Permissions",
    },
  ],
  "Dispute Resolution": [
    {
      id: 0,
      title: "Dispute resolution",
    },
  ],
  Sales: [
    {
      id: 2,
      title: "VAT",
    },
    {
      id: 3,
      title: "Commission",
    },
    {
      id: 4,
      title: "Adverts",
    },
    {
      id: 5,
      title: "Discounts",
    },
    {
      id: 6,
      title: "Agents",
    },
    {
      id: 7,
      title: "Banned accounts",
    },
  ],
  "ID Confirmation": [
    {
      id: 6,
      title: "Agents",
    },
    {
      id: 7,
      title: "ID confirmation",
    },
  ],
  Contractor: [
    {
      id: 0,
      title: "List of registered vendors under them",
    },
  ],
};
const AddPermissionModal = () => {
  const accessToken = useSelector((state) => state.token.states.accessToken);
  const [positionAccessList, setPositionAccessList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [showPositions, setShowPositions] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState("Select a position");
  const permissionDetails = useSelector((state) => state.modalOverlay.states.permissionDetails);
  const [details, setDetails] = useState({
    first_name: permissionDetails.first_name || "",
    last_name: permissionDetails.last_name || "",
    email: permissionDetails.email || "",
    role: permissionDetails.user_type || "",
  });

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const [errorState, setErrorState] = useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    positionError: "",
  });

  const roleMapping = {
    "Super Admin": "super_admin",
    "Dispute Resolution": "dispute_resolution",
    Sales: "sales",
    "ID Confirmation": "id_confirmation",
    Contractor: "contractor",
  };

  const formatRole = (role) => {
    const fetchedRoleMapping = {
      super_admin: "Super Admin",
      dispute_resolution: "Dispute Resolution",
      id_confirmation: "ID Confirmation",
      sales: "Sales",
      contractor: "Contractor",
    };
    return fetchedRoleMapping[role] || role;
  };

  const handlePositionSelect = (position) => {
    setSelectedPosition(position);
    setDetails({ ...details, role: roleMapping[position] });
    setErrorState((prev) => ({ ...prev, positionError: "" }));
  };

  const validate = () => {
    let isError = false;
    const errors = {
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      positionError: "",
    };

    if (!details.first_name) {
      isError = true;
      errors.firstNameError = "Please enter first name";
    }
    if (!details.last_name) {
      isError = true;
      errors.lastNameError = "Please enter last name";
    }
    if (!details.email) {
      isError = true;
      errors.emailError = "Please enter email";
    }
    if (!details.role) {
      isError = true;
      errors.positionError = "Please select a position";
    }
    if (details.role === "Select a position") {
      isError = true;
      errors.positionError = "Please select a position";
    }

    setErrorState(errors);
    return isError;
  };

  useEffect(() => {
    const accessList = positionAccess[selectedPosition];
    if (accessList) {
      setPositionAccessList(accessList);
    } else {
      setPositionAccessList([]);
    }
  }, [selectedPosition]);

  const handleAddPermission = async () => {
    const error = validate();
    dispatch(setRefreshPermission(false));
    if (!error) {
      try {
        setLoading(true);
        const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/add-permissions`, {
          method: "POST",
          body: JSON.stringify(details),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data = await response.text();
        if (response.ok) {
          setLoading(false);
          setSuccess(true);
          setSuccessMessage(data);
          dispatch(setRefreshPermission(true));
        } else {
          setLoading(false);
          setError(data.message || data);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleUpdatePermission = async () => {
    const error = validate();
    dispatch(setRefreshPermission(false));
    if (!error) {
      try {
        setLoading(true);
        const response = await fetch(
          `${import.meta.env.VITE_BASE_URL}/admin//edit-permission/${permissionDetails.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(details),
          },
        );

        const data = await response.json();

        if (response.ok) {
          setLoading(false);
          setSuccess(true);
          setSuccessMessage(data.message);
          dispatch(setRefreshPermission(true));
        } else {
          setLoading(false);
          setError(data.message || data.error);
        }
      } catch (error) {
        setLoading(false);
        setError(error.message);
      }
    }
  };

  const handleClose = () => {
    dispatch(setShowAddPermission(false));
    dispatch(setPermissionDetails({}));
  };

  return (
    <>
      {!success && (
        <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
          <div
            className="absolute right-4 top-2 text-2xl hover:cursor-pointer"
            onClick={handleClose}
          >
            X
          </div>
          <div className="flex flex-col gap-y-3 px-8">
            <span className="py-2 text-start text-xl font-semibold text-secondary">
              Give permission
            </span>
            <div className="flex flex-col">
              <label htmlFor="name" className="text-base font-normal text-[#181818]">
                Full Name
              </label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                placeholder="First Name of the user"
                value={details.first_name}
                onChange={handleChange}
                className="w-full border border-[#AEAEAE] px-2 py-4 text-base font-normal text-lightgray outline-none"
              />
              <span className="text-start text-sm text-red-500">{errorState.nameError}</span>
            </div>
            <div className="flex flex-col">
              <label htmlFor="last_name" className="text-base font-normal text-[#181818]">
                Last Name
              </label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Last Name of the user"
                value={details.last_name}
                onChange={handleChange}
                className="w-full border border-[#AEAEAE] px-2 py-4 text-base font-normal text-lightgray outline-none"
              />
              <span className="text-start text-sm text-red-500">{errorState.lastNameError}</span>
            </div>
            <div className="flex flex-col">
              <label htmlFor="email" className="text-base font-normal text-[#181818]">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email of the user"
                value={details.email}
                onChange={handleChange}
                className="w-full border border-[#AEAEAE] px-2 py-4 text-base font-normal text-lightgray outline-none"
              />
              <span className="text-start text-sm text-red-500">{errorState.emailError}</span>
            </div>
            <div className="flex flex-col">
              <label htmlFor="position" className="text-base font-normal text-[#181818]">
                Position
              </label>
              <div
                className="relative flex w-full border border-[#AEAEAE] px-2 py-4"
                onClick={() => setShowPositions(!showPositions)}
              >
                <div className="flex w-full items-center justify-between">
                  <span className="text-base font-normal text-lightgray">
                    {selectedPosition !== "Select a position"
                      ? selectedPosition
                      : formatRole(permissionDetails.user_type)}
                  </span>
                  <img src={dropdown} alt="" />
                </div>
                {showPositions && (
                  <div className="absolute top-11 flex w-full animate-slide_up flex-col gap-y-2 bg-primary py-4 drop-shadow-2xl">
                    {availablePositions.map((position) => (
                      <div
                        key={position.id}
                        onClick={() => handlePositionSelect(position.value)}
                        className="flex gap-x-3 px-3"
                      >
                        <input type="radio" name="position" id={position.value} />
                        <label
                          htmlFor={position.value}
                          className="text-sm font-normal text-lightgray"
                        >
                          {position.value}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {positionAccessList.length > 0 && (
                <div className="flex flex-col items-start gap-y-1 pt-3">
                  <div className="text-base text-[#181818]">
                    {selectedPosition} team will have access to:
                  </div>
                  {selectedPosition === "Contractor" ? (
                    <div
                      key={positionAccessList[0].id}
                      className="flex items-center gap-x-2 text-sm font-normal text-secondary"
                    >
                      <span className="ml-4">{positionAccessList[0].title}</span>
                    </div>
                  ) : (
                    <>
                      {positionAccessList.map((access) => (
                        <div
                          key={access.id}
                          className="flex items-center gap-x-2 text-sm font-normal text-secondary"
                        >
                          <img src={tickIcon} alt="" className="w-[20px]" />
                          <span>{access.title}</span>
                        </div>
                      ))}
                      {allPermissions
                        .filter(
                          (permission) =>
                            !positionAccessList.some((access) => access.title === permission),
                        )
                        .map((permission) => (
                          <div
                            key={permission}
                            className="flex items-center gap-x-2 text-sm font-normal text-secondary"
                          >
                            <img src={cancelIcon} alt="" className="w-[20px]" />
                            <span>{permission}</span>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              )}
              <span className="text-start text-sm text-red-500">{errorState.positionError}</span>
            </div>
            {error !== "" && <span className="text-start text-sm text-red-500">{error}</span>}
            <div className="w-full">
              {permissionDetails.id ? (
                <Button
                  className={`flex w-full items-center justify-center`}
                  onClick={handleUpdatePermission}
                  disabled={loading}
                >
                  {loading ? (
                    <img src={Spinner} alt="loading" className="w-[5%]" />
                  ) : (
                    "Update Permission"
                  )}
                </Button>
              ) : (
                <Button
                  className={`flex w-full items-center justify-center`}
                  onClick={handleAddPermission}
                  disabled={loading}
                >
                  {loading ? (
                    <img src={Spinner} alt="loading" className="w-[5%]" />
                  ) : (
                    "Add Permission"
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      {success && (
        <SuccessModal
          title={`You have successfully given ${selectedPosition} permission to ${details.first_name} ${details.last_name}`}
          buttonText={"Done"}
          handleClose={() => {
            setSuccess(false);
            handleClose();
          }}
          showClose={true}
          onClick={() => {
            setSuccess(false);
            handleClose();
          }}
        />
      )}
    </>
  );
};

export default AddPermissionModal;
