import React, { useState } from "react";
import { Link } from "react-router-dom";
import { left, logout } from "../../../../../../../constants/images";
import Toggle from "../../../../../../../components/ui/toggle";
import { RxEyeOpen } from "react-icons/rx";
import { BiHide } from "react-icons/bi";
import { Button } from "../../../../../../../components/ui";

const SettingsMobileContent = ({ setShowLogoutModal, setShowCloseAccountModal }) => {
  const [onlineStatus, setOnlineStatus] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [passwordSecurityExpanded, setPasswordSecurityExpanded] = useState(false);
  const [notificationSettingsExpanded, setNotificationSettingsExpanded] = useState(false);
  const [expandChangePassword, setExpandChangePassword] = useState(false);
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [passwordErrors, setPasswordErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const handlePasswordChange = (e) => {
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePasswordToggle = (passwordType) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [passwordType]: !prevShowPassword[passwordType],
    }));
  };

  const validatePasswords = () => {
    const errors = {};
    if (!passwords.currentPassword) {
      errors.currentPassword = "Current password is required";
    }
    if (!passwords.newPassword) {
      errors.newPassword = "New password is required";
    } else if (passwords.newPassword.length < 8) {
      errors.newPassword = "New password must be at least 8 characters";
    }
    if (!passwords.confirmNewPassword) {
      errors.confirmNewPassword = "Confirm new password is required";
    } else if (passwords.confirmNewPassword !== passwords.newPassword) {
      errors.confirmNewPassword = "Passwords do not match";
    }
    setPasswordErrors(errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validatePasswords();
  };

  const handleToggle = (type) => {
    switch (type) {
      case "onlineStatus":
        setOnlineStatus((prevStatus) => !prevStatus);
        break;
      case "darkMode":
        setDarkMode((prevMode) => !prevMode);
        break;
      case "twoFactorAuth":
        setTwoFactorAuth((prevAuth) => !prevAuth);
        break;
      default:
        break;
    }
  };

  const handleExpand = (section) => {
    switch (section) {
      case "passwordSecurity":
        setNotificationSettingsExpanded(false);
        setPasswordSecurityExpanded(true);
        break;
      case "notificationSettings":
        setPasswordSecurityExpanded(false);
        setNotificationSettingsExpanded(true);
        break;
      default:
        break;
    }
  };

  const [notificationSettings, setNotificationSettings] = useState({
    messages: false,
    serviceRequest: false,
    projectUpdates: false,
    ratingReminders: false,
  });
  const handleNotificationToggle = (type) => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      [type]: !prevSettings[type],
    }));
  };

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleCloseAccountClick = () => {
    setShowCloseAccountModal(true);
  };

  return (
    <div className="relative mx-auto w-full max-w-full items-center justify-center pt-[5rem] md:hidden">
      {!notificationSettingsExpanded && !passwordSecurityExpanded && (
        <div>
          <Link to="/vendor-dashboard/home" className="relatve">
            <img
              src={left}
              alt="go back"
              className="absolute left-4 top-[7rem] z-10 w-[10px] rotate-180"
            />
          </Link>
          <div className="mt-6 flex w-full flex-col items-center justify-between">
            <span className="mx-4 text-xl font-medium text-secondary">Settings</span>

            <div className="mt-10 flex w-full flex-col">
              {/* // side bar */}

              <div className="mb-8 flex flex-col gap-y-2 px-7">
                <div
                  className="w-ful border border-[#AEAEAE] px-4 py-3 hover:cursor-pointer"
                  onClick={() => handleExpand("passwordSecurity")}
                >
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-secondary">
                      Password and security
                    </span>
                    <img
                      src={left}
                      alt="open"
                      className={`transition-transform duration-300 ${
                        !passwordSecurityExpanded ? "rotate-0" : "rotate-90"
                      }`}
                    />
                  </div>
                </div>

                <div
                  className="border border-[#AEAEAE] px-4 py-3 hover:cursor-pointer"
                  onClick={() => handleExpand("notificationSettings")}
                >
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-secondary">
                      Notification settings
                    </span>
                    <img src={left} alt="open" />
                  </div>
                </div>
              </div>

              <div className="mb-10 flex flex-col gap-y-2 px-7">
                <div className=" border border-[#AEAEAE] px-4 py-3">
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-[#181818]">Online status</span>
                    <Toggle
                      defaultChecked={onlineStatus}
                      onChange={() => handleToggle("onlineStatus")}
                    />
                  </div>
                </div>

                <div className=" border border-[#AEAEAE] px-4 py-3">
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-[#181818]">Enable dark mode </span>
                    <Toggle defaultChecked={darkMode} onChange={() => handleToggle("darkMode")} />
                  </div>
                </div>
              </div>

              <div className="gap-y- mb-10 flex flex-col">
                <div className="px-7 py-3">
                  <div className="flex items-center justify-between">
                    <span className="text-base font-medium text-[#181818]">Account management</span>
                    <img src={left} alt="open" />
                  </div>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="px-7 py-3">
                  <div className="flex flex-col">
                    <div className="flex w-full items-center justify-between" onClick={handleCloseAccountClick}>
                      <span className="text-sm font-medium text-lightgray">Close account</span>
                      <img src={left} alt="open" />
                    </div>
                    <span className="text-xs text-lightgray">
                      This will permanently close your account
                    </span>
                  </div>
                </div>

                <hr className="w-full" color="#AEAEAE" />
                <div className="px-7 py-3">
                  <div className="flex items-center gap-x-2" onClick={handleLogoutClick}>
                    <img src={logout} alt="open" />
                    <span className="text-sm font-medium text-[#FF0000]">Logout</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {passwordSecurityExpanded && (
        <div>
          <div onClick={() => setPasswordSecurityExpanded(false)} className="relatve">
            <img
              src={left}
              alt="go back"
              className="absolute left-4 top-[7rem] z-10 w-[10px] rotate-180"
            />
          </div>
          <div className="mt-6 flex w-full flex-col items-center justify-between gap-y-4">
            <span className="mx-4 text-xl font-medium text-secondary">Password and security</span>
            <hr color="#AEAEAE" className="w-full" />
            <div className="flex w-full flex-col gap-y-5">
              <div
                className="relative flex w-full flex-col items-start justify-between px-4"
                onClick={() => setExpandChangePassword(!expandChangePassword)}
              >
                <div className="flex w-full items-center justify-between">
                  <span className="text-sm font-medium text-secondary">Change password</span>
                  <img
                    src={left}
                    alt="open"
                    className={`transition-transform duration-300 ${
                      !expandChangePassword ? "rotate-0" : "rotate-90"
                    }`}
                  />
                </div>
                {expandChangePassword && (
                  <div className="w-full">
                    <div className="flex w-full flex-col gap-y-6">
                      <div className="flex w-full flex-col gap-y-4 py-2">
                        <div className="relative flex flex-col gap-y-2">
                          <label
                            htmlFor="current_password"
                            className="text-sm font-normal text-[#181818]"
                          >
                            Enter current password
                          </label>
                          <input
                            type={showPassword.currentPassword ? "text" : "password"}
                            name="currentPassword"
                            id="current_password"
                            value={passwords.currentPassword}
                            onChange={handlePasswordChange}
                            className="rounded-[5px] border border-[#181818] px-4 py-2 outline-none"
                          />
                          {showPassword.currentPassword ? (
                            <RxEyeOpen
                              className="absolute right-4 top-10 cursor-pointer"
                              color="#5F5F5F"
                              size={24}
                              onClick={() => handlePasswordToggle("currentPassword")}
                            />
                          ) : (
                            <BiHide
                              className="absolute right-4 top-10 cursor-pointer"
                              color="#5F5F5F"
                              size={24}
                              onClick={() => handlePasswordToggle("currentPassword")}
                            />
                          )}
                          {passwordErrors.currentPassword && (
                            <span className="text-sm text-red-500">
                              {passwordErrors.currentPassword}
                            </span>
                          )}
                        </div>

                        <div className="relative flex flex-col gap-y-2">
                          <label
                            htmlFor="new_password"
                            className="text-sm font-normal text-[#181818]"
                          >
                            Enter new password
                          </label>
                          <input
                            type={showPassword.newPassword ? "text" : "password"}
                            name="newPassword"
                            id="new_password"
                            value={passwords.newPassword}
                            onChange={handlePasswordChange}
                            className="rounded-[5px] border border-[#181818] px-4 py-2 outline-none"
                          />
                          {showPassword.newPassword ? (
                            <RxEyeOpen
                              className="absolute right-4 top-10 cursor-pointer"
                              color="#5F5F5F"
                              size={24}
                              onClick={() => handlePasswordToggle("newPassword")}
                            />
                          ) : (
                            <BiHide
                              className="absolute right-4 top-10 cursor-pointer"
                              color="#5F5F5F"
                              size={24}
                              onClick={() => handlePasswordToggle("newPassword")}
                            />
                          )}
                          {passwordErrors.newPassword && (
                            <span className="text-sm text-red-500">
                              {passwordErrors.newPassword}
                            </span>
                          )}
                        </div>

                        <div className="relative flex flex-col gap-y-2">
                          <label
                            htmlFor="confirm_new_password"
                            className="text-base font-normal text-[#181818]"
                          >
                            Confirm new password
                          </label>
                          <input
                            type={showPassword.confirmNewPassword ? "text" : "password"}
                            name="confirmNewPassword"
                            id="confirm_new_password"
                            value={passwords.confirmNewPassword}
                            onChange={handlePasswordChange}
                            className="rounded-[5px] border border-[#181818] px-4 py-2 outline-none"
                          />
                          {showPassword.confirmNewPassword ? (
                            <RxEyeOpen
                              className="absolute right-4 top-10 cursor-pointer"
                              color="#5F5F5F"
                              size={24}
                              onClick={() => handlePasswordToggle("confirmNewPassword")}
                            />
                          ) : (
                            <BiHide
                              className="absolute right-4 top-10 cursor-pointer"
                              color="#5F5F5F"
                              size={24}
                              onClick={() => handlePasswordToggle("confirmNewPassword")}
                            />
                          )}
                          {passwordErrors.confirmNewPassword && (
                            <span className="text-sm text-red-500">
                              {passwordErrors.confirmNewPassword}
                            </span>
                          )}
                        </div>
                        <Button onClick={handleSubmit}>Update</Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <hr color="#AEAEAE" className="w-full" />
              <div className="flex items-center justify-between px-4">
                <span className="text-sm font-medium text-[#181818]">
                  Face or fingerprint recognition
                </span>
                <Toggle defaultChecked={darkMode} onChange={() => handleToggle("darkMode")} />
              </div>
              <hr color="#AEAEAE" className="w-full" />
              <div className="flex w-full flex-col gap-y-2 px-4 py-2">
                <div className="flex w-full items-center justify-between">
                  <span className="text-sm font-medium text-[#181818]">
                    Two-factor authentication
                  </span>
                  <Toggle
                    defaultChecked={twoFactorAuth}
                    onChange={() => handleToggle("twoFactorAuth")}
                  />
                </div>
                <span className="mt-3 text-xs font-normal text-lightgray">
                  <span className="font-medium">Recommended.</span>We will send an authentication
                  code via SMS or email when using an unrecognized device or browser to log in
                </span>
              </div>
              <hr color="#AEAEAE" className="w-full" />
            </div>
          </div>
        </div>
      )}

      {notificationSettingsExpanded && (
        <div>
          <div onClick={() => setNotificationSettingsExpanded(false)} className="relatve">
            <img
              src={left}
              alt="go back"
              className="absolute left-4 top-[7rem] z-10 w-[10px] rotate-180"
            />
          </div>
          <div className="mt-6 flex w-full flex-col items-center justify-between gap-y-4">
            <span className="mx-4 text-xl font-medium text-secondary">Notification settings</span>
            <hr color="#AEAEAE" className="w-full" />
            <div className="flex w-full flex-col gap-y-6">
              <span className="px-4 text-sm font-medium text-[#181818]">
                Turn on push notifications
              </span>
              <span className="px-4 text-xs font-normal text-lightgray">
                Allow GYWD to send you notifications to quickly see updates about your orders and
                activities
              </span>
              <hr color="#AEAEAE" className="w-full" />
              <div className="flex w-full flex-col gap-y-4 px-4 py-5">
                <span className="text-sm font-medium text-[#181818]">Email notifications</span>
                <hr color="#AEAEAE" className="w-full" />
                <div className="flex flex-col gap-y-5">
                  <div className="mt-4 flex w-full items-center justify-between">
                    <span className="text-sm font-medium text-lightgray">Messages</span>
                    <Toggle
                      defaultChecked={notificationSettings.messages}
                      onChange={() => handleNotificationToggle("messages")}
                    />
                  </div>
                  <hr color="#AEAEAE" className="w-full" />
                  <div className="flex w-full items-center justify-between">
                    <span className="text-sm font-medium text-lightgray">Service request</span>
                    <Toggle
                      defaultChecked={notificationSettings.serviceRequest}
                      onChange={() => handleNotificationToggle("serviceRequest")}
                    />
                  </div>
                  <hr color="#AEAEAE" className="w-full" />
                  <div className="flex w-full items-center justify-between">
                    <span className="text-sm font-medium text-lightgray">Project updates</span>
                    <Toggle
                      defaultChecked={notificationSettings.projectUpdates}
                      onChange={() => handleNotificationToggle("projectUpdates")}
                    />
                  </div>
                  <hr color="#AEAEAE" className="w-full" />
                  <div className="flex w-full items-center justify-between">
                    <span className="text-sm font-medium text-lightgray">Rating reminders</span>
                    <Toggle
                      defaultChecked={notificationSettings.ratingReminders}
                      onChange={() => handleNotificationToggle("ratingReminders")}
                    />
                  </div>
                  <hr color="#AEAEAE" className="w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsMobileContent;
