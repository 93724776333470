import React, { useEffect, useState } from "react";
import { dropdown } from "../../../../../../constants/images";
import { disputeTracking } from "../../../../../../constants";
import { setDisputeDashboard } from "../../../../../../features/overlay";
import { useDispatch } from "react-redux";

const dateSortValues = [
  {
    id: 0,
    value: "Newest",
  },
  {
    id: 0,
    value: "Oldest",
  },
  {
    id: 0,
    value: "Latest",
  },
];
const categorySortValues = [
  {
    id: 0,
    value: "Plumbing",
  },
  {
    id: 0,
    value: "Category",
  },
  {
    id: 0,
    value: "Category",
  },
];
const statusSortValues = [
  {
    id: 0,
    value: "Pending",
  },
  {
    id: 0,
    value: "Review",
  },
  {
    id: 0,
    value: "Resolved",
  },
];
const DisputeDashboard = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDateSort, setShowDateSort] = useState(false);
  const [showCategorySort, setShowCategorySort] = useState(false);
  const [showStatusSort, setShowStatusSort] = useState(false);
  const [dateSort, setDateSort] = useState("Newest");
  const [categorySort, setCategorySort] = useState("Category");
  const [statusSort, setStatusSort] = useState("Status");
  const [showDetails, setShowDetails] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [sortedDisputes, setSortedDisputes] = useState(disputeTracking);

  const applyFilters = () => {
    let filteredDisputes = disputeTracking;
    if (statusSort !== "Status") {
      filteredDisputes = filteredDisputes.filter((dispute) => dispute.status === statusSort);
      console.log(statusSort);
    }
    if (categorySort !== "Category") {
      filteredDisputes = filteredDisputes.filter((dispute) => dispute.title === categorySort);
    }
    return filteredDisputes;
  };

  const handleDate = (e) => {
    setDateSort(e);
    setShowDateSort(false);
    const filteredDisputes = applyFilters();
    sortDisputes("date", e === "Newest" ? "desc" : "asc", filteredDisputes);
  };

  const handleCategory = (e) => {
    setCategorySort(e);
    setShowCategorySort(false);
    const filteredDisputes = applyFilters();
    sortDisputes("category", e === "Plumbing" ? "desc" : "asc", filteredDisputes);
  };

  const handleStatus = (e) => {
    setStatusSort(e);
    setShowStatusSort(false);
    const filteredDisputes = applyFilters();
    sortDisputes("date", "desc", filteredDisputes); // sort by date in descending order
  };
  const sortDisputes = (field, order, disputes = disputeTracking) => {
    const sortedDisputes = [...disputes].sort((a, b) => {
      if (field === "date") {
        return order === "asc"
          ? new Date(a.date) - new Date(b.date)
          : new Date(b.date) - new Date(a.date);
      } else if (field === "category") {
        return order === "asc" ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
      } else if (field === "status") {
        return order === "asc"
          ? a.status.localeCompare(b.status)
          : b.status.localeCompare(a.status);
      }
    });
    setSortedDisputes(sortedDisputes);
  };
  useEffect(() => {
    sortDisputes("date", "desc");
  }, []);
  const handleBackClick = () => {
    dispatch(setDisputeDashboard(false));
  };
  const handleDetails = (id) => {
    setSelectedId(id);
    setShowDetails(true);
  };
  return (
    <>
      <div className="relative mr-0 h-screen w-full max-w-full items-start justify-center pl-0 pr-0 pt-10 md:float-right md:mx-auto md:ml-auto md:w-[81%] md:pt-20">
        {!showDetails && (
          <div className="px-6 pt-12 md:px-20 md:pt-20">
            <div
              className="absolute left-10 top-[100px] z-10 w-[20px] rotate-90 md:hidden"
              onClick={handleBackClick}
            >
              <img src={dropdown} alt="go back" />
            </div>
            <div className="md:text-start text-center text-base font-medium text-secondary md:text-2xl">
              Dispute resolution
            </div>
            {/* <div className="flex border-[#AEAEAE] py-4 text-xs font-medium md:mt-10 md:border-b-[0.5px] md:border-t-[0.5px] md:px-5 md:text-base">
              <button
                className={`w-[70%] rounded-[5px] px-4 py-2 ${activeIndex === 0 ? "bg-[#0096C1] text-white" : "bg-white text-[#5F5F5F]"}`}
                onClick={() => setActiveIndex(0)}
              >
                Consumer raised dispute
              </button>
              <button
                className={`w-[60%] rounded-[5px] px-4 py-2 ${activeIndex === 1 ? "bg-[#0096C1] text-white" : "bg-white text-[#5F5F5F]"}`}
                onClick={() => setActiveIndex(1)}
              >
                Vendor raised dispute
              </button>
            </div> */}
            <div className="py-8 md:py-12">
              <div className="flex items-center gap-x-4 lg:gap-x-7 ">
                <span className="text-xs font-medium md:text-sm">Sort:</span>
                <div className="relative">
                  <div
                    className="flex w-[100px] cursor-pointer items-center justify-between gap-x-2 rounded-[20px] border border-lightgray px-[15px] py-1 md:w-[120px] md:py-[2px]"
                    onClick={() => setShowDateSort(!showDateSort)}
                  >
                    <span className="text-xs font-medium md:text-sm">{dateSort}</span>
                    <img src={dropdown} alt="" />
                  </div>
                  {showDateSort && (
                    <div className="absolute mt-2 w-full bg-primary text-sm md:text-base">
                      <div className="flex flex-col items-center justify-center gap-y-2 py-2">
                        {dateSortValues.map((date) => (
                          <span
                            key={date.id}
                            onClick={() => handleDate(date.value)}
                            className="cursor-pointer"
                          >
                            {date.value}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="relative">
                  <div
                    className="flex w-[100px] cursor-pointer items-center justify-between gap-x-2 rounded-[20px] border border-lightgray px-[15px] py-1 md:w-[120px] md:py-[2px]"
                    onClick={() => setShowCategorySort(!showCategorySort)}
                  >
                    <span className="text-xs font-medium md:text-sm">{categorySort}</span>
                    <img src={dropdown} alt="" />
                  </div>
                  {showCategorySort && (
                    <div className="absolute mt-2 w-full bg-primary text-sm md:text-base">
                      <div className="flex flex-col items-center justify-center gap-y-2 py-2">
                        {categorySortValues.map((category) => (
                          <span
                            key={category.id}
                            onClick={() => handleCategory(category.value)}
                            className="cursor-pointer"
                          >
                            {category.value}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <div
                    className="flex w-[100px] cursor-pointer items-center justify-between gap-x-2 rounded-[20px] border border-lightgray px-[15px] py-1 md:w-[120px] md:py-[2px]"
                    onClick={() => setShowStatusSort(!showStatusSort)}
                  >
                    <span className="text-xs font-medium md:text-sm">{statusSort}</span>
                    <img src={dropdown} alt="" />
                  </div>
                  {showStatusSort && (
                    <div className="absolute z-[50] mt-2 w-full bg-primary text-sm md:text-base">
                      <div className="flex flex-col items-center justify-center gap-y-2 py-2">
                        {statusSortValues.map((status) => (
                          <span
                            key={status.id}
                            onClick={() => handleStatus(status.value)}
                            className="cursor-pointer"
                          >
                            {status.value}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-4">
              {sortedDisputes.map((dispute) => (
                <div
                  className="cursor-pointer rounded-[5px] border border-lightgray"
                  key={dispute.id}
                  onClick={() => handleDetails(dispute.id)}
                >
                  <div className="flex flex-col justify-between gap-y-2 px-4 py-2">
                    <div className="flex w-full items-center justify-between">
                      <div className="text-lightgray">
                        <span className="text-xs md:text-base">Project number:</span>{" "}
                        <span className="text-base md:text-lg">{dispute.project_number}</span>{" "}
                      </div>
                      <div className="text-xs text-secondary md:text-base">
                        <span className="hidden md:block">Opened: </span> {dispute.date}
                      </div>
                    </div>
                    <div className="flex w-full flex-col items-start justify-between text-lightgray md:flex-row md:items-end">
                      <div className="flex flex-col">
                        <span className="text-xs md:text-base">Project Title</span>
                        <span className="text-sm font-medium text-secondary md:text-lg">
                          {dispute.title}
                        </span>
                      </div>
                      <div className="mt-3 md:mt-0">
                        <span className="text-xs md:text-base">Resolution status:</span>{" "}
                        <span
                          className={`${dispute.status === "Pending" ? "text-lightblue" : dispute.status === "In review" ? "text-[#0096C1]" : dispute.status === "Resolved" ? "text-[#36C100]" : null} text-sm md:text-base`}
                        >
                          {dispute.status}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {sortedDisputes.length === 0 && <div className="text-center text-secondary font-medium md:text-2xl mt-4">No Dispute matches your query</div>}
          </div>
        )}
        {showDetails && (
          <div className="relative space-y-4 px-6 pt-12 md:px-20 md:pt-20">
            <div
              className="absolute left-4 top-[76px] z-10 w-[20px] rotate-90 md:hidden"
              onClick={() => setShowDetails(false)}
            >
              <img src={dropdown} alt="go back" />
            </div>
            <div className="text-center text-base font-medium text-secondary md:text-start md:text-2xl">
              Dispute resolution
            </div>
            {disputeTracking
              .filter((value) => value.id === selectedId)
              .map((dispute) => (
                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                  <div className="flex flex-col">
                    <span className="text-xs font-normal text-lightgray md:text-base">
                      Dispute Id
                    </span>
                    <span className="text-sm font-medium text-secondary md:text-lg">
                      {dispute.dispute_id}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xs font-normal text-lightgray md:text-base">
                      Project Id
                    </span>
                    <span className="text-sm font-medium text-secondary md:text-lg">
                      {dispute.project_number}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xs font-normal text-lightgray md:text-base">
                      Resolution status
                    </span>
                    <span
                      className={`text-sm font-medium md:text-lg ${dispute.status === "Pending" ? "text-lightblue" : dispute.status === "In review" ? "text-[#0096C1]" : dispute.status === "Resolved" ? "text-[#36C100]" : null}`}
                    >
                      {dispute.status}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xs font-normal text-lightgray md:text-base">
                      Project title
                    </span>
                    <span className="text-sm font-medium text-secondary md:text-lg">
                      {dispute.title}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xs font-normal text-lightgray md:text-base">
                      Dispute opened
                    </span>
                    <span className="text-sm font-medium text-secondary md:text-lg">
                      {dispute.date}
                    </span>
                  </div>
                  {dispute.status === "Resolved" && (
                    <>
                      <div className="flex flex-col">
                        <span className="text-xs font-normal text-lightgray md:text-base">
                          Resolution reached
                        </span>
                        <span className="text-sm font-medium text-secondary md:text-lg">
                          {dispute.resolution}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs font-normal text-lightgray md:text-base">
                          Amount refunded
                        </span>
                        <span className="text-sm font-medium text-secondary md:text-lg">
                          {dispute.amount_refunded}
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs font-normal text-lightgray md:text-base">
                          Dispute ended
                        </span>
                        <span className="text-sm font-medium text-secondary md:text-lg">
                          {dispute.end_date}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DisputeDashboard;
