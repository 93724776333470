import React from "react";
import Container from "../../../container";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import { jobsData } from "../../data";
import { linkarrow } from "../../../../../constants/images";

const JobMiddleScreeen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const job = jobsData.find((job) => job.id === parseInt(id));

  if (!job)
    return (
      <div className="flex h-screen items-center justify-center text-xl font-semibold">
        Job not found
      </div>
    );

  return (
    <Container>
      <div className="px-5 py-20 md:px-10">
        <div className="items-center justify-between md:flex">
          <h1 className="text-[20px] font-medium md:text-[25px] ">{job?.title}</h1>
          <div className="hidden cursor-pointer items-center rounded-md bg-[#0096C1] px-4 py-2 text-white md:flex">
            Close job
          </div>
        </div>

        <div
          className="mt-7 flex w-full cursor-pointer items-center justify-between rounded-[5px] border-[1px] border-[#AEAEAE] px-3 py-3 md:mt-12 md:w-[80%]"
          onClick={() => navigate(`/jobs/${job.id}/details`)}
        >
          <span className="text-[18px] text-black">Job details</span>
          <img src={linkarrow} alt="linkarrow" className="pl-2" />
        </div>

        <div
          className="mt-7 flex w-full cursor-pointer items-center justify-between rounded-[5px] border-[1px] border-[#AEAEAE] px-3 py-3 md:w-[80%]"
          onClick={() => navigate(`/jobs/${job.id}/application`)}
        >
          <span className="text-[18px] text-black">Applications</span>
          <img src={linkarrow} alt="linkarrow" className="pl-2" />
        </div>

        <div
          className="mt-7 flex w-full cursor-pointer items-center justify-between rounded-[5px] border-[1px] border-[#AEAEAE] px-3 py-3 md:w-[80%]"
          onClick={() => navigate(`/jobs/${job.id}/hired-vendor`)}
          r
        >
          <span className="text-[18px] text-black">Hired vendor</span>
          <img src={linkarrow} alt="linkarrow" className="pl-2" />
        </div>

        <div className="mt-10 flex cursor-pointer items-center justify-center rounded-md bg-[#0096C1] px-4 py-2 text-center text-white md:hidden">
          Close job
        </div>
      </div>
    </Container>
  );
};

export default JobMiddleScreeen;
