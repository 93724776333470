import React from "react";
import Container from "../../../container";
import SupportContent from "./_components/support-content";

const HelpAndSupport = () => {
  return (
    <Container>
      <div className="md:py-16 py-20 md:px-10 ">
        <SupportContent />
      </div>
    </Container>
  );
};

export default HelpAndSupport;
