import React, { useState, useRef, useEffect } from "react";
import { Button } from "../../../../../../../../../components/ui";
import { addmore } from "../../../../../../../../../constants/images";
import ThankYouForPro from "./thankYouPro";

const OpenDispute = ({ setShowModal }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const fileInputRef = useRef(null); // Reference to the hidden file input

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages(files);
  };

  const handleAddFilesClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="">
        <div className="">
          <h2 className="text-[20px] font-semibold text-[#002D3A]">Open a dispute</h2>
          <h3 className="text-[16px]">Reason for dispute</h3>
          <textarea
            name=""
            id=""
            rows="3"
            className="w-full rounded-md bg-[#F4F4F4] px-3 py-2"
            placeholder=""
          ></textarea>

          <h3 className="mt-4">Upload supporting documents</h3>
          <div className="flex w-full items-center justify-center rounded-md border-2 border-dotted border-[#4d4c4c] py-10">
            {selectedImages.length === 0 ? (
              <div
                className="flex cursor-pointer items-center space-x-1 bg-[#E6F5F9] px-3 py-2 text-[#0096C1]"
                onClick={handleAddFilesClick}
              >
                <div>
                  <img src={addmore} alt="Add files" />
                </div>
                <span>Add files</span>
              </div>
            ) : (
              <div className="relative">
                <img
                  src={URL.createObjectURL(selectedImages[0])}
                  alt="Uploaded"
                  className="h-auto w-full rounded-md"
                />
              </div>
            )}
          </div>

          <input
            type="file"
            accept="image/*"
            multiple={false}
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
          />

          <div className="mt-7 flex space-x-6">
            <Button
              children="Cancel"
              backgroundColor="#FFFFFF"
              textColor="#0096C1"
              className="w-full"
            />

            <Button
              children="Submit dispute"
              className="w-full"
              onClick={() => setShowModal("thankYouforPro")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenDispute;
