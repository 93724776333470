import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts";
import { Dropdown } from "../../../../../constants/images";
import { filterDataByDateRange } from "../../../../../utils/dateFilter";
import { groupByKeys } from "../../../../../utils/groupByPeriod";

const RevenueChart = ({ transactionData }) => {
  const dispatch = useDispatch();
  const [amountReceived, setAmountReceived] = useState();
  const [amountCommissioned, setAmountCommissioned] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState("This week");
  const [openSelect, setOpenSelect] = useState(false);

  const accessToken = useSelector((state) => state?.token.states.accessToken);

  const filteredData = filterDataByDateRange(
    transactionData,
    selectedPeriod === "This week" ? "week" : selectedPeriod === "This month" ? "month" : "year",
  );

  const groupedData = Object.values(groupByKeys(filteredData, ["transaction_date", "type"]));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/amounts-received`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        setAmountReceived(data);
      } catch (error) {
        console.error("Error fetching amount received:", error);
      }
    };
    fetchData();
  }, [accessToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/total-commission`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        setAmountCommissioned(data);
      } catch (error) {
        console.error("Error fetching total commission:", error);
      }
    };
    fetchData();
  }, [accessToken]);

  const handleSelect = () => setOpenSelect((prev) => !prev);

  return (
    <div className="mx-auto mb-10 flex w-full rounded-[10px] bg-primary py-4">
      <div className="flex w-full flex-col">
        <div className="flex w-full items-center justify-between px-10 py-5">
          <span className="font-medium text-secondary md:text-lg">Revenue Analytics</span>
          <div className="relative flex items-center gap-x-2">
            <div
              onClick={handleSelect}
              className="flex w-[190px] items-center justify-between rounded-[5px] border border-[#AEAEAE] px-5 py-2 text-base font-medium text-lightgray hover:cursor-pointer"
            >
              <span>{selectedPeriod}</span>
              <img
                src={Dropdown}
                alt="open"
                className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
              />
            </div>
            {openSelect && (
              <div className="absolute left-0 top-[100%] w-full animate-slide_up rounded-[10px] bg-primary px-3 py-4">
                <div className="flex flex-col items-center gap-y-2 text-base font-medium text-lightgray">
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedPeriod("This week")}
                  >
                    This week
                  </span>
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedPeriod("This month")}
                  >
                    This month
                  </span>
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedPeriod("This year")}
                  >
                    This year
                  </span>
                  <span
                    className="text-lightblue hover:cursor-pointer"
                    onClick={() => setSelectedPeriod("Custom range")}
                  >
                    Custom range
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr className="w-full px-0" color="#AEAEAE" />
        <div className="flex w-full gap-x-8 p-10">
          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#279F51]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">
                Total amount received
              </span>
              <span className="font-semibold text-secondary md:text-2xl">
                ₦{amountReceived?.total_amount_received?.toLocaleString() || 0}
              </span>
            </div>
          </div>
          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#623CEA]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">
                Total commission
              </span>
              <span className="font-semibold text-secondary md:text-2xl">
                ₦{amountCommissioned?.total_commission_balance?.toLocaleString() || 0}
              </span>
            </div>
          </div>
        </div>
        <BarChart
          width={1200}
          height={300}
          data={groupedData}
          margin={{
            top: 20,
            right: 50,
            left: 50,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="transaction_date" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="amount" barSize={20} stackId="a" fill="#279F51" />
          <Bar dataKey="commission" barSize={20} stackId="a" fill="#623CEA" />
        </BarChart>
      </div>
    </div>
  );
};

export default RevenueChart;
