import React from "react";

const ProjectDeliveryExtention = () => {
  return (
    <div className="w-full py-4 md:border-[1px] md:border-[#AEAEAE] md:px-5">
      <h1 className="text-center text-base font-bold text-secondary md:text-xl">
        Project Delivery Date Extension Request
      </h1>
      <p className="mt-2 text-sm leading-5 md:mt-5 md:text-base">
        The vendor, John Doe has requested a delivery date extension for the service "Plumbing
        service for my bathroom".
      </p>
      <p className="mt-5 text-sm text-[#5F5F5F]">Current delivery date and time</p>
      <h5 className="text-base font-medium text-black">23 Dec 2023, 6 pm</h5>

      <h6 className="mt-3 text-sm text-[#5F5F5F] md:mt-6">Reason for extension</h6>
      <div className="w-full bg-[#DEDEDE] p-3 ">
        <p className="text-sm leading-5 md:text-base md:leading-6">
          Yorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit
          interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per
          conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum
          lobortis.
        </p>
      </div>
      <p className="mt-5 text-sm text-[#5F5F5F]">Proposed new delivery date and time</p>
      <h5 className="text-base font-medium text-black">23 Dec 2023, 6 pm</h5>
    </div>
  );
};

export default ProjectDeliveryExtention;
