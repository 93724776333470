import React, { useRef, useState } from "react";
import { Button, Input } from "../../../../../components/ui";
import { addFile, sucessMark } from "../../../../../constants/images";
import General_Modal from "../../../projects/shared/modals";
import ReuseAbleSucessCard from "../../../reuseAbleSuceesCard";
import { useNavigate } from "react-router-dom";
const PostJobForm = ({ currentStep, nextStep, prevStep }) => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(null);
  const handleOnClose = () => setShowModal(null);
  const [formDetails, setFormDetails] = useState({
    category: "",
    title: "",
    description: "",
    requirements: "",
    location: "",
    address: "",
    budget: "",
    dateFrom: "",
    dateTo: "",
    documents: [],
  });

  const [errorState, setErrorState] = useState({
    categoryError: "",
    titleError: "",
    descriptionError: "",
    requirementsError: "",
    locationError: "",
    addressError: "",
    budgetError: "",
    dateFromError: "",
    dateToError: "",
    documentsError: [],
  });

  const validate = () => {
    let isError = false;
    const errors = {};

    if (currentStep === 1) {
      if (formDetails.category === "") {
        isError = true;
        errors.categoryError = "Please select a category";
      }
      if (formDetails.title === "") {
        isError = true;
        errors.titleError = "Please enter a job title";
      }
      if (formDetails.description === "") {
        isError = true;
        errors.descriptionError = "Please enter a job description";
      }
      if (formDetails.requirements === "") {
        isError = true;
        errors.requirementsError = "Please enter job requirements";
      }
    }

    if (currentStep === 2) {
      if (formDetails.location === "") {
        isError = true;
        errors.locationError = "Please select a location";
      }
      if (formDetails.budget === "") {
        isError = true;
        errors.budgetError = "Please enter a budget";
      }
      if (formDetails.dateFrom === "") {
        isError = true;
        errors.dateFromError = "Please enter a date";
      }
      if (formDetails.dateTo === "") {
        isError = true;
        errors.dateToError = "Please enter date";
      }
      if (formDetails.documents === "") {
        isError = true;
        errors.documentsError = "please add relevants documents";
      }
    }

    setErrorState(errors);
    return isError;
  };

  const handleFormChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };
  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    setFormDetails({
      ...formDetails,
      documents: [...formDetails.documents, ...files],
    });
  };

  const handleRemoveFile = (index) => {
    const updatedDocuments = [...formDetails.documents];
    updatedDocuments.splice(index, 1);
    setFormDetails({ ...formDetails, documents: updatedDocuments });
  };
  const handleNext = (e) => {
    e.preventDefault();
    const error = validate();
    if (!error) {
      nextStep();
    }
  };

  const handlePrev = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div>
      <div className="mb-4">
        <h5>Step {currentStep}/3</h5>
        <div className="h-2 w-full rounded-md bg-gray-200">
          <div
            className="h-2 rounded-md bg-[#0096C1]"
            style={{ width: `${(currentStep / 3) * 100}%` }}
          />
        </div>
      </div>
      {currentStep === 1 && (
        <div className="">
          <h2 className="mt-3 font-bold text-black">Job details</h2>

          <form onSubmit={handleNext} className="flex flex-col space-y-6">
            <div>
              <label htmlFor="" className="text-lg">
                Which category of service does your job fall under?
              </label>
              <select
                name="category"
                className="w-full border-2 border-[#F0F0F0] py-2 pl-2 outline-none"
                onChange={handleFormChange}
              >
                <option value="">Select a category</option>
                <option value="category1">Category 1</option>
                <option value="category2">Category 2</option>
              </select>
              <span className="text-sm text-[#e62e2e]">{errorState.categoryError}</span>
            </div>
            <div>
              <label htmlFor="" className="text-lg">
                Give a title to your job post
              </label>
              <Input className="w-full py-2 pl-2" onChange={handleFormChange} name="title" />
              <span className="text-sm text-[#e62e2e]">{errorState.titleError}</span>
            </div>
            <div>
              <label htmlFor="" className="text-lg">
                Job description
              </label>
              <textarea
                className="h-36 w-full border-2 border-[#F0F0F0] py-2 pl-2 outline-none"
                name="description"
                onChange={handleFormChange}
              />
              <span className="text-sm text-[#e62e2e]">{errorState.descriptionError}</span>
            </div>
            <div>
              <label htmlFor="" className="text-lg">
                Specific job requirements
              </label>
              <textarea
                className="h-36 w-full border-2 border-[#F0F0F0] py-2 pl-2 outline-none"
                onChange={handleFormChange}
                name="requirements"
              />
              <span className="text-sm text-[#e62e2e]">{errorState.requirementsError}</span>
            </div>

            <Button children="Proceed" className="w-full" />
          </form>
        </div>
      )}
      <div>
        {currentStep === 2 && (
          <div>
            <h2 className="mt-3 font-bold text-black">Job logistics</h2>
            <form onSubmit={handleNext} className="flex flex-col gap-4">
              <div>
                <label className="mt-1 text-lg">Location</label>

                <select
                  name="location"
                  className="w-full border-2 py-2"
                  onChange={handleFormChange}
                >
                  <option value="select">select location</option>
                  <option value="Physical">Physical</option>
                  <option value="Remote">Remote</option>
                </select>
                <span className="text-sm text-[#e62e2e]">{errorState.locationError}</span>
              </div>
              <div className="mt-3">
                <label className="text-lg">What is your budget?</label>
                <Input
                  name="budget"
                  className="w-full rounded-sm py-2 pl-2"
                  onChange={handleFormChange}
                />
                <span className="text-sm text-[#e62e2e]">{errorState.budgetError}</span>
              </div>
              <div className="mt-3">
                <label className="text-lg">Date</label>
                <Input
                  type="date"
                  placeholder="From"
                  name="dateFrom"
                  className="w-full rounded-sm bg-[#F0F0F0] py-2 pl-2"
                  onChange={handleFormChange}
                />
                <span className="text-sm text-[#e62e2e]">{errorState.dateFromError}</span>
                <Input
                  type="date"
                  placeholder="To"
                  name="dateTo"
                  className="mt-2 w-full rounded-sm bg-[#F0F0F0] py-2 pl-2"
                  onChange={handleFormChange}
                />
                <span className="text-sm text-[#e62e2e]">{errorState.dateToError}</span>
              </div>

              <div className="mt-3">
                <label className="text-lg">Upload supporting documents or images</label>
                <div
                  onClick={() => fileInputRef.current.click()}
                  className="flex cursor-pointer items-center"
                >
                  <img src={addFile} alt="Add file" className="mr-2" />
                  <span>Add files</span>
                </div>
                <input
                  ref={fileInputRef}
                  type="file"
                  multiple
                  className="hidden"
                  onChange={handleFileUpload}
                />
                <div className="mt-3 flex gap-2">
                  {formDetails.documents.map((file, index) => (
                    <div key={index} className="relative">
                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        className="h-20 w-20 object-cover"
                      />
                      <button
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                        className="absolute right-0 top-0 text-red-500"
                      >
                        ✖
                      </button>
                    </div>
                  ))}
                </div>
                <span className="text-sm text-[#e62e2e]">{errorState.documentsError}</span>
              </div>

              <div className="mt-5 flex justify-between">
                <Button children="Proceed" className="w-full" type="submit" />
              </div>
            </form>
          </div>
        )}
      </div>

      {currentStep === 3 && (
        <div>
          <h2 className="font-bold text-black">Review job post</h2>
          <div>
            <p>Job Title: {formDetails.title}</p>
            <p>Description: {formDetails.description}</p>
            <p>Requirements: {formDetails.requirements}</p>
            <p>Location: {formDetails.location}</p>
            <p>Budget: {formDetails.budget}</p>
            <p>
              Date: {formDetails.dateFrom} - {formDetails.dateTo}
            </p>
          </div>
          <div className="mt-10 flex space-x-4">
            <Button
              children="Edit"
              backgroundColor="#ffff"
              textColor="#0096C1"
              className="w-1/2"
              onClick={handlePrev}
            />
            <Button children="Post job" className="w-1/2" onClick={() => setShowModal("post")} />
          </div>
        </div>
      )}
      {showModal === "post" && (
        <General_Modal onClose={handleOnClose} width="35%">
          <ReuseAbleSucessCard
            button="Track job post"
            image={sucessMark}
            heading="Job posted successfully"
            message="Sit back and wait for qualified vendors to apply for the job"
            setShowModal={setShowModal}
            onClick={() => navigate("/jobs/track-jobs")}
          />
        </General_Modal>
      )}
    </div>
  );
};

export default PostJobForm;
