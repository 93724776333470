import { createSlice } from "@reduxjs/toolkit";
import { profile } from "../constants/images";
const initialState = {
  user: {
    id: null,
    name: "Tim John",
    email: "timmark@gmail.com",
    role: "consumer",
    phone: "+234 1234567890",
    address: "Yaba, Lagos",
    avatar: profile,
    token: null,
    tokenExpired: false,
    notifications: [],
    notificationsCount: 0,
    lastActivity: null,
    lastActivityTime: null,
    lastActivityTimeAgo: null,
    settings: {
        language: 'en',
        theme: 'light',
        notifications: true,
        notificationsSound: true,
        notificationsVibrate: true,
        notificationsPopup: true,
        notificationsEmail: true,
        notificationsSMS: true,
        notificationsWhatsapp: true,
        notificationsFacebookMessenger: true,
        notificationsTwitter: true,
        notificationsLinkedIn: true,
        notificationsInstagram: true,
        notificationsPinterest: true,
        notificationsReddit: true,
        notificationsTikTok: true,
        notificationsYouTube: true,
        notificationsGoogle: true,
  },
}
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = {...state.user, ...action.payload}
        },
        logoutUser: (state) => {
            state.user = null;
        }
    },
})

export const { setUser, logoutUser } = userSlice.actions;