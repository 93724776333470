import React from "react";
import { notisblueIcon } from "../../../../constants/images";

const NotificationCard = ({ days, notifications }) => {
  return (
    <div className="">
      <h3 className="border-b pb-2 pt-5 text-[16px] font-semibold text-[#002D3A] md:text-[20px]">
        {days}
      </h3>
      {notifications.map(
        (notification, index) =>
          notification.conntent && (
            <div key={index} className="mt-2 flex items-center space-x-3 border-b pb-2">
              <div className="rounded-full border-2 border-[#002D3A] p-1">
                <img src={notisblueIcon} alt="" />
              </div>
              <div>
                <h3 className="text-[14px] font-semibold md:text-[16px]">{notification.heading}</h3>
                <p className="text-[14px] md:text-[16px]">{notification.conntent}</p>
              </div>

            </div>
          ),
      )}
    </div>
  );
};

export default NotificationCard;
