import React from "react";

const CardDispute = ({ project_number, project_title, opened, status }) => {
  const statusColor =
    status === "unresolved"
      ? "text-[#FFA000]"
      : status === "resolved"
        ? "text-[#279F51]"
        : "text-[#623CEA]";

  return (
    <div className="w-full rounded-md border-[1px] border-[#AEAEAE] px-5 py-3">
      <div className="hidden md:flex md:justify-between">
        <span className="text-[12px] text-[#5F5F5F] md:text-[16px]">
          Project number:
          <span className="text-[16px] md:text-[18px]"> {project_number}</span>
        </span>
        <div>
          <span className="hidden text-[12px] text-[#5F5F5F] md:inline md:text-[16px]">
            Opened:
          </span>
          <span className="text-[12px] md:text-[18px]"> {opened}</span>
        </div>
      </div>
      <div className="flex justify-between md:hidden">
        <span className="text-[12px] text-[#5F5F5F] md:text-[16px]">
          Project number:
          <span className="text-[16px] md:text-[18px]"> {project_number}</span>
        </span>
        <div>
          <span className="hidden text-[12px] text-[#5F5F5F] md:inline md:text-[16px]">
            Opened:
          </span>
          <span className="text-[12px] md:text-[18px]"> {opened}</span>
        </div>
      </div>

      <h3 className="md:mt-5 mt-2 text-[16px] text-[#5F5F5F]">Project Title</h3>
      <div className="md:flex md:justify-between hidden">
        <span className="block text-[16px] font-medium text-[#002D3A] md:text-[18px]">
          {project_title}
        </span>
        <span className="block text-[#5F5F5F]">
          Resolution status:
          <span className={`${statusColor} text-[16px] font-medium md:text-[18px]`}> {status}</span>
        </span>
      </div>
      <div className="flex flex-col md:hidden">
        <span className="block text-[16px] font-medium text-[#002D3A] md:text-[18px]">
          {project_title}
        </span>
        <span className="block text-[#5F5F5F]">
          Resolution status:
          <span className={`${statusColor} text-[16px] font-medium md:text-[18px]`}> {status}</span>
        </span>
      </div>
    </div>
  );
};
export default CardDispute;
