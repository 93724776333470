import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Back, Clock, LocationTwo, Star } from "../../../../../../../constants/images";
import { setShowVendor } from "../../../../../../../redux/slice/modalOverlay";
import { formatDate } from "../../../../../../../utils/dateUtils";

const VendorDetails = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.token.states.accessToken)
  const vendorId = useSelector((state) => state.modalOverlay.states.vendorId);
  const [vendorDetail, setVendorDetails] = useState(null);

  const handleBackClick = () => {
    dispatch(setShowVendor(false));
  };

  const fetchVendorDetails = async (id) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/vendor/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      console.log("vendorDetails", data);
      setVendorDetails(data || []);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchVendorDetails(vendorId);
  }, [vendorId]);

  // Parsing catalog_url if available
  const catalogImages = vendorDetail?.catalog_url ? JSON.parse(vendorDetail.catalog_url) : [];

  // Function to convert 24-hour time format to 12-hour am/pm format
  const formatTime = (timeString) => {
    if (!timeString) return null;

    const [hour, minute] = timeString.split(":").map(Number);
    const period = hour >= 12 ? "pm" : "am";
    const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format
    return `${formattedHour}:${minute < 10 ? "0" : ""}${minute}${period}`;
  };

  // Function to create time range for a single day
  const formatTimeRange = (open, close) => {
    if (!open || !close) return "Closed";
    return `${formatTime(open)} - ${formatTime(close)}`;
  };

  // Function to group days with similar time ranges
  const getBusinessHours = (vendorDetail) => {
    if (!vendorDetail) {
      return [];
    }
    const days = [
      { name: "Sunday", open: vendorDetail.sunday_open, close: vendorDetail.sunday_close },
      { name: "Monday", open: vendorDetail.monday_open, close: vendorDetail.monday_close },
      { name: "Tuesday", open: vendorDetail.tuesday_open, close: vendorDetail.tuesday_close },
      { name: "Wednesday", open: vendorDetail.wednesday_open, close: vendorDetail.wednesday_close },
      { name: "Thursday", open: vendorDetail.thursday_open, close: vendorDetail.thursday_close },
      { name: "Friday", open: vendorDetail.friday_open, close: vendorDetail.friday_close },
      { name: "Saturday", open: vendorDetail.saturday_open, close: vendorDetail.saturday_close },
    ];

    let result = [];
    let currentRange = null;

    days.forEach((day, index) => {
      const timeRange = formatTimeRange(day.open, day.close);

      // Initialize a new range if it's the first or different from the current one
      if (!currentRange || currentRange.timeRange !== timeRange) {
        if (currentRange) {
          result.push(currentRange);
        }
        currentRange = { days: [day.name], timeRange };
      } else {
        currentRange.days.push(day.name); // Group the same time range for consecutive days
      }

      // Add the last group at the end
      if (index === days.length - 1 && currentRange) {
        result.push(currentRange);
      }
    });

    return result;
  };
  const businessHours = getBusinessHours(vendorDetail);
  return (
    <>
      {
        <div className="flex justify-between rounded-[10px] bg-primary px-10 py-12 pt-10 shadow-lg">
          <div className="flex w-full flex-col gap-y-5">
            <div className="flex items-center justify-start gap-x-2">
              <img
                src={Back}
                alt="back"
                className="hover:cursor-pointer"
                onClick={handleBackClick}
              />
              <span className="text-base font-medium text-[#181818]">Back</span>
            </div>
            <div className="flex items-center gap-x-4">
              <img src={vendorDetail?.profile_picture} className="w-[75px]" alt="" />
              <div className="flex flex-col">
                <span className="text-lg font-semibold text-secondary">{vendorDetail?.name}</span>
                <span className="text-base font-semibold text-lightgray">
                  {vendorDetail?.service_category}
                </span>
                <div className="flex items-center gap-x-1 text-sm">
                  <img src={Star} alt="rating" />
                  <span className="font-semibold text-[#181818]">{/* {value.rating} */}</span>
                  <span className="font-normal text-lightgray">{/* ({value.count}) */}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-xl font-semibold text-secondary">
                {vendorDetail?.business_name}
              </span>
              <span className="text-sm font-medium text-secondary">Business hours</span>
              <div className="flex flex-col gap-y-2 pt-2">
                <div>
                  {/* <img src={Clock} alt="time" /> */}
                  <span></span>
                  <span></span>

                  <ul>
                    {businessHours.map((range, index) => (
                      <li key={index}>
                        <img src={Clock} alt="time" />
                        {range.days.join(" - ")}: {range.timeRange}
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  {/* <img src={Clock} alt="time" /> */}
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-sm font-medium text-secondary">Address</span>
              <div className="flex items-center gap-x-1">
                <img src={LocationTwo} alt="location" />
                <span className="text-base font-semibold text-secondary">
                  {vendorDetail?.business_address}
                </span>
              </div>
            </div>
            <div className="flex w-3/4 flex-col">
              <span className="text-sm text-lightgray">Description</span>
              <span className="font-medium text-[#181818] lg:text-lg">
                {vendorDetail?.service_description}
              </span>
            </div>
            <div className="flex flex-col items-start gap-y-2">
              <span className="text-sm text-lightgray">Catalog</span>
              <div className="grid grid-cols-2 gap-2">
                {catalogImages.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    className="h-[180px] w-[200px] object-contain"
                    alt={`Catalog item ${index + 1}`}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col gap-y-10 px-20 py-2 ">
            <div className="h-fit w-full rounded-[5px] border border-[#AEAEAE] px-4">
              <div className="flex flex-col">
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Join Date</span>
                  <span className="text-base font-semibold text-secondary">
                    {formatDate(vendorDetail?.joinDate)}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">User ID</span>
                  <span className="text-base font-semibold text-secondary">
                    {vendorDetail?.userId}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Phone number</span>
                  <span className="text-base font-semibold text-secondary">
                    {vendorDetail?.phoneNumber}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Email</span>
                  <span className="text-base font-semibold text-secondary">
                    {vendorDetail?.email}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Gender</span>
                  <span className="text-base font-semibold text-secondary">
                    {vendorDetail?.gender}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Location</span>
                  <span className="text-base font-semibold text-secondary">
                    {vendorDetail?.location}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Category</span>
                  <span className="text-base font-semibold text-secondary">
                    {vendorDetail?.service_category}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Specialty</span>
                  <span className="text-base font-semibold text-secondary">
                    {vendorDetail?.specialty}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Years of experience</span>
                  <span className="text-base font-semibold text-secondary">
                    {vendorDetail?.years_of_experience}
                  </span>
                </div>
                <hr className="w-full" color="#AEAEAE" />
                <div className="flex w-full justify-between py-2">
                  <span className="text-sm font-normal text-lightgray">Base price rate</span>
                  <span className="text-base font-semibold text-secondary">
                    ₦{vendorDetail?.base_rate_amount?.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default VendorDetails;
