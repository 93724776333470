export const disputeData = 
[
    {
        project_number: "#783451",
        project_title : "Plumbing service for my bathroom",
        opened: "24 Dec 2023",
        status:"unresolved",
    },
    {
        project_number: "#783451",
        project_title : "Plumbing service for my bathroom",
        opened: "24 Dec 2023",
        status:"resolving",
    },
    {
        project_number: "#783451",
        project_title : "Plumbing service for my bathroom",
        opened: "24 Dec 2023",
        status:"resolved",
    },
]