import React, { useState } from "react";
import { Button } from "../../../../../../components/ui";
import { left } from "../../../../../../constants/images";

const reasons = [
  {
    id: 0,
    title: "I can't find suitable vendors",
  },
  {
    id: 1,
    title: "I have privacy concerns",
  },
  {
    id: 2,
    title: "I'm no longer interested",
  },
  {
    id: 3,
    title: "No reason",
  },
  {
    id: 4,
    title: "Other reasons",
  },
];

const CloseAccountModal = ({ showCloseAccount, setShowCloseAccount }) => {
  const [actionType, setActionType] = useState("closeAccount");
  const [reasonId, setReasonId] = useState(null);
  const [reason, setReason] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  if (!showCloseAccount) return null;

  const handleCancel = () => {
    setShowCloseAccount(false);
  };

  const handleReasonClick = (a, b) => {
    setReasonId(a);
    setReason(b);
    setShowDropdown(false);
  };
  return (
    <>
      <div className="fixed z-[99] h-screen w-screen bg-black bg-opacity-35" />
      <div className="fixed bottom-0 left-0 top-[30%] z-[999] md:h-fit w-full animate-slide_up rounded-tl-[20px] rounded-tr-[20px] bg-primary md:bottom-[10%] md:left-[35%] md:top-[35%] md:mx-0 md:w-[30%] md:animate-none md:rounded-[5px]">
        <span
          className="absolute right-6 top-2 text-xl font-medium hover:cursor-pointer md:right-10"
          onClick={handleCancel}
        >
          x
        </span>

        {actionType === "closeAccount" && (
          <div className="flex flex-col items-start justify-center gap-y-4 px-4 py-10 md:px-10">
            <span className="w-full text-center text-lg font-medium text-secondary md:text-xl">
              Are you sure you want to close your account?
            </span>
            <div className="flex flex-col px-10">
              <span className="text-base font-normal text-[#181818] md:text-lg">
                Closing your account will
              </span>
              <ul className="list-disc text-sm font-normal text-[#181818] md:text-base">
                <li>Log you out of all devices</li>
                <li>Delete all of your account information</li>
              </ul>
            </div>
            <div className="absolute bottom-10 left-0 mx-auto flex w-full items-center gap-x-3 px-6 md:relative md:mt-20 md:px-0 ">
              <Button
                className="w-full"
                backgroundColor="transparent"
                textColor="#0096C1"
                onClick={handleCancel}
              >
                No
              </Button>
              <Button className="w-full" onClick={() => setActionType("closeReason")}>
                Yes
              </Button>
            </div>
          </div>
        )}

        {actionType === "closeReason" && (
          <div className="flex flex-col items-start justify-center gap-y-4 px-4 py-10 md:px-10">
            <span className="w-full text-start text-lg font-medium text-secondary md:text-xl">
              We are sad to see you go
            </span>
            <div className="flex flex-col">
              <span className="text-base font-normal text-[#181818] md:text-lg">
                Just one more thing. Why do you want to close your account?
              </span>
            </div>
            <div className="flex w-full flex-col gap-y-6">
              <div className="relative rounded-[5px] border border-[#AEAEAE] py-2">
                <div
                  className="flex items-center justify-between px-4 py-2 text-sm md:text-base"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <span>{reason}</span>
                  <img
                    src={left}
                    alt=""
                    className={`${showDropdown ? "rotate-90" : "rotate-0"} transition-transform ease-out`}
                  />
                </div>
                {showDropdown && (
                  <div className="absolute top-16 z-40">
                    <div className="flex flex-col gap-y-1 bg-slate-300 px-3 py-4">
                      {reasons.map((reason) => (
                        <span
                          key={reason.title}
                          onClick={() => handleReasonClick(reason.id, reason.title)}
                          className="hover:cursor-pointer md:text-base text-sm"
                        >
                          {reason.title}{" "}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {reasonId === 4 && (
                <div className="flex w-full flex-col space-y-1">
                  <label
                    htmlFor="other"
                    className="text-sm font-normal text-lightgray md:text-base"
                  >
                    Please specify
                  </label>
                  <input
                    type="text"
                    name="other"
                    id="other"
                    className="rounded-[5px] border border-[#AEAEAE] px-4 py-2 outline-none text-sm md:text-base"
                  />
                </div>
              )}
            </div>
            <div className="absolute left-0 bottom-10 mx-auto w-full px-6 md:static md:px-0 ">
              <Button className="w-full" onClick={() => setActionType("closeAccount")}>
                Close Account
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CloseAccountModal;
