import React, { useEffect, useState } from "react";
import {
  copyIcon,
  earnIcon,
  left,
  promoteIcon,
  referNEarn,
} from "../../../../../../constants/images";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShowMobileRefer } from "../../../../../../features/overlay";
import { Button } from "../../../../../../components/ui";

const OfferContent = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const showMobileRefer = useSelector((state) => state?.overlay.displayStates.showMobileRefer);
  const [code, setCode] = useState('GYWDE-TOMWPJ')
  const [copied, setCopied] = useState(false);


  useEffect(() => {
    const fetchCode = async () => {
      try {
        const response = await fetch("", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          // setCode(data.code);
        } else {
          // throw new Error();
        }
      } catch (error) {
        // setError(error.message);
      }
    };
    fetchCode();
  }, []);

  const handleMobileRefer = () => {
    dispatch(setShowMobileRefer(true));
  };

  const handleReferBackClick = () => {
    dispatch(setShowMobileRefer(false));
  };

  const handleCodeCopy = () => {
    navigator.clipboard.writeText(code).then(
      () => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      },
      (err) => {
      },
    );
  };

  return (
    <div className="relative h-[120vh] max-w-full items-start justify-center pl-0 pr-0 pt-[4rem] md:float-right md:mx-auto md:ml-auto md:mr-0 md:w-[81%] md:pt-40">
      {!showMobileRefer && (
        <Link to="/vendor-dashboard/home" className="relative block md:hidden">
          <img src={left} alt="go back" className="absolute left-4 top-12 w-[10px]" />
        </Link>
      )}
      <div className="mt-10 flex flex-col justify-between md:mt-0 md:w-[65%] md:px-20">
        {!showMobileRefer && (
          <span className="mx-4 text-center text-[25px] font-medium text-secondary md:mx-0 md:text-start">
            Offers
          </span>
        )}
        <div>
          <div className="mb-10 mt-10 hidden space-y-3 rounded-[5px] border border-[#AEAEAE] py-4 md:block">
            <div className="flex items-center gap-x-3 px-6">
              <img src={earnIcon} alt="earn" />
              <span className="text-lg font-normal text-secondary">Refer and earn</span>
            </div>
            <hr color="#AEAEAE" className="w-full" />
            <div className="flex justify-center px-14 py-8">
              <div className="flex flex-col items-center gap-y-4">
                <img src={referNEarn} alt="refer and earn" className="w-[150px]" />
                <div className="relative flex">
                  <input
                    type="text"
                    className="rounded-[5px] border-4 border-lightblue px-2 py-1 outline-none"
                    value={code}
                  />
                  <div className="absolute right-0 w-[90px] bg-lightblue px-2 py-2 text-primary hover:cursor-pointer">
                    <div
                      className="flex items-center justify-center gap-x-2 text-sm"
                      onClick={handleCodeCopy}
                    >
                      <img src={copyIcon} alt="" />
                      <span>{copied ? "Copied!" : "Copy"}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-3/4 flex-col space-y-4 px-6 text-lg text-secondary">
                <span>
                  Invite a friend to join GYWDE and get{" "}
                  <span className="font-medium text-lightblue">₦1000</span> on the first order of
                  each friend that joins using your referral code
                </span>
                <span>
                  Your friend also gets <span className="font-medium text-lightblue">₦500</span> off
                  on their first order
                </span>
              </div>
            </div>
          </div>
          {!showMobileRefer && (
            <>
              <div
                className="mx-4 my-4 block rounded-[5px] border border-[#AEAEAE] py-4 md:hidden"
                onClick={handleMobileRefer}
              >
                <div className="flex items-center justify-between px-6">
                  <div className="flex w-full items-center justify-between gap-x-3">
                    <div className="flex items-center gap-x-2">
                      <img src={earnIcon} alt="earn" />
                      <span className="text-lg font-normal text-secondary">Refer and earn</span>
                    </div>
                    <img src={left} alt="" />
                  </div>
                </div>
              </div>
              <div className="mx-4 rounded-[5px] border border-[#AEAEAE] py-4 md:mx-0">
                <div className="flex items-center justify-between px-6">
                  <div className="flex items-center gap-x-3">
                    <img src={promoteIcon} alt="promote" />
                    <span className="text-lg font-normal text-secondary">Promotions</span>
                  </div>
                  <img src={left} alt="" />
                </div>
              </div>
            </>
          )}
          {showMobileRefer && (
            <div className="relative flex w-full flex-col items-center gap-y-5 text-center">
              <img
                src={left}
                alt="go back"
                className="absolute left-8 top-[1.6rem] w-[10px]"
                onClick={handleReferBackClick}
              />
              <div className="mt-5 flex flex-col justify-between gap-y-3">
                <span className="mx-4 text-center text-xl font-medium text-secondary md:mx-0 md:text-start">
                  Refer and earn
                </span>
                <img src={referNEarn} alt="refer and earn" className="mx-auto w-[223px]" />
                <div className="relative w-fit mx-auto items-center justify-center flex my-5">
                  <input
                    type="text"
                    className="rounded-[5px] border-4 border-lightblue px-2 py-1 outline-none"
                    value={code}
                  />
                  <div className="absolute right-0 w-[90px] rounded-[5px] bg-lightblue px-2 py-2 text-primary hover:cursor-pointer">
                    <div
                      className="flex items-center justify-center gap-x-2 text-xs"
                      onClick={handleCodeCopy}
                    >
                      <img src={copyIcon} alt="" />
                      <span>{copied ? "Copied!" : "Copy"}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex flex-col gap-y-8 px-6">
                    <span>
                      Invite a friend to join GYWDE and get{" "}
                      <span className="font-medium text-lightblue">₦1000</span> on the first order
                      of each friend that joins using your referral code
                    </span>
                    <span>
                      Your friend also gets <span className="font-medium text-lightblue">₦500</span>{" "}
                      off on their first order
                    </span>
                  </div>
                </div>
              </div>

              <div className="mx-auto w-5/6 rounded-[5px] border border-[#AEAEAE] py-2">
                <div className="flex items-center justify-between px-6">
                  <span className="text-sm font-normal text-lightgray">View referral history</span>
                  <img src={left} alt="" />
                </div>
              </div>
              <div className="mx-4 w-5/6 pt-6">
                <Button className="w-full !text-base">Refer Now</Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferContent;
