import React from "react";
import { mission } from "../../../../constants/images";

const MissionSection = () => {
  return (
    <div>
      <div className="flex flex-col md:flex-row lg:gap-[22rem] md:gap-10 lg:py-40 lg:pb-20 lg:px-[9rem] px-8 py-12  mx-auto">
        <div className="flex items-start order-last md:order-first md:mt-0 mt-10">
          <img
            src={mission}
            alt="about us"
            className="lg:max-w-[250%] md:max-w-full"
          />
        </div>

        <div className="flex lg:gap-0 gap-6 flex-col justify-around lg:w-full md:w-[70%] order-first md:order-last">
          <div className="flex flex-col">
            <span className="lg:text-[30px] md:text-[20px] text-[25px] text-secondary font-semibold">
              Our Mission
            </span>
            <span className="text-lightgray font-normal lg:text-lg">
            To be the leading provider of technology services, recognised for 
our innovative solutions, exceptional client care, and commitment 
to drive digital transformation.
            </span>
          </div>
          <div className="flex flex-col">
            <span className="lg:text-[30px] md:text-[20px] text-[25px] text-secondary font-semibold">
              Our Vison
            </span>
            <span className="text-lightgray font-normal lg:text-lg">
            At GYWDE, we are driven by the vision of 
transforming technology into tangible benefits for businesses 
and individuals alike, our mission is to revolutionise the way 
services are delivered in the digital era, creating value through
innovative solutions and exceptional client experiences.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionSection;
