export const formatDate = (dateString) => {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
    ];
  
    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const day = date.getDate();

   if(dateString)return `${month} ${day} ${year}`
    else {return("")}
  };