import React from "react";
import Container from "../../../container";
import { visaLogo, verve, Mastercard, cardDelete } from "../../../../../constants/images";
const PaymentDetails = () => {
  return (
    <Container>
      <div className="px-5 py-10 md:px-28 md:py-20">
        <h2 className="mb-4 text-[25px] font-medium text-[#002D3A]">Payments</h2>
        <div className=" border-[1px] py-4  md:border-gray-500">
          <div className="flex items-center justify-between px-8 pb-3 md:border-b-[1px] md:border-b-gray-500">
            <h3 className="mb-2 font-semibold">Manage Cards</h3>
            <button className=" rounded-lg border-2 border-[#0096C1] px-4  py-2 font-medium text-[#0096C1]">
              Add New Card
            </button>
          </div>
          <div className="space-y-2 px-8 py-5">
            {[visaLogo, verve, Mastercard].map((card, index) => (
              <div
                key={index}
                className="flex items-center justify-between rounded-lg border-[1px] border-gray-500 px-4 py-3"
              >
                <div className="flex items-center gap-4">
                  <img src={card} alt={"card"} className="h-4" />
                  <p>**** {index + 3456}</p>
                </div>
                <img src={cardDelete} alt="cardDelete" />
              </div>
            ))}
          </div>
        </div>
        <div className="mt-10 border-[1px]  py-4 md:border-gray-500">
          <div className="border-b-[1px] md:border-b-gray-500">
            <h3 className="mb-2 px-8 font-semibold">Transactions</h3>
          </div>
          <div className="space-y-2 px-8 py-5">
            {[
              { date: "Dec 2023", description: "Plumbing service", amount: "₦7,000" },
              { date: "Nov 2023", description: "Faucet repair", amount: "₦5,000" },
            ].map((tx, index) => (
              <div key={index} className="flex justify-between border-b pb-2">
                <p>{tx.description}</p>
                <p>{tx.amount}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PaymentDetails;
