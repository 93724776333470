import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Pagination } from "../../components";
import { vendorData } from "../../constants";
import { Dropdown } from "../../constants/images";
import { setSelectedAgentId, setShowAgent } from "../../redux/slice/modalOverlay";
import AgentTableDetails from "./_components/agent-details";
import AgentNavbar from "./_components/navbar";
const entries = [
  { id: 0, entry: 10 },
  { id: 0, entry: 20 },
  { id: 0, entry: 30 },
];
const ContractorAgents = () => {
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const dispatch = useDispatch();
  const [selectedEntry, setSelectedEntry] = useState(10);
  const [agent, setAgent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const showAgent = useSelector((state) => state.modalOverlay.states.showAgent);
  const agentSearch = useSelector((state) => state.modalOverlay.states.agentSearch);
  const { agentId } = useSelector((state) => state.modalOverlay.states);

  const fetchAgent = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/referrals/${agentId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();

      setAgent(data || []);
    } catch (error) {
      console.log("Error fetching data", error);
      setAgent([]);
    }
  };

  useEffect(() => {
    fetchAgent();
  }, []);

  const vendorDataFiltered = vendorData.filter((value) => {
    const searchTerm = agentSearch?.toLowerCase();
    const location = value.location?.toLowerCase();
    const service = value.service?.toLowerCase();
    const projectId = value.projectId?.toLowerCase();
    const phone = value.phone?.toLowerCase();
    const email = value.email?.toLowerCase();
    const gender = value.gender?.toLowerCase();
    const userId = value.userId?.toLowerCase();
    const date = value.join_date?.toLowerCase();
    return (
      location?.includes(searchTerm) ||
      projectId?.includes(searchTerm) ||
      service?.includes(searchTerm) ||
      phone?.includes(searchTerm) ||
      email?.includes(searchTerm) ||
      gender?.includes(searchTerm) ||
      userId?.includes(searchTerm) ||
      date?.includes(searchTerm)
    );
  });

  const [openSelect, setOpenSelect] = useState(false);

  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  // Pagination logic
  const itemsPerPage = selectedEntry;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = vendorDataFiltered.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(vendorDataFiltered.length / itemsPerPage);

  const handleVendorId = (e) => {
    dispatch(setSelectedAgentId(e));
    dispatch(setShowAgent(true));
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <Layout>
      {!showAgent && (
        <div className="mx-auto flex w-full flex-col rounded-[10px] bg-primary py-4">
          <div className="mb-10 w-full rounded-[10px] rounded-bl-none rounded-br-none bg-primary shadow-lg">
            <div className="flex w-full items-center  justify-between px-12 py-4">
              <div className="text-[25px] font-medium text-secondary">John's Agency</div>
            </div>
          </div>
          <AgentNavbar />
          <div className="grid w-full grid-cols-9 bg-[#E8E8E8] px-6 py-4 text-sm font-medium text-[#181818]">
            <span>User ID</span>
            <span>Name</span>
            <span>Phone Number</span>
            <span>Email</span>
            <span>Gender</span>
            <span>Location</span>
            <span>Service Category</span>
            <span>Join Date</span>
            <span>Status</span>
          </div>
          {currentData.map((value) => (
            <div
              key={value.id}
              className="transition-colors ease-in-out hover:cursor-pointer hover:bg-[#eceaea]"
              onClick={() => handleVendorId(value.id)}
            >
              <div className="grid w-full grid-cols-9 px-6 py-4">
                <span className="">{value.userId}</span>
                <span className="">{value.name}</span>
                <span className="">{value.phone}</span>
                <span className="">{value.email}</span>
                <span className="">{value.gender}</span>
                <span className="">{value.location}</span>
                <span className="">{value.service}</span>
                <span className="">{value.join_date}</span>
                <span
                  className={`${value.status === "Complete" ? "text-[#1de73f]" : "text-[#ec2727]"}`}
                >
                  {value.status}
                </span>
              </div>
              <hr color="#AEAEAE" className="w-full" />
            </div>
          ))}
          {vendorDataFiltered.length === 0 && (
            <div className="flex w-full items-center justify-center pt-4 text-xl text-secondary">
              No search results available for query
            </div>
          )}
          <div className="flex w-full justify-between px-6 pt-3">
            <div className="flex w-full">
              <div className="relative flex items-center gap-x-1">
                <span>Showing</span>
                <div
                  onClick={handleSelect}
                  className="flex w-fit items-center gap-x-4 px-5 text-base font-normal text-[#181818] hover:cursor-pointer"
                >
                  <span>{selectedEntry}</span>
                  <img
                    src={Dropdown}
                    alt="open"
                    className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
                  />
                </div>
                {openSelect && (
                  <div className="absolute bottom-[100%] left-[40%] w-[30%] animate-slide_up  rounded-[10px] bg-primary px-3 py-2">
                    <div className="flex flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
                      {entries.map((entry) => (
                        <span
                          key={entry.id}
                          className="hover:cursor-pointer"
                          onClick={() => setSelectedEntry(entry.entry)}
                        >
                          {entry.entry}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                <span>entries</span>
              </div>
            </div>
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={paginate}
              />
            </div>
          </div>
        </div>
      )}

      {showAgent && <AgentTableDetails />}
    </Layout>
  );
};

export default ContractorAgents;
