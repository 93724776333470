import React from "react";
import { Button, Input } from "../../../../../../components/ui";

const DeclineCard = () => {
  return (
    <div className="px-4 py-6">
      <h3 className="text-center text-xl font-semibold  text-secondary">
        You declined the delivery date extension
      </h3>
      <h4 className="mt-7 text-base">Choose new delivery date</h4>
      <div className="mt-2 flex space-x-3">
        <Input placeholder="Date" className="w-full bg-[#F0F0F0] py-2 pl-2" />
        <select name="" id="" className="w-full border-2 border-[#F0F0F0] py-2 pl-2 outline-none">
          <option value="">Time</option>
        </select>
      </div>
      <div className=" mt-6 flex items-center space-x-2">
        <Input type="checkbox" /> <label htmlFor="">Leave as previous date</label>
      </div>

      <Button children="Send" className="mt-8 w-full" />
    </div>
  );
};

export default DeclineCard;
