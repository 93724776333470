import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../../../features/consumerSlice";
import { Button, Input } from "../../../../../../components/ui";

const EditAddress = ({setShowModal}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [address, setAddress] = useState(user?.address || "");

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setUser({ address }));
    setShowModal(null)
  };

  return (
    <div>
      <h2 className="text-center text-[20px] font-semibold text-[#002D3A]">Edit address</h2>
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="address"
          onChange={handleAddressChange}
          value={address}
          className="mt-2 w-full rounded-sm py-2 pl-3"
        />
        <Button children="Done" className="my-10 w-full" />
      </form>
    </div>
  );
};

export default EditAddress;
