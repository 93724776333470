import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { Button } from "../../../../../../../components/ui";

const CheckWorkModal = ({ setShowModal }) => {
  const [communicationRating, setCommunicationRating] = useState(0);
  const [qualityRating, setQualityRating] = useState(0);
  const [timelinessRating, setTimelinessRating] = useState(0);
  const [overallRating, setOverallRating] = useState(0);

  const handleNextModal = () => {
    if (
      communicationRating <= 1 ||
      qualityRating <= 1 ||
      timelinessRating <= 1 ||
      overallRating <= 1
    ) {
      setShowModal("disputeModal");
    } else {
      setShowModal("thanksModal");
    }
  };

  return (
    <div className="p-4 h-full overflow-y-auto"> 
      <h3 className="text-[20px] font-semibold">Give ratings</h3>
      <h3 className="mt-4 text-[16px] font-medium">Rate vendors on the following</h3>

      <div className="flex flex-col space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-[14px] font-medium">Communication</span>
          <ReactStars
            count={5}
            onChange={setCommunicationRating}
            size={40}
            activeColor="#ffd700"
          />
        </div>

        <div className="flex items-center justify-between">
          <span className="text-[14px] font-medium">Quality of service</span>
          <ReactStars
            count={5}
            onChange={setQualityRating}
            size={40}
            activeColor="#ffd700"
          />
        </div>

        <div className="flex items-center justify-between">
          <span className="text-[14px] font-medium">Timeliness</span>
          <ReactStars
            count={5}
            onChange={setTimelinessRating}
            size={40}
            activeColor="#ffd700"
          />
        </div>

        <div className="flex items-center justify-between">
          <span className="text-[16px] font-semibold">Overall rating</span>
          <ReactStars
            count={5}
            onChange={setOverallRating}
            size={40}
            activeColor="#ffd700"
          />
        </div>

        <textarea
          rows="3"
          className="w-full rounded-md bg-[#F4F4F4] px-3 py-4 mt-3"
          placeholder="Leave your comment here (optional)"
        ></textarea>

        <Button children="Submit review" className="mt-9 w-full md:mb-0 mb-5" onClick={handleNextModal} />
      </div>
    </div>
  );
};

export default CheckWorkModal;
