import React, { useState, useRef } from "react";
import useOutsideClick from "../hooks/useOutsideClick";
const AutoComplete = ({
  data,
  onSelect,
  defaultValue,
  placeholder,
}) => {
  const [query, setQuery] = useState(defaultValue || "");
  const [filteredData, setFilteredData] = useState(data);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const ref = useRef(null);

  useOutsideClick(ref, () => setShowSuggestions(false));

  const handleSelect = (suggestion) => {
    setQuery(suggestion.name);
    onSelect(suggestion);
    setShowSuggestions(false);
  };

  const handleChange = (e) => {
    const query = e.target.value;
    setQuery(query);
    setShowSuggestions(true);
    const filteredData = data.filter((item) => item.name.toLowerCase().includes(query.toLowerCase()));
    setFilteredData(filteredData);
  };

  return (
    <div ref={ref} className="relative">
      <input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder={placeholder}
        className="w-full border border-[#AEAEAE] px-2 py-4 outline-none"
      />
      {showSuggestions && (
        <div className="absolute left-0 top-[50px] h-[200px] w-full overflow-auto rounded-[5px] bg-[#d8d6d6] text-[#181818]">
          <div className="flex w-full flex-col items-center justify-center gap-y-2 px-5 py-3 font-medium">
            {filteredData.map((suggestion) => (
              <div
                className="flex items-center gap-x-3 cursor-pointer"
                key={suggestion?.name}
                onClick={() => handleSelect(suggestion)}
              >
                <span>{suggestion?.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoComplete;