import React, { useRef, useState } from "react";
import { dropdown, uploadIcon } from "../../../../../../constants/images";
import { Button, Input } from "../../../../../../components/ui";
import { setEditServices } from "../../../../../../features/overlay";
import useOutsideClick from "../../../../../../hooks/useOutsideClick";
import { useDispatch } from "react-redux";
import { Upload } from "../../../../../../admin/constants/images";

const categories = [
  { id: 1, name: "Plumbing" },
  { id: 2, name: "Electrical" },
  { id: 3, name: "Carpentry" },
  { id: 4, name: "HVAC" },
  { id: 5, name: "Landscaping" },
];

const experiences = [
  { id: 1, years: "1-2 years" },
  { id: 2, years: "2-5 years" },
  { id: 3, years: "5-10 years" },
  { id: 4, years: "10+ years" },
];

const EditServiceModal = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [service, setService] = useState({
    category: "",
    experience: "",
    businessName: "",
  });

  const [errorState, setErrorState] = useState({
    categoryError: "",
    specialtyError: "",
    experienceError: "",
    businessNameError: "",
  });
  const [selectedDays, setSelectedDays] = useState([]);
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const [showCategory, setShowCategory] = useState(false);
  const [showYears, setShowYears] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setService({ ...service, [name]: value });
  };
  const handleSelectedDays = () => {};

  const validate = () => {
    let isError = false;
    const errors = {
      agent_nameError: "",
      company_nameError: "",
    };

    if (!details.agent_name) {
      isError = true;
      errors.agent_nameError = "Please enter agent name";
    }
    if (!details.company_name) {
      isError = true;
      errors.company_nameError = "Please enter the company name";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };
  useOutsideClick(ref, () => setShowCategory(false));
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      return;
    }
  };
  const handleClose = () => {
    dispatch(setEditServices(false));
  };
  return (
    <div className="relative animate-slide_up">
      <div className="absolute right-10 top-2 text-2xl" onClick={handleClose}>
        x
      </div>{" "}
      <div className="h-[780px] overflow-auto md:h-[565px]">
        <div className="flex flex-col justify-center rounded-[5px] bg-primary p-7 md:px-10 md:pt-10">
          <span className="my-2 text-base font-semibold text-secondary md:text-start md:text-xl">
            Edit service details
          </span>
          <form className="flex flex-col gap-y-2">
            <div className="relative flex flex-col">
              <span className="py-1 text-[#181818]">Business category</span>
              <div
                className="flex w-full items-center justify-between gap-4 rounded-[5px] border border-[#AEAEAE] px-4 py-4"
                onClick={() => setShowCategory(!showCategory)}
              >
                <span className="font-medium text-lightgray">{service.category}</span>
                <img src={dropdown} alt="category" className="cursor-pointer" />
              </div>
              {showCategory && (
                <div
                  className="absolute left-0 top-[82px] z-20 h-[200px] w-full overflow-auto rounded-[5px] bg-[#f8f8f8] text-[#181818]"
                  ref={ref}
                >
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      className="flex w-full flex-col items-center justify-center gap-y-1 px-5 py-2 font-medium"
                    >
                      <div
                        className="flex cursor-pointer items-center gap-x-3"
                        onClick={() => {
                          setService({ ...service, category: category.name });
                          setShowCategory(false);
                        }}
                      >
                        <span>{category.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <span className="text-sm text-[#e62e2e]">{errorState.categoryError}</span>{" "}
            </div>

            <div className="relative flex flex-col">
              <span className="py-1 text-[#181818]">Specialty?</span>
              <input
                type="text"
                onChange={handleChange}
                className="w-full rounded-[5px] border border-[#AEAEAE] px-2 py-3 font-medium text-lightgray outline-none"
              />
              <span className="text-sm text-[#e62e2e]">{errorState.specialtyError}</span>{" "}
            </div>

            <div className="relative flex flex-col">
              <span className="py-1 text-[#181818]">How many years of experience do you have?</span>
              <div
                className="flex w-full items-center justify-between gap-4 border border-[#AEAEAE] px-4 py-5"
                onClick={() => setShowYears(!showYears)}
              >
                <span className="font-medium text-lightgray">{service.experience}</span>
                <img src={dropdown} alt="category" />
              </div>
              {showYears && (
                <div className="absolute left-0 top-[82px] z-20 h-[200px] w-full overflow-auto rounded-[5px] bg-[#f8f8f8] text-[#181818]">
                  {experiences.map((experience) => (
                    <div
                      key={experience.id}
                      className="flex w-full flex-col items-center justify-center gap-y-1 px-5 py-2 font-medium"
                    >
                      <div
                        className="flex cursor-pointer items-center gap-x-3"
                        onClick={() => {
                          setService({ ...service, experience: experience.years });
                          setShowYears(false);
                        }}
                      >
                        <span>{experience.years}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <span className="text-sm text-[#e62e2e]">{errorState.experienceError}</span>{" "}
            </div>

            <div className="relative flex flex-col">
              <span className="py-1 text-[#181818]">Business name</span>
              <input
                type="text"
                onChange={handleChange}
                className="w-full border border-[#AEAEAE] px-2 py-3 font-medium text-lightgray outline-none"
              />
              <span className="text-sm text-[#e62e2e]">{errorState.businessNameError}</span>{" "}
            </div>

            <div className="flex flex-col items-start gap-y-1 pt-3">
              <label htmlFor="availability" className="text-sm text-[#181818] md:text-base">
                Business hours
              </label>
            </div>

            <div className="rounded-[5px] border border-[#AEAEAE] py-3">
              <div className="space-x flex flex-wrap items-center justify-between px-4 text-xs text-[#181818] md:gap-1 md:px-10 md:text-base">
                <div
                  className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("sunday") ? "bg-secondary text-primary" : "text-[#181818] "}`}
                >
                  <Input
                    type="checkbox"
                    onChange={handleSelectedDays}
                    name="day"
                    className=""
                    id="sunday"
                    value="sunday"
                  />
                  <label htmlFor="sunday" className="py-2">
                    Sun
                  </label>
                </div>
                <div
                  className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("monday") ? "bg-secondary text-primary" : "text-[#181818] "}`}
                >
                  <Input
                    type="checkbox"
                    onChange={handleSelectedDays}
                    name="day"
                    className=""
                    id="monday"
                    value="monday"
                  />
                  <label htmlFor="monday" className="py-2">
                    Mon
                  </label>
                </div>
                <div
                  className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("tuesday") ? "bg-secondary text-primary" : "text-[#181818] "}`}
                >
                  <Input
                    type="checkbox"
                    onChange={handleSelectedDays}
                    name="day"
                    className=""
                    id="tuesday"
                    value="tuesday"
                  />
                  <label htmlFor="tuesday" className="py-2">
                    Tues
                  </label>
                </div>
                <div
                  className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("wednesday") ? "bg-secondary text-primary" : "text-[#181818] "}`}
                >
                  <Input
                    type="checkbox"
                    onChange={handleSelectedDays}
                    name="day"
                    className=""
                    id="wednesday"
                    value="wednesday"
                  />
                  <label htmlFor="wednesday" className="py-2">
                    Wed
                  </label>
                </div>
                <div
                  className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("thursday") ? "bg-secondary text-primary" : "text-[#181818] "}`}
                >
                  <Input
                    type="checkbox"
                    onChange={handleSelectedDays}
                    name="day"
                    className=""
                    id="thursday"
                    value="thursday"
                  />
                  <label htmlFor="thursday" className="py-2 ">
                    Thur
                  </label>
                </div>
                <div
                  className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("friday") ? "bg-secondary text-primary" : "text-[#181818] "}`}
                >
                  <Input
                    type="checkbox"
                    onChange={handleSelectedDays}
                    name="day"
                    className=""
                    id="friday"
                    value="friday"
                  />
                  <label htmlFor="friday" className="py-2 ">
                    Fri
                  </label>
                </div>
                <div
                  className={`flex gap-1 rounded-[5px] md:gap-2 ${selectedDays.includes("saturday") ? "bg-secondary text-primary" : "text-[#181818] "}`}
                >
                  <Input
                    type="checkbox"
                    onChange={handleSelectedDays}
                    name="day"
                    className=""
                    id="saturday"
                    value="saturday"
                  />
                  <label htmlFor="saturday" className="py-2">
                    Sat
                  </label>
                </div>
              </div>
              <div className="relative flex w-full items-center gap-4 px-4 py-2 pt-6 md:px-10">
                <div className="flex w-1/2 flex-col gap-y-1">
                  <span htmlFor="open" className=" text-lightgray">
                    Opens at
                  </span>
                  <div className="flex w-full justify-between rounded-[5px] border border-[#AEAEAE] px-3 py-2 ">
                    <input
                      aria-label="Time"
                      onChange={handleChange}
                      name="openingtime"
                      defaultValue={"04:00"}
                      id="open"
                      type="time"
                      className="w-auto outline-none"
                    />
                  </div>
                </div>

                <div className="flex w-1/2 flex-col gap-y-1">
                  <span htmlFor="open" className=" text-lightgray">
                    Close at
                  </span>

                  <div className="flex w-full justify-between rounded-[5px] border border-[#AEAEAE] px-3 py-2 ">
                    <input
                      aria-label="Time"
                      onChange={handleChange}
                      name="openingtime"
                      defaultValue={"12:00"}
                      id="open"
                      type="time"
                      className="w-auto outline-none"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-auto flex items-center gap-x-2 text-lightblue">
              <img src={Upload} alt="" />
              <span>Add more</span>
            </div>
            <div className="relative flex flex-col">
              <span className="py-1 text-[#181818]">Set your base price rate</span>
              <div
                className="flex w-full items-center justify-between gap-4 rounded-[5px] border border-[#AEAEAE] px-4 py-4"
                onClick={() => setShowCategory(!showCategory)}
              >
                <span className="font-medium text-lightgray">{service.category}</span>
                <img src={dropdown} alt="category" className="cursor-pointer" />
              </div>
              {showCategory && (
                <div className="absolute left-0 top-[82px] z-20 h-[200px] w-full overflow-auto rounded-[5px] bg-[#f8f8f8] text-[#181818]">
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      className="flex w-full flex-col items-center justify-center gap-y-1 px-5 py-2 font-medium"
                    >
                      <div
                        className="flex cursor-pointer items-center gap-x-3"
                        onClick={() => {
                          setService({ ...service, category: category.name });
                          setShowCategory(false);
                        }}
                      >
                        <span>{category.name}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <span className="text-sm text-[#e62e2e]">{errorState.categoryError}</span>{" "}
            </div>
            <div className="relative flex flex-col">
              <span className="py-1 text-[#181818]">Service description</span>
              <textarea
                type="text"
                onChange={handleChange}
                className="w-full border border-[#AEAEAE] px-2 py-3 font-medium text-lightgray outline-none"
              />
              <span className="text-sm text-[#e62e2e]">{errorState.businessNameError}</span>{" "}
            </div>
            <div className="flex gap-x-2">
              <Button
                textColor={"#0096C1"}
                backgroundColor={"transparent"}
                children="Cancel"
                className="mt-10 w-full"
                onClick={(handleClose)}
              />
              <Button
                border="none"
                // type="submit"
                children="Save"
                className="mt-10 w-full"
                onClick={handleSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditServiceModal;
