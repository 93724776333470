import React from "react";
import { blueNotification } from "../../../../constants/images";
import { Link } from "react-router-dom";
const NoAlert = () => {
  return (
    <div className="mx-auto flex flex-col items-center justify-center md:w-[60%]">
      <div className="flex h-36 w-36 items-center justify-center rounded-full border-8 border-[#0096C1] md:h-44 md:w-44">
        <img src={blueNotification} alt="" />
      </div>
      <h3 className="mt-4 text-[16px] font-semibold text-[#002D3A] md:text-[20px]">
        No notification yet
      </h3>
      <p className="mt-5 text-center text-[14px] leading-[21px] md:text-[18px]">
        Get updates on your ongoing projects, new messages, and exciting promotions right here.
      </p>
      <Link to="">
        {" "}
        <h3 className="mt-7 cursor-pointer font-bold text-[#0096C1]">Browse services</h3>
      </Link>
    </div>
  );
};

export default NoAlert;
