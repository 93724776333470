import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { dropdown, left } from "../../../../../../constants/images";
import { Link } from "react-router-dom";

const options = {
  legend: {
    position: "bottom",
    textStyle: {
      fontName: "Work Sans",
      fontSize: 14,
      color: "#5F5F5F",
    },
  },
  backgroundColor: "#E6F5F9",
};

const LineChart = () => {
  const [openSort, setOpenSort] = useState(false);
  const [chartData, setChartData] = useState();
  const [activeTab, setActiveTab] = useState("all");

  useEffect(() => {
    handleAllClick();
  }, []);

  const allData = [
    ["Year", "Profile views", "Impressions", "Projects"],
    ["Dec 16", 800, 400, 3000],
    ["Dec 17", 600, 460, 400],
    ["Dec 18", 660, 1120, 560],
    ["Dec 19", 1030, 540, 2000],
    ["Dec 20", 1030, 540, 2000],
    ["Dec 21", 1030, 540, 2000],
    ["Dec 22", 400, 540, 2000],
  ];
  const profileViewsData = [
    ["Year", "Profile views"],
    ["Dec 16", 800],
    ["Dec 17", 600],
    ["Dec 18", 660],
    ["Dec 19", 1030],
    ["Dec 20", 1030],
    ["Dec 21", 1030],
    ["Dec 22", 400],
  ];

  const impressionsData = [
    ["Year", "Impressions"],
    ["Dec 16", 400],
    ["Dec 17", 460],
    ["Dec 18", 1120],
    ["Dec 19", 540],
    ["Dec 20", 540],
    ["Dec 21", 540],
    ["Dec 22", 540],
  ];

  const projectsData = [
    ["Year", "Projects"],
    ["Dec 16", 3000],
    ["Dec 17", 400],
    ["Dec 18", 560],
    ["Dec 19", 2000],
    ["Dec 20", 2000],
    ["Dec 21", 2000],
    ["Dec 22", 2000],
  ];

  const handleAllClick = () => {
    setChartData(allData);

    setActiveTab("all");
  };

  const handleProfileViewsClick = () => {
    setChartData(profileViewsData);

    setActiveTab("profileViews");
  };

  const handleImpressionsClick = () => {
    setChartData(impressionsData);

    setActiveTab("impressions");
  };

  const handleProjectsClick = () => {
    setChartData(projectsData);

    setActiveTab("projects");
  };

  return (
    <div className="relative md:mr-0 h-[120vh] max-w-full items-start justify-center pl-0 pr-0 pt-[8rem] md:float-right md:mx-auto md:ml-auto md:w-[81%] md:pt-40">
      <Link to='/vendor-dashboard/home' className="md:hidden block">
      <img src={left} alt="go back" className="absolute left-4 w-[10px]"  />
      </Link>
      <div className="flex w-full justify-between items-center md:px-20 mt-10 md:mt-0">
        <span className="text-xl font-medium text-secondary md:mx-0 mx-4">My stats</span>
        <div className="relative">
          <div
            className="flex w-[140px] cursor-pointer items-center justify-between rounded-[20px] border border-[#AEAEAE] px-5 py-2 md:mx-0 mx-4"
            onClick={() => setOpenSort(!openSort)}
          >
            <span className="text-sm font-medium text-lightgray md:text-base">Sort by</span>
            <img
              src={dropdown}
              alt="sort"
              className={`${openSort && "rotate-180 transition-all ease-in"}`}
            />
          </div>

          {openSort && (
            <div className="absolute bottom-0 md:top-[100%] w-[140px] z-10">
              <div className="flex flex-col items-center justify-center gap-y-2 bg-primary text-sm md:text-base font-medium">
                <span>Last 1 week</span>
                <hr color="#181818" className="w-full" />
                <span>Last 1 week</span>
                <hr color="#181818" className="w-full" />
                <span>Last 1 week</span>
                <hr color="#181818" className="w-full" />
              </div>
            </div>
          )}
        </div>
      </div>
      <hr color="#AEAEAE" className="my-10 md:mx-20 md:block hidden" />
      <div className="flex flex-col justify-between md:mx-20 my-10 md:my-0 md:flex-row">
        <div className="relative overflow-hidden md:min-w-[70%]">
          <div className="absolute top-2 z-20 flex items-center gap-x-6 font-normal text-lightgray md:left-[30%] left-[10%] text-xs md:text-base">
            <span
              className={`cursor-pointer rounded-[5px] ${
                activeTab === "all" ? "bg-primary px-[7px] py-[5px] font-medium text-lightblue" : ""
              }`}
              onClick={handleAllClick}
            >
              All
            </span>
            <span
              className={`cursor-pointer rounded-[5px] ${
                activeTab === "profileViews"
                  ? "bg-primary px-[7px] py-[5px] font-medium text-lightblue"
                  : ""
              }`}
              onClick={handleProfileViewsClick}
            >
              Profile views
            </span>
            <span
              className={`cursor-pointer rounded-[5px] ${
                activeTab === "impressions"
                  ? "bg-primary px-[7px] py-[5px] font-medium text-lightblue"
                  : ""
              }`}
              onClick={handleImpressionsClick}
            >
              Impressions
            </span>
            <span
              className={`cursor-pointer rounded-[5px] ${
                activeTab === "projects"
                  ? "bg-primary px-[7px] py-[5px] font-medium text-lightblue"
                  : ""
              }`}
              onClick={handleProjectsClick}
            >
              Projects
            </span>
          </div>
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={chartData}
            options={options}
          />
        </div>
        <div className="flex md:flex-col justify-between md:mt-0 mt-10 mx-4 md:mx-0">
          <div className="flex w-[115px] flex-col rounded-[5px] border border-[#AEAEAE] px-3 md:px-8 py-2 md:w-full">
            <span className="font-medium text-secondary text-xl md:text-[25px]">24</span>
            <span
              className="text-sm md:text-base font-normal text-lightgray"
              onClick={handleProfileViewsClick}
            >
              Profile views
            </span>
          </div>
          <div className="flex w-[115px] flex-col rounded-[5px] border border-[#AEAEAE] px-3 py-2 md:w-full">
            <span className="font-medium text-secondary text-xl md:text-[25px]">34</span>
            <span className="text-sm md:text-base font-normal text-lightgray" onClick={handleImpressionsClick}>
              Impressions
            </span>
          </div>
          <div className="flex w-[115px] flex-col rounded-[5px] border border-[#AEAEAE] px-3 py-2 md:w-full">
            <span className="font-medium text-secondary text-xl md:text-[25px]">15</span>
            <span className="text-sm md:text-base font-normal text-lightgray" onClick={handleProjectsClick}>
              Projects
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineChart;
