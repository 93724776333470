import React, { useState } from "react";
import { addfile } from "../../../../../../constants/images";
import { Button } from "../../../../../../components/ui";

const ResponseModal = ({ handleClose, cancel, onSuccess }) => {
  const [details, setDetails] = useState({
    note: "",
    file: "",
  });
  const [errorState, setErrorState] = useState({
    noteError: "",
    fileError: "",
  });

  const validate = () => {
    let isError = false;
    const errors = {
      noteError: "",
      fileError: "",
    };

    if (!details.note) {
      isError = true;
      errors.noteError = "Please add a note";
    }
    if (!details.file) {
      isError = true;
      errors.fileError = "Please upload a document";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSuccess(true)
    // const error = validate();

    if (!error) {
      // dispatch(
      //   setApplicationForm({
      //     application_details: details,
      //     service_fee: servicefee,
      //     actual_amount: amount,
      //   }),
      // );
      // sendSuccessful(!successful);
    }
  };

  return (
    <div className="fixed bottom-[0%] left-0 right-0 z-[999] block w-full md:animate-none animate-slide_up rounded-[5px] rounded-tl-[30px] rounded-tr-[30px] bg-primary px-4 py-8 md:relative md:top-[35%] md:px-10 md:py-12">
      <div className="absolute right-10 top-2 text-2xl hover:cursor-pointer" onClick={handleClose}>
        x
      </div>
      <div className="flex flex-col justify-center gap-y-4 lg:px-10 lg:py-8">
        <div className="flex flex-col">
          <label htmlFor="note" className="py-2 text-base font-normal text-[#181818]">
            Explain your side of the story
          </label>
          <textarea
            name="note"
            placeholder="Include a brief note about the project"
            id="note"
            cols="20"
            onChange={handleForm}
            rows="5"
            className="border border-[#181818] px-4 py-2 outline-none"
          ></textarea>
          <span className="text-xs text-[#e62e2e]">{errorState.noteError}</span>
        </div>
        <div className="flex flex-col">
          <span>Upload supporting documents</span>
          <div className="flex flex-col items-center justify-center gap-y-2 border border-dashed border-[#181818] py-6 lg:py-8">
            <label
              htmlFor="previous_work"
              className="mx-auto flex w-fit cursor-pointer items-center justify-center gap-2 bg-[#E6F5F9] px-3 py-4"
            >
              <img src={addfile} alt="add file" />
              <span className="text-sm text-lightblue">Add Files</span>
            </label>
            <span className="text-center text-sm text-lightgray">
              {details.file?.replace(/^.*[\\\/]/, "")}
            </span>
            <input
              type="file"
              name="file"
              id="previous_work"
              onChange={handleForm}
              className="hidden"
            />
            <span className="text-xs text-[#e62e2e]">{errorState.fileError}</span>
          </div>
        </div>
        <div className="flex items-center gap-x-2 w-full">
        <Button onClick={cancel} backgroundColor='transparent' textColor='#0096C1' className='w-full'>Cancel</Button>
        <Button onClick={handleSubmit} className='w-full'>Submit</Button>
        </div>
      </div>
    </div>
  );
};

export default ResponseModal;
