import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { blueChat, starRate, linkarrow, x, mark } from "../../../../../constants/images";
import { projectsData } from "../../data";
import SummaryReport from "../desktopProject/summaryReport";
import PaymentDetails from "../desktopProject/paymentDetails";
import CheckWork from "../desktopProject/checkWork";
import { Button } from "../../../../../components/ui";
import ProjectFormsModals from "../desktopProject/formComponents";
const MobileProject = () => {
  const { id } = useParams();
  const res = projectsData[id];
  const [showService, setShowService] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [showCheckWork, setShowCheckWork] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleShowService = () => {
    setShowService(!showService);
  };
  const handleshowPaymentDetails = () => {
    setShowPaymentDetails(!showPaymentDetails);
  };
  const handleshowCheckWork = () => {
    setShowCheckWork(!showCheckWork);
  };
  return (
    <div className="mx-7 py-24 md:hidden ">
      <h3 className="text-center text-[18px] font-semibold text-[#002D3A]">Project details</h3>
      <div className="mt-5 flex items-center justify-between">
        <div className="flex space-x-2">
          <div className="">
            <img src={res.image} alt="" />
          </div>
          <div className="">
            <span className="text-[16px] font-semibold text-[#002D3A]">{res.name}</span>
            <div className="flex items-center space-x-1">
              <div>
                {" "}
                <img src={starRate} alt="" />
              </div>
              <span className="text-[12px] font-semibold">{res.ratings}</span>
              <span className="text-[12px] text-[#5F5F5F] ">(31)</span>
            </div>
          </div>
        </div>
        <Link to="/chat">
          <img src={blueChat} alt="" />
        </Link>
      </div>
      <div className="mt-7 w-full bg-[#E6F5F9] px-4 py-4">
        <div className="">
          <span className="flex items-center justify-center text-center text-[16px] leading-[24px]">
            The delivery timeline for this project has elapsed. Has the job been delivered?
          </span>
          <div className="mt-3 flex items-center justify-between px-10">
            <div className="flex cursor-pointer items-center">
              <div className="mr-4">
                <img src={x} alt="x" />
              </div>{" "}
              <span className="text-[18px] text-[#FF0000]">No</span>
            </div>
            <div className="flex cursor-pointer items-center" onClick={() => setShowModal(true)}>
              <div className="mr-4">
                <img src={mark} alt="mark" />
              </div>{" "}
              <span className="text-[18px] text-[#0096C1]"  >Yes</span>
            </div>
          </div>
        </div>
      </div>
      <p className="mt-5 text-[12px] text-[#5F5F5F]">Project number: #783451</p>
      <h4 className="text-[16px] font-medium text-[#002D3A]">{res.title}</h4>

      <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3 ">
        <div
          className=" flex  cursor-pointer items-center justify-between  "
          onClick={handleShowService}
        >
          <span className="text-center text-[14px] font-medium text-[#5F5F5F]">
            Service report summary
          </span>
          <Link to='/summary-report'>
          <img
            src={linkarrow}
            alt=""
            className={`${showService && "ease rotate-90 transition-all"}`}
          />
          </Link>
        </div>
        {showService && <SummaryReport />}
      </div>
      <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3  ">
        <div
          className=" flex  cursor-pointer items-center justify-between  "
          onClick={handleshowPaymentDetails}
        >
          <span className="text-center text-[14px] font-medium text-[#5F5F5F]">
            Payment details
          </span>
          <img
            src={linkarrow}
            alt=""
            className={`${showPaymentDetails && "ease rotate-90 transition-all"}`}
          />
        </div>
        {showPaymentDetails && <PaymentDetails />}
      </div>
      <div className="mt-6 w-full rounded-[5px] border-[1px] border-[#AEAEAE] p-3  ">
        <div
          className=" flex  cursor-pointer items-center justify-between  "
          onClick={handleshowCheckWork}
        >
          <span className="text-center text-[14px] font-medium text-[#5F5F5F]">Check work</span>
          <img
            src={linkarrow}
            alt=""
            className={`${showCheckWork && "ease rotate-90 transition-all"}`}
          />
        </div>
        {showCheckWork && <CheckWork />}

       
      </div>
      <Button children='Rebook' className='w-full my-24' backgroundColor='#ffff' textColor='#0096C1'/>
      {showModal && <ProjectFormsModals setShowModal={setShowModal}/>}
    </div>
  );
};

export default MobileProject;
