import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

const ConsumeSide = ({ reviewData }) => {
  const datatype = reviewData?.dispute_file_url?.includes("[");
  const files =
    datatype && reviewData?.dispute_file_url
      ? JSON.parse(reviewData.dispute_file_url)
      : [reviewData?.dispute_file_url];

  const docViewerFiles = files.map((file) => ({ uri: file }));
  const handleDownload = (url) => {
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      alert("Popup blocked! Please allow popups for this website.");
    }
  };

  return (
    <div className="px-5 py-7">
      <div className="flex gap-20">
        <div className="text-[18px] text-[#5F5F5F]">
          <h3>Consumer name</h3>
          <span className="text-[20px] font-medium text-[#002D3A]">
            {reviewData?.consumer_name || <Skeleton height={30} />}
          </span>
        </div>
        <div className="text-[18px] text-[#5F5F5F]">
          <h3>Email address</h3>
          <span className="text-[20px] font-medium text-[#002D3A]">
            {reviewData?.consumer_email || <Skeleton height={30} />}
          </span>
        </div>
      </div>

      <h3 className="mt-5 text-[25px] leading-[29.33px] text-[#5F5F5F]">Submitted Evidences</h3>
      <h4 className="mt-3 text-[18px] leading-[21.11px] text-[#5F5F5F]">Reason for dispute</h4>
      <p className="text-[20px] leading-[30px] text-[#181818]">
        {reviewData?.reason || <Skeleton count={3} height={30} />}
      </p>

      <h4 className="mt-3 text-[18px] text-[#5F5F5F]">Documents</h4>
      <div className="mt-2 flex flex-wrap gap-7">
        {docViewerFiles.length > 0 ? (
          docViewerFiles.map((file, index) => (
            <div
              key={index}
              className="flex h-fit w-[185px] cursor-pointer flex-col items-center justify-center rounded-[5px] bg-[#EAEAEA] p-2"
            >
              <div className="h-[110.06px] w-[165px] overflow-hidden object-contain">
                <DocViewer
                  documents={[file]}
                  pluginRenderers={DocViewerRenderers}
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
              <span className="mt-1 text-[#181818]">Document {index + 1}</span>

              {file.uri ? (
                <button
                  onClick={() => handleDownload(file.uri)}
                  className="mt-2 text-[#0096C1] underline"
                >
                  Download
                </button>
              ) : null}
            </div>
          ))
        ) : (
          <Skeleton height={110} width={165} count={2} />
        )}
      </div>

      <div className="mt-16">
        <button className="w-[35%] rounded-[5px] border-[1px] border-[#0096C1] px-4 py-2 text-[18px] text-[#0096C1]">
          Request more details
        </button>
      </div>
    </div>
  );
};

export default ConsumeSide;
