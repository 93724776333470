import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../../../components";
import { Spinner } from "../../../../../../constants/images";
import { setDiscountDetails, setEditDiscount } from "../../../../../../redux/slice/modalOverlay";

const EditDiscountModal = ({ fetchAllDiscount }) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const [success, setSuccess] = useState(false); // State for success modal
  const discount = useSelector((state) => state.modalOverlay.states.discountDetails);

  const accessToken = useSelector((state) => state?.token.states.accessToken)
  const date = discount?.valid_until;
  const formattedDate = new Date(date).toISOString().split("T")[0];
  const [details, setDetails] = useState({
    name: discount?.name || "",
    price: discount?.price || "",
    usage_frequency: discount?.usage_frequency || "",
    discount_code: discount?.discount_code || "",
    valid_until: formattedDate || "",
  });
  const [selectedImage, setSelectedImage] = useState(discount.discount_image_url);
  const [errorState, setErrorState] = useState({
    nameError: "",
    priceError: "",
    usage_frequencyError: "",
    discount_codeError: "",
    valid_untilError: "",
  });

  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let isError = false;
    const errors = {
      nameError: "",
      priceError: "",
      usage_frequencyError: "",
      discount_codeError: "",
      valid_untilError: "",
    };

    if (!details.name) {
      isError = true;
      errors.nameError = "Please enter a discount name";
    }
    if (!details.discount_code) {
      isError = true;
      errors.discount_codeError = "Please enter a discount code";
    }
    if (!details.price) {
      isError = true;
      errors.priceError = "Please enter discount price";
    }
    if (!details.usage_frequency) {
      isError = true;
      errors.usage_frequencyError = "Please enter usage frequency";
    }
    if (!details.valid_until) {
      isError = true;
      errors.valid_untilError = "Please enter validity";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["image/jpeg", "image/png", "image/svg+xml"];

    if (file && allowedFormats.includes(file.type)) {
      setSelectedFile(file);
      setFileName(file.name);
      setFileError("");
    } else {
      setSelectedFile(null);
      setFileName("");
      setFileError("Invalid file format. Please select a JPG, JPEG, PNG, or SVG file.");
    }
  };

  const handleClose = () => {
    dispatch(setEditDiscount(false));
    dispatch(setDiscountDetails({}));
  };

  const handleEditDiscount = async () => {
    const error = validate();

    if (!error) {
      setLoading(true);
      try {
        // First, create the discount
        const response = await fetch(
          `${import.meta.env.VITE_BASE_URL}/admin/discount/${discount?.id}`,
          {
            method: "PUT",
            body: JSON.stringify(details),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },

          },
        );

        if (!response.ok) {
          let errorData;
          try {
            errorData = await response.clone().json();
            throw new Error(errorData.message || "Unknown error occurred");
          } catch (jsonError) {
            const text = await response.clone().text();
            console.error("Response text:", text);
            throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
          }
        }

        const data = await response.json();

        // Then, update the discount with the image
        await updateDiscount(data.discountId, selectedFile);

        // Show success modal
        setSuccess(true);
        setTimeout(() => {
          fetchAllDiscount();
          setSuccess(false);
          setLoading(false);
          dispatch(setDiscountDetails({}));
          dispatch(setEditDiscount(false)); // Close modal after success
          // Refresh or reload the discount list here if needed
        }, 3000); // Display success modal for 3 seconds
      } catch (error) {
        console.log("Error adding discount", error);
        setLoading(false);
      }
    }
  };

  const updateDiscount = async (discountId, image) => {
    const formData = new FormData();
    formData.append("discount", image);

    try {
      const response = await fetch(
        `${import.meta.env.VITE_BASE_URL}/admin/discount/${discountId}/image`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (!response.ok) {
        let errorData;
        try {
          errorData = await response.clone().json();
          throw new Error(errorData.message || "Unknown error occurred");
        } catch (jsonError) {
          const text = await response.clone().text();
          console.error("Response text:", text);
          throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
        }
      }

      const data = await response.json();
      console.log("Discount updated with image", data);
    } catch (error) {
      console.log("Error updating discount with image", error);
      setLoading(false);
    }
  };

  return (
    <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
      <div className="absolute right-10 top-2 text-2xl hover:cursor-pointer" onClick={handleClose}>
        X
      </div>
      <div className="flex flex-col gap-y-3 px-8">
        <span className={`py-2 text-start text-xl font-semibold text-secondary`}>Add Discount</span>
        <div className="flex flex-col">
          <label htmlFor="name" className="text-base font-normal text-[#181818]">
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={details.name}
            placeholder="Name the discount"
            onChange={handleForm}
            className="w-full rounded-[5px] border  border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
          />
          {details.name === "" && (
            <span className="text-start text-sm text-red-500">{errorState.nameError}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="name" className="text-base font-normal text-[#181818]">
            Price
          </label>
          <input
            type="text"
            name="price"
            id="price"
            value={details.price}
            placeholder="Input the discount price"
            onChange={handleForm}
            className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
          />
          {details.price === "" && (
            <span className="text-start text-sm text-red-500">{errorState.priceError}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="usage" className="text-base font-normal text-[#181818]">
            Usage frequency
          </label>
          <div className="flex items-center gap-x-2">
            <input
              type="text"
              name="usage_frequency"
              id="usage_frequency"
              value={details.usage_frequency}
              placeholder="Input the usage frequency"
              onChange={handleForm}
              className="w-1/2 rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
            />
            <span className="text-base font-normal text-lightgray">time(s)</span>
          </div>
          {details.usage_frequency === "" && (
            <span className="text-start text-sm text-red-500">
              {errorState.usage_frequencyError}
            </span>
          )}
        </div>{" "}
        <div className="flex flex-col">
          <label htmlFor="discount_code" className="text-base font-normal text-[#181818]">
            Discount code
          </label>
          <input
            type="text"
            name="discount_code"
            id="discount_code"
            value={details.discount_code}
            placeholder="Set code"
            onChange={handleForm}
            className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
          />
          {details.discount_code === "" && (
            <span className="text-start text-sm text-red-500">{errorState.discount_codeError}</span>
          )}
        </div>{" "}
        <div className="flex flex-col">
          <label htmlFor="valid_until" className="text-base font-normal text-[#181818]">
            Valid until
          </label>
          <input
            type="date"
            name="valid_until"
            id="valid_until"
            value={details.valid_until}
            placeholder="Set validity date"
            onChange={handleForm}
            className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 text-lightgray outline-none"
          />
          {details.valid_until === "" && (
            <span className="text-start text-sm text-red-500">{errorState.valid_untilError}</span>
          )}
        </div>{" "}
        <div className="flex flex-col gap-y-2">
          <span className="text-base font-normal text-[#181818]">Image</span>
          <img src={selectedImage} alt="image" className="w-[30%]" />
          <label htmlFor="changeImage" className="cursor-pointer text-sm text-lightblue">
            Change image
          </label>

          <input
            type="file"
            name="changeImage"
            id="changeImage"
            className="hidden"
            accept=".jpg,.jpeg,.png,.svg"
            onChange={handleFileChange}
          />
          {fileError && <span className="text-center text-sm text-red-500">{fileError}</span>}
        </div>
        <div className="mt-10 w-full">
          <Button
            className="flex w-full items-center justify-center"
            onClick={() => handleEditDiscount()}
            disabled={loading}
          >
            {loading ? <img src={Spinner} alt="loading" className="w-[5%]" /> : "Update Discount"}
          </Button>
        </div>
      </div>
      {success && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded-lg bg-white p-5 shadow-lg">
            <span className="text-xl font-semibold">Success!</span>
            <p className="mt-2">Discount updated successfully.</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default EditDiscountModal;
