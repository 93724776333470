import React from "react";
import Container from "../../../container";
import { giftIcon, referNEarn, promoteIcon, copyIcon,linkarrow } from "../../../../../constants/images";
const OffersDetails = () => {
  const referralCode = "GYWDE-T0MWR";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralCode);
    alert("Referral code copied to clipboard!");
  };
  return (
    <Container>
      <div className="px-5 py-10 md:px-24 md:py-20">
        <h2 className="mb-4 text-xl font-semibold">Offers</h2>

        <div className="mb-4 rounded-lg border-gray-500 md:w-[70%] md:border-[1px]">
          <div className="flex items-center  space-x-4 border-b-[1px] border-gray-500 px-5 py-2">
            <img src={giftIcon} alt="giftIcon" className="flex h-4" />
            <h3 className="mb-2 text-base text-[#002D3A] ">Refer and Earn</h3>
          </div>
          <div className="flex items-center space-x-4 px-5 py-7">
            <div className="">
              <img src={referNEarn} alt="referNEarn" />
              <div className="flex items-center ">
                <input
                  type="text"
                  value={referralCode}
                  readOnly
                  className="w-full  border-4 border-[#0096C1] px-2 py-[1px] outline-none"
                />
                <div
                  onClick={copyToClipboard}
                  className=" flex  cursor-pointer  items-center space-x-1 bg-[#0096C1] py-[8px] pr-4 text-white"
                >
                  <img src={copyIcon} alt="" className="scale-75" />
                  <span className="pr-1 text-[12px]">Copy</span>
                </div>
              </div>
            </div>
            <div>
              <p className="mb-2 text-[18px] leading-[21px] text-gray-600">
                Invite a friend to join GYWDE and get <span className="text-[#0096C1]">₦1000</span>{" "}
                on their first order.
                <br /> <br /> Your friend also gets
                <span className="text-[#0096C1]">₦500</span> off on their first order.
              </p>

              <p className="float-end mt-7 flex cursor-pointer text-[12px] text-[#0096C1]">
                View referral history
              </p>
            </div>
          </div>
        </div>
        <div className="flex border-[1px] border-gray-500 px-5 py-2 md:w-[70%] items-center justify-between">
       
            <div className="flex items-center space-x-2 ">
              <img src={promoteIcon} alt="promoteIcon" />
              <span className="text-[18px]">Promotions</span>
            </div>
            
       
          <img src={linkarrow} alt="linkarrow" />
        </div>
      </div>
    </Container>
  );
};

export default OffersDetails;
