import React from 'react'
import { linkarrow } from '../../../../../constants/images';

const ReuseableDropdown = ({ label, isOpen, handleToggle, options }) => {
    return (
      <div className="relative inline-block "> 
        <div
          className="flex w-full cursor-pointer items-center rounded-[5px] border-[1px] border-[#AEAEAE] px-2 py-1 font-semibold text-[#002D3A]"
          onClick={handleToggle}
        >
          {label}
          <img
            src={linkarrow}
            alt=""
            className={`${isOpen ? "rotate-90 transition-transform" : ""} pl-5`} 
          />
        </div>
        {isOpen && (
          <div className="absolute left-0 z-10 mt-1 w-full bg-[#D9D9D9] p-2 text-[#002D3A] shadow-lg">
            {options.map((option, index) => (
              <p key={index} className="cursor-pointer hover:bg-gray-300 p-1">
                {option}
              </p>
            ))}
          </div>
        )}
      </div>
    );
  };

export default ReuseableDropdown