import React, { useEffect, useState } from "react";
import ProjectsNavbar from "../projects-navbar";
import { Dropdown } from "../../../../../../../constants/images";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "../../../../../../../components";
import { setProjectId, setShowProject } from "../../../../../../../redux/slice/modalOverlay";
import { formatDate } from "../../../../../../../utils/dateUtils";

// Modal component for displaying the transaction ID
const TransactionIdModal = ({ isOpen, onClose, location }) => {
  // Close modal if clicked outside
  const handleClickOutside = (event) => {
    if (event.target.className.includes("modal-overlay")) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleClickOutside);
    } else {
      window.removeEventListener("click", handleClickOutside);
    }
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="rounded bg-white p-4">
        <h2 className="mb-4 text-xl font-bold">Location</h2>
        <p>{location}</p>
        <button className="mt-4 rounded bg-blue-500 px-4 py-2 text-white" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};
const entries = [
  { id: 0, entry: 10 },
  { id: 0, entry: 20 },
  { id: 0, entry: 30 },
];
const ProjectTable = ({ onGoingJobs = [], cancelledJobs = [], completedJobs = [] }) => {
  const projectData = [...onGoingJobs, ...cancelledJobs, ...completedJobs] || [];

  const dispatch = useDispatch();
  const [selectedEntry, setSelectedEntry] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const project = useSelector((state) => state.modalOverlay.states.selectedProject);

  const projectSearch = useSelector((state) => state.modalOverlay.states.projectSearch);

  const projectDataFiltered = projectData?.filter((value) => {
    const match = value?.status?.toLowerCase() === project?.toLowerCase();
    return (
      match ||
      (match && (value.address?.toLowerCase() || "-").includes(projectSearch?.trim().toLowerCase())) ||
      (match && (value.title?.toLowerCase() || "").includes(projectSearch?.trim().toLowerCase())) ||
      (match && (`${value.service_request_id}`.toLowerCase() || "").includes(projectSearch?.trim().toLowerCase())) ||
      (match && (value.consumer_name.toLowerCase() || "").includes(projectSearch?.trim().toLowerCase())) ||
      (match && (value.vendor_name.toLowerCase() || "").includes(projectSearch?.trim().toLowerCase())) ||
      (match && (value.status.toLowerCase() || "").includes(projectSearch?.trim().toLowerCase()))
    );
  });
  
  const [openSelect, setOpenSelect] = useState(false);

  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  //Pagination logic
  const itemsPerPage = selectedEntry;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = projectDataFiltered.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(projectDataFiltered.length / itemsPerPage);
  const handleProjectId = (e) => {
    dispatch(setProjectId(e));
    dispatch(setShowProject(true));
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleLocationClick = (id) => {
    setSelectedLocation(id);
    setIsModalOpen(true);
  };
  return (
    <div className="mx-auto flex w-full flex-col rounded-[10px] bg-primary py-4">
      <ProjectsNavbar />
      <div className="grid w-full grid-cols-9 space-x-4 bg-[#E8E8E8] px-6 py-4 text-sm font-medium text-[#181818]">
        <span>Project ID</span>
        <span>Project Title</span>
        <span>Location</span>
        <span>Consumer</span>
        <span>Vendor</span>
        <span>Budget</span>
        <span>Start Date</span>
        <span>End Date</span>
        <span>Status</span>
      </div>
      {currentData.map((value) => {
        return (
          <div
            key={value.id}
            className="transition-colors ease-in-out hover:cursor-pointer hover:bg-[#eceaea]"
            onClick={() => handleProjectId(value?.service_request_id)}
          >
            <div className="grid w-full grid-cols-9 space-x-4 px-6 py-4">
              <span className="whitespace-pre-wrap break-words">{value.service_request_id || "-"}</span>
              <span className="whitespace-pre-wrap break-words">{value.title || "-"}</span>
              <span
                className="w-[100px] cursor-pointer"
                onClick={() => handleLocationClick(value?.address)}
                title="Click to view location"
              >
                <span className="truncate">{(value?.address || "").slice(0, 8) + "..." || "-"}</span>
              </span>
              <span className="whitespace-pre-wrap break-words">{value?.consumer_name || "-"}</span>
              <span className="whitespace-pre-wrap break-words">{value?.vendor_name || "-"}</span>
              <span className="whitespace-pre-wrap break-words">
                {value?.budget?.toLocaleString() || "-"}
              </span>
              <span className="whitespace-pre-wrap break-words">
                {formatDate(value?.start_date)}
              </span>
              <span className="whitespace-pre-wrap break-words">{formatDate(value?.end_date) || "-"}</span>
              <span
                className={`${
                  value.status === "ongoing"
                    ? "text-[#0096C1]"
                    : value.status === "completed"
                      ? "text-[#30e749]"
                      : value.status === "cancelled"
                        ? "text-[#FF392B]"
                        : ""
                }`}
              >
                {value.status}
              </span>
            </div>
            <hr color="#AEAEAE" className="w-full" />
          </div>
        );
      })}
      {projectDataFiltered.length === 0 && (
        <div className="flex w-full items-center justify-center pt-4 text-xl text-secondary">
          No search results available for query
        </div>
      )}
      <div className="flex w-full justify-between px-6 pt-3">
        <div className="flex w-full">
          <div className="relative flex items-center gap-x-1">
            <span>Showing</span>
            <div
              onClick={handleSelect}
              className="flex w-fit items-center gap-x-4 px-5 text-base font-normal text-[#181818] hover:cursor-pointer"
            >
              <span>{selectedEntry}</span>
              <img
                src={Dropdown}
                alt="open"
                className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
              />
            </div>
            {openSelect && (
              <div className="absolute bottom-[100%] left-[40%] w-[30%] animate-slide_up  rounded-[10px] bg-primary px-3 py-2">
                <div className="flex flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
                  {entries.map((entry) => (
                    <span
                      key={entry.id}
                      className="hover:cursor-pointer"
                      onClick={() => setSelectedEntry(entry.entry)}
                    >
                      {entry.entry}
                    </span>
                  ))}
                </div>
              </div>
            )}
            <span>entries</span>
          </div>
        </div>
        <div>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
        </div>
      </div>
      <TransactionIdModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        location={selectedLocation}
      />
    </div>
  );
};

export default ProjectTable;
