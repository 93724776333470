import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
const JobFeedback = () => {
  const [rating, setRating] = useState(0);
  const ratingChanged = (newRating) => {
    console.log(newRating);
    setRating(newRating);
  };
  return (
    <div className="mt-7">
      <h3 className="text-[18px] font-medium">Your feedback to vendor</h3>

      <div className="flex items-center space-x-20">
        <span className="text-[18px] font-medium text-[#5F5F5F]">Overall rating</span>
        <div className=" flex items-center space-x-3">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={40}
            activeColor="#ffd700"
            edit={false}
            value={3}
          />{" "}
          <span className="text-[18px] font-medium text-[#5F5F5F]">3.0</span>
        </div>
      </div>
      <p className="text-[16px] leading-[24px] w-[80%] mt-3">I hired John for a plumbing issue at my home, and I couldn't be more satisfied with his service. He responded promptly to my request, arrived on time, and quickly identified and fixed the problem.</p>

      <hr className="my-6 w-[85%]" />

      <h3 className="text-[18px] font-medium">Vendor’s feedback</h3>

<div className="flex items-center space-x-20">
  <span className="text-[18px] font-medium text-[#5F5F5F]">Overall rating</span>
  <div className=" flex items-center space-x-3">
    <ReactStars
      count={5}
      onChange={ratingChanged}
      size={40}
      activeColor="#ffd700"
      edit={false}
      value={4}
    />{" "}
    <span className="text-[18px] font-medium text-[#5F5F5F]">4.0</span>
  </div>
</div>
<p className="text-[16px] leading-[24px] w-[80%] mt-3">Great experience working for this client</p>
    </div>
  );
};

export default JobFeedback;
