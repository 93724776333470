import React, { useState } from "react";
import { linkarrow } from "../../../../../constants/images";

const Reviews = ({ vendor, name, reviewsDate, reviews, rating }) => {
  const [showBy, setShowBy] = useState(false);
  const handleShowby = () => {
    setShowBy(!showBy);
  };


  return (
    <div className="mt-8">
      <div
        className="flex w-[20%] cursor-pointer items-center justify-between rounded-[17px] border-2 border-[#AEAEAE] px-2 py-1 font-semibold text-[#002D3A]"
        onClick={handleShowby}
      >
        Sort by{" "}
        <img
          src={linkarrow}
          alt=""
          className={`${showBy && "ease rotate-90 transition-all"}`}
        />
      </div>
      {showBy && (
        <div className="w-[20%] bg-[#D9D9D9] p-2 text-[#002D3A] ">
          <p className="cursor-pointer">Newest</p>
          <p className="cursor-pointer">Highest</p>
          <p className="cursor-pointer">Lowest</p>
        </div>
      )}

      <div className="mt-5">
        <div className="flex">
          <img src={vendor} alt="vendor" />
          <div className="flex items-start gap-80">
            <div className="flex flex-col">
              <p className="text-[16px] font-semibold text-[#002D3A]">{name}</p>
              <p className="text-[14px] text-[#5F5F5F]">{reviewsDate}</p>
            </div>
            <div className="flex items-center gap-2">
              <img src="/rate.png" alt="" className="h-4 w-4" />
              <span className="font-semibold">{rating}</span>
            </div>
          </div>
        </div>
        <p className="text-[16px] leading-[24px] text-[#181818]">{reviews}</p>
      </div>
      <div className="mt-5">
        <div className="flex">
          <img src={vendor} alt="vendor" />
          <div className="flex items-start gap-80">
            <div className="flex flex-col">
              <p className="text-[16px] font-semibold text-[#002D3A]">{name}</p>
              <p className="text-[14px] text-[#5F5F5F]">{reviewsDate}</p>
            </div>
            <div className="flex items-center gap-2">
              <img src="/rate.png" alt="" className="h-4 w-4" />
              <span className="font-semibold">{rating}</span>
            </div>
          </div>
        </div>
        <p className="text-[16px] leading-[24px] text-[#181818]">{reviews}</p>
      </div>
      <div className="mt-5">
        <div className="flex">
          <img src={vendor} alt="vendor" />
          <div className="flex items-start gap-80">
            <div className="flex flex-col">
              <p className="text-[16px] font-semibold text-[#002D3A]">{name}</p>
              <p className="text-[14px] text-[#5F5F5F]">{reviewsDate}</p>
            </div>
            <div className="flex items-center gap-2">
              <img src="/rate.png" alt="" className="h-4 w-4" />
              <span className="font-semibold">{rating}</span>
            </div>
          </div>
        </div>
        <p className="text-[16px] leading-[24px] text-[#181818]">{reviews}</p>
      </div>
    </div>
  );
};

export default Reviews;
