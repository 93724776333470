import React from "react";
import Container from "../container";
import NoAlert from "./no-alert";
import NotificationCard from "./card";
import { notificationdata } from "./data"; 

const Alert = () => {
  return (
    <Container>
      <div className="my-32 px-12">
        <h3 className="text-[20px] font-bold text-[#002D3A] md:text-[30px]">Alerts</h3>

        {notificationdata.length === 0 && <NoAlert />}

        {notificationdata.map((notification) => (
          <NotificationCard
            key={notification.id}
            days={notification.days}
            notifications={notification.notifications}
          />
        ))}
      </div>
    </Container>
  );
};

export default Alert;
