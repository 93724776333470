import React, { useEffect, useState } from "react";
import { Advert, Agent, Comission, Discount, Vat } from "../../../../constants/images";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowAdvertModal,
  setShowAgentModal,
  setShowDiscountModal,
  setShowPermisionsModal
} from "../../../../redux/slice/modalOverlay";
import { permissionsIcon } from "../../../../../constants/images";

const BusinessCards = ({ showVatModal, showComissionModal }) => {
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const userType = useSelector((state) => state.token.states.userType);

  const [commissionValue, setCommissionValue] = useState(null);
  const [vatValue, setVatValue] = useState(null);
  const [vatError, setVatError] = useState("");
  const [commissionError, setCommissionError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => {
      getCommission();
      getVat();
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const getCommission = async () => {
    if (userType === "ID Confirmation") {
      return false;
    }
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/current-commission`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      if (response.ok && data.commission_rate !== commissionValue) {
        setCommissionValue(data.commission_rate);
      }
    } catch (error) {
      console.log(error);
      setCommissionError("Error in fetching Commission");
    }
  };

  const getVat = async () => {
    if (userType === "ID Confirmation") {
      return false;
    }
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/current-vat`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      if (response.ok && data.vat_amount !== vatValue) {
        setVatValue(data.vat_amount);
      }
    } catch (error) {
      console.log(error);
      setVatError("Error in fetching VAT");
    }
  };
  useEffect(() => {
    getVat();
    getCommission();
  }, [commissionValue, vatValue]);

  const handleVatModal = () => {
    showVatModal(true);
  };
  const handleComissionModal = () => {
    showComissionModal(true);
  };
  const handleAdvertsModal = () => {
    dispatch(setShowAdvertModal(true));
  };
  const handleAgentsModal = () => {
    dispatch(setShowAgentModal(true));
  };
  const handleDiscountModal = () => {
    dispatch(setShowDiscountModal(true));
  };
  const handlePermissions = () => {
    dispatch(setShowPermisionsModal(true));
  }
  return (
    <div className="float-end ml-auto  w-full grid-cols-5 grid justify-between gap-x-4 gap-y-5 pb-14">

      <div className={`${userType === 'ID Confirmation' && 'hidden'} group flex w-[100%] flex-col gap-y-2 rounded-[10px] px-4 py-6 shadow-lg`}>

        <div className="flex w-full items-end justify-between ">
          <img src={Vat} alt="vat" />
          <span
            className="text-sm font-normal text-lightblue hover:cursor-pointer"
            onClick={handleVatModal}
          >
            Edit
          </span>
        </div>
        <div className="flex w-full items-end justify-between">
          <span className="text-lg font-normal text-lightgray ">VAT</span>
          <span className="text-[25px] font-medium text-[#181818] ">{vatValue}%</span>
        </div>
      </div>

      <div className={`${userType === 'ID Confirmation' && 'hidden'} group flex w-[100%] flex-col gap-y-2 rounded-[10px] px-2 py-6 shadow-lg`}>

        <div className="w-fullitems-end flex justify-between">
          <img src={Comission} alt="comission" />
          <span
            className="text-sm font-normal text-lightblue hover:cursor-pointer"
            onClick={handleComissionModal}
          >
            Edit
          </span>
        </div>
        <div className="flex w-full items-end justify-between">
          <span className="text-lg font-normal text-lightgray ">Comission</span>
          <span className="text-[25px] font-medium text-[#181818] ">{commissionValue}%</span>
        </div>
      </div>
      <div

        className={`${userType === 'ID Confirmation' && 'hidden'} group flex w-[100%] flex-col items-center gap-y-2 rounded-[10px] px-2 py-6 shadow-lg transition-all ease-in hover:scale-95 hover:cursor-pointer hover:bg-[#0096C1] hover:bg-opacity-80`}

        onClick={handleAdvertsModal}
      >
        <img src={Advert} alt="advert" />
        <span className="text-lg font-medium text-[#181818] group-hover:text-primary">Adverts</span>
      </div>
      <div
        className={`group flex w-[100%] flex-col items-center gap-y-2 rounded-[10px] px-2 py-6 shadow-lg transition-all ease-in hover:scale-95 hover:cursor-pointer hover:bg-[#0096C1] hover:bg-opacity-80`}
        onClick={handleAgentsModal}
      >
        <img src={Agent} alt="agent" />
        <span className="text-lg font-medium text-[#181818] group-hover:text-primary">Agents</span>
      </div>
      <div

        className={`${userType === 'ID Confirmation' && 'hidden'} group flex w-[100%] flex-col items-center gap-y-2 rounded-[10px] px-2 py-6 shadow-lg transition-all ease-in hover:scale-95 hover:cursor-pointer hover:bg-[#0096C1] hover:bg-opacity-80`}

        onClick={handleDiscountModal}
      >
        <img src={Discount} alt="discount" />
        <span className="text-lg font-medium text-[#181818] group-hover:text-primary">
          Discount
        </span>
      </div>
      <div

        className={`${(userType === 'Sales' || userType === "ID Confirmation") && 'hidden'} group flex w-[100%] flex-col items-center gap-y-2 rounded-[10px] px-2 py-6 shadow-lg transition-all ease-in hover:scale-95 hover:cursor-pointer hover:bg-[#0096C1] hover:bg-opacity-80`}

        onClick={handlePermissions}
      >
        <img src={permissionsIcon} alt="permission" />
        <span className="text-lg font-medium text-[#181818] group-hover:text-primary">
          Permissions
        </span>
      </div>
    </div>
  );
};

export default BusinessCards;
