import React from "react";
import { Success } from "../../../constants/images";
import Button from "../../../../components/ui/button";

const SuccessModal = ({title, buttonText, handleClose, showClose, onClick}) => {

  return (
    <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
    {showClose &&  <div
        className="absolute right-5 top-2 text-2xl hover:cursor-pointer"
        onClick={handleClose}
      >
        x
      </div>}
      <div className="flex flex-col justify-between">
        <div className="flex flex-col items-center pb-24 justify-center gap-y-3">
          <img src={Success} className="w-[150px]" alt="successfull" />
          <span className="px-5 py-2 text-center text-xl font-semibold text-secondary lg:px-10">
           {title}
          </span>
        </div>
        <div className="w-full flex items-center absolute bottom-0 justify-center md:px-8 px-4 lg:px-10 lg:py-8">
          <Button className="w-full" onClick={onClick}>{buttonText}</Button>
        </div>
      </div>
      )
    </div>
  );
};

export default SuccessModal;
