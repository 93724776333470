import { useState, useEffect } from "react";

const useBannedAccounts = (accessToken) => {
  const [allBannedAccounts, setAllBannedAccounts] = useState(null);
  const [acctDetails, setAcctDetails] = useState(null);
  const [dispute, setAcctDispute] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [accountId, setAccountId] = useState(null);

  const fetchBannedAcct = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/banned-vendors`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setAllBannedAccounts(data?.bannedVendors);
    } catch (error) {
      setAllBannedAccounts(null);
      console.error("Error fetching banned accounts:", error);
    }
  };

  const fetchBannedAcctDetails = async (id) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/banned-vendors/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setShowDetails(true);
      setAcctDetails(data?.vendorDetails);
      setAcctDispute(data?.disputes);
    } catch (error) {
      setAcctDetails(null);
      console.error("Error fetching account details:", error);
    }
  };

  useEffect(() => {
    fetchBannedAcct();
  }, []);

  useEffect(() => {
    if (accountId) {
      fetchBannedAcctDetails(accountId);
    }
  }, [accountId]);

  return {
    allBannedAccounts,
    acctDetails,
    dispute,
    showDetails,
    setAccountId,
    setShowDetails,
  };
};

export default useBannedAccounts;
