export const jobsData = [
  {
    id: 1,
    title: "Plumber needed to repair bathroom faucet",
    location: "Yaba, Lagos",
    posted_date: "23 Dec 2023",
    budget: "₦25,000",
    applications: [
      {
        id: 1,
        name: "John Doe",
        profession: "Plumber",
        location: "Yaba, Lagos",
        rating: 4.8,
        reviewCount: 31,
        coverLetter: "I can help with what you need. Horem ipsum dolor sit amet...",
        status: "liked", 
      },
      {
        id: 2,
        name: "Jane Smith",
        profession: "Plumber",
        location: "Ikeja, Lagos",
        rating: 4.2,
        reviewCount: 20,
        coverLetter: "I have years of experience in plumbing...",
        status: "messaged",
      },
      {
        id: 3,
        name: "Mike Johnson",
        profession: "Plumber",
        location: "Lekki, Lagos",
        rating: 4.0,
        reviewCount: 15,
        coverLetter: "Expert in plumbing and repairs...",
        status: "declined",
      }
    ],
    status: "open",
  },

  {
    id: 2,
    title: "Electrical repair and installation",
    loation: "Ikeja, Lagos",
    posted_date: "22 Dec 2023",
    budget: "₦230,000",
    applications: "28",
    status: "closed",
  },
  {
    id: 3,
    title: "Graphic designer for brand business logo",
    loation: "Online",
    posted_date: "30 Dec 2023",
    budget: "₦35,000",
    applications: "26",
    status: "open",
  },
];
