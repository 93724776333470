import React from "react";
import { Thankyou } from "../../../../../../../constants/images";
import { Button } from "../../../../../../../components/ui";
const ThankYouModal = () => {
  return (
    <div className="flex flex-col gap-5 items-center justify-center">
      <div className="">
        {" "}
        <img src={Thankyou} alt="" />
      </div>
      <p className="text-[18px] font-semibold leading-[21.2px] text-center text-[#002D3A]">
        Thank you for choosing GYWDE. We hope to see you again soon
      </p>
      <Button children="Browse services" className="w-full my-4"/>
    </div>
  );
};

export default ThankYouModal;
