import React from "react";
import Navbar from "../../_components/ui/navbar";
import SideBar from "../../_components/ui/sidebar";
import EarningsContent from "./_components/earnings-content";


const VendorDashboardEarnings = () => {
  return (
    <div>
      <Navbar />
      <SideBar />
      <EarningsContent />
    </div>
  );
};

export default VendorDashboardEarnings;
