import React, { useEffect, useState } from "react";
import { Logout } from "../../../../../constants/images";
import { NavLink } from "react-router-dom";
import {
  HomeIcon,
  AlertIcon,
  JobIcon,
  ChatIcon,
  ProjectIcon,
  EarningIcon,
} from "./icons";
import { useDispatch, useSelector } from "react-redux";
import { setDisputeDashboard, setSeeMoreJobs, setShowServiceRequest } from "../../../../../features/overlay";

const SideBar = () => {
  const dispatch = useDispatch();
  const hideSidebar = useSelector(
    (state) => state?.overlay.displayStates.hideSidebar,
  );
  const [displayMobile, setDisplayMobile] = useState(screen.width <= 768);
  const [active, setActive] = useState(0);
  const handleClick = () => {
    dispatch(setShowServiceRequest(false));
    dispatch(setSeeMoreJobs(false));
    dispatch(setDisputeDashboard(false));
  };
  useEffect(() => {
    setDisplayMobile(screen.width <= 768);

    const handleResize = () => {
      setDisplayMobile(screen.width <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sideLinks = [
    {
      id: 0,
      title: "Home",
      route: "home",
      icon: <HomeIcon />,
    },
    {
      id: 1,
      title: "Jobs",
      route: "jobs",
      icon: <JobIcon />,
    },
    {
      id: 2,
      title: "Projects",
      route: "projects",
      icon: <ProjectIcon />,
    },
    {
      id: 3,
      title: "Chat",
      route: "chat",
      icon: <ChatIcon />,
    },
    {
      id: 4,
      title: "Alert",
      route: "alerts",
      icon: <AlertIcon />,
    },
    {
      id: 5,
      title: "Earnings",
      route: "earnings",
      icon: <EarningIcon />,
    },
  ];
  return (
    <div className={`fixed bottom-0 left-0 right-0 z-[99] md:z-20 float-left flex w-auto items-center justify-center bg-white px-4 drop-shadow-md md:bottom-[-10.5%] md:left-auto md:right-auto md:mt-28 md:block md:h-screen md:w-[19%] md:px-0 ${(hideSidebar && displayMobile) ? 'hidden' : 'block'}`}>
      <div className="flex justify-between md:flex-col md:px-0">
        <div className="flex w-full flex-row items-center py-3 pt-7 text-xs md:flex-col md:items-start md:gap-x-4 md:gap-y-8 md:px-4 md:text-base">
          {sideLinks.map((link) => (
            <NavLink
              to={`/vendor-dashboard/${link.route}`}
              onClick={handleClick}
              className={({ isActive }) =>
                isActive
                  ? "flex w-3/4 cursor-pointer flex-col items-center gap-x-3 rounded-[5px] text-lightblue md:bg-lightblue px-3 py-2 lg:text-white md:flex md:flex-row md:items-start md:py-4"
                  : "flex w-3/4 cursor-pointer  flex-col items-center gap-x-3 bg-none px-3 md:flex-row md:items-start md:py-4"
              }
            >
              <div>{link.icon}</div>
              <span className="text-sm font-medium md:text-base">
                {link.title}
              </span>
            </NavLink>
          ))}
        </div>
        <div
          className={`${active === 6 && "rounded-[5px] bg-[#0096C1] px-2 py-2 text-white"} mt-32  hidden cursor-pointer items-center gap-x-2 px-2 md:ml-4  md:flex`}
          onClick={() => handleTabSwitch(6)}
        >
          <img src={Logout} alt="" />
          <span className="">Logout</span>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
