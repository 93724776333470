
import React, { useEffect, useState } from "react";
import { resoSuccefully } from "../../../../constants/images";


const SuccessModalDispute = ({ message, adminResponse }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 1800000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="my-7 rounded-[17px] bg-white px-5 py-8 drop-shadow-md">
      <h3 className="text-[20px] font-medium text-[#002D3A]">Resolution Decision</h3>
      {show && (
        <div className="mt-2 flex space-x-2">
          <div className="">
            <img src={resoSuccefully} alt="Success" />
          </div>
          <span className="text-[16px] text-[#21A601]">Resolution sent successfully</span>
        </div>
      )}
      <div className="text-[#002D3A] text-[18px]">
        <span>{message}</span>
      </div>
      <div className="">
        <h3 className="mt-7 text-[#5F5F5F] text-[18px]">Reason</h3>
        <p className="text-[20px] leading-[30px]">
          {adminResponse || "No reason provided."}
        </p>
      </div>
    </div>
  );
};

export default SuccessModalDispute;
