import React from "react";
import Navbar from "../../_components/ui/navbar";
import SideBar from "../../_components/ui/sidebar";
import SupportContent from "./_components/support-content";

const VendorDashboardSupport = () => {
  return (
    <div className="relative">
      <Navbar />
      <SideBar />
      <SupportContent />
    </div>
  );
};

export default VendorDashboardSupport;
