import React, { useState } from "react";
import { notice } from "../../../../../../constants/images";
import { Button } from "../../../../../../components/ui";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDisputeDashboard } from "../../../../../../features/overlay";

const SuccessModal = ({ handleClose }) => {
  const dispatch = useDispatch()

  const handleDashboard = () => {
    dispatch(setDisputeDashboard(true));
  };

  return (
    <div className="fixed left-0 right-0 z-[999] mx-auto block w-5/6 bg-primary px-4 py-8 md:relative md:top-[35%] md:w-full md:px-10 md:py-12">
      <div
        className="absolute right-4 top-2 text-2xl hover:cursor-pointer md:right-10"
        onClick={handleClose}
      >
        x
      </div>
      <div className="flex flex-col items-start gap-y-4 md:gap-y-2">
        <img src={notice} alt="notice" />
        <span>
          Thank you for providing the necessary documentation. The dispute will be reviewed and you
          will be notified of the result once it is done or if we need addtional information
        </span>
        <span className="mx-auto cursor-pointer text-center font-medium text-lightblue md:mx-0 md:text-start">
          Track dispute
        </span>
        <div className="mx-auto block md:hidden">
            <Button onClick={handleDashboard}>Go to dashboard</Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
