import React, { useEffect, useState } from "react";

const General_Modal = ({
  onClose,
  children,
  width = "40%",
  alignment = "center",
  verticalAlignment = "center"
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize(); 
    window.addEventListener("resize", checkScreenSize); 
    return () => window.removeEventListener("resize", checkScreenSize); 
  }, []);

  const handleOnClose = (e) => {
    if (e.target.id === "modal") return onClose();
  };

  const getHorizontalAlignmentClasses = () => {
    if (isMobile) return "justify-center"; 
    switch (alignment) {
      case "left":
        return "justify-start";
      case "right":
        return "justify-end";
      default:
        return "justify-center";
    }
  };

  const getVerticalAlignmentClasses = () => {
    if (isMobile) return "items-end"; 
    switch (verticalAlignment) {
      case "top":
        return "items-start";
      case "bottom":
        return "items-end";
      default:
        return "items-center";
    }
  };

  return (
    <div
      id="modal"
      onClick={handleOnClose}
      className={`fixed inset-0 flex h-screen z-50 bg-black bg-opacity-70 ${getHorizontalAlignmentClasses()} ${getVerticalAlignmentClasses()}`}
    >
      <div
        className={`bg-white p-4 px-5 rounded-lg z-10 shadow-lg transition-transform duration-300 ${
          isMobile ? "w-full max-w-md animate-slide-up" : ""
        }`}
        style={{
          width: isMobile ? "100%" : width, 
          maxHeight: isMobile ? "90vh" : "auto", 
        }}
      >
        <div className="overflow-y-auto" style={{ maxHeight: "100%" }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default General_Modal;
