import "../Dash.css";
import React, { useState, useEffect } from "react";
import { searchicon, linkarrow, nothingFound } from "../../../constants/images";
import { Input } from "../../../components/ui";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { catergoryCheckbox, vendors,vendorsMobile } from "../data";
import { stars } from "../../vendor-dashboard/data";
import Ratings from "../../services/desktop-view/_components/sidebar/_components/ratings";
import DesktopVendor from "../cards/DesktopVendor";
import MobileVendors from "../cards/MobileVendors";



const SearchCompnent = ({ searchParams }) => {
const [allJobs, setAlljobs] = useState(vendors)
const [showCategory, setShowCategory] = useState(false);
const [isChecked, setIsChecked] = useState(" ");
const [seledtedRatings, setSelectedRatings] = useState(null);
const [rangeValues, setRangeValues] = useState([]);
const [location, setLocation] = useState("");
const [min, setMin] = useState(2000);
const [max, setMax] = useState(50000);

useEffect(() => {
  const filteredVendors = vendors
    .filter((vendor) => vendor.title.includes(capitalizedSearchParams)) // Filter jobs based on searchValue
    .slice(0, seeAllvendors);
    setAlljobs(filteredVendors);
}, []);

useEffect(() => {
  const priceFilter = vendors
    .filter((vendor) => vendor?.range >= min && vendor?.range <= max) // Filter jobs based on searchValue
    .slice(0, seeAllvendors);
    setAlljobs(priceFilter);
}, [min, max]);
useEffect(() => {
  if (rangeValues.length !== 0) {
    setMin(rangeValues[0]);
    setMax(rangeValues[1]);
  }
}, [rangeValues]);

useEffect(() => {
  const categoryFilter = vendors
    .filter((vendor) => vendor.title.includes(isChecked)) // Filter jobs based on searchValue
    .slice(0, seeAllvendors);
    setAlljobs(categoryFilter);
}, [isChecked]);

useEffect(() => {
  const locationFilter = vendors
    .filter((vendor) => vendor.location.includes(location)) // Filter jobs based on searchValue
    .slice(0, seeAllvendors);
  setAlljobs(locationFilter);
}, [location]);

const handleCategories = () => {
  setShowCategory(!showCategory);
};

const [isLike,setIsLike]=useState({})
const handleLike=(id)=>{
    setIsLike(prev=>({
      ...prev,
      [id]:!prev[id]
    }))}

  const [active, setActive] = useState(0);
  const handleTabSwitch = (index) => {
    setActive(index);
  };
  const [seeAllvendors, setSeeAllVendors] = useState(9);
  const handleSeeAllVendors = () => {
    const lenthOfArray = vendors.length;
    if (seeAllvendors < lenthOfArray) {
      setSeeAllVendors(seeAllvendors + 9);
    }
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const capitalizedSearchParams = capitalizeFirstLetter(searchParams);
  useEffect(() => {
     allJobs
      .filter((vendor) => vendor.service.includes(capitalizedSearchParams)) // Filter vendors based on searchParams
      .slice(0, seeAllvendors);
  },[allJobs])
 // Use slice instead of splice
    const filteredMobileVendors = vendorsMobile 
    .filter((vendor) => vendor.service.includes(capitalizedSearchParams)) // Filter vendors based on searchParams
    .slice(0, seeAllvendors); 

  return (
    <div className=" mx-auto flex w-full gap-8 px-10 py-5 ">
       <div className="hidden h-full w-[25%] md:flex md:flex-col">
        <div className="w-full rounded-[5px] border-[0.5px] border-[#AEAEAE] px-5 py-4">
          <div className="flex items-center justify-between">
            <span className="text-[16px] font-[500] text-[#5F5F5F]">
              Category
            </span>
            <img src={linkarrow} alt="" className="cursor-pointer" />
          </div>
          <div className="mt-6 flex flex-col">
            <div
              className="relative flex cursor-pointer items-center justify-between"
              onClick={handleCategories}
            >
              <span className="w-full  rounded-[5px] border  border-gray-200 bg-primary p-2 text-sm text-lightgray outline-none md:text-base">
                Select categories
              </span>
              <img
                src={linkarrow}
                alt="categories"
                className={`absolute right-4  ${showCategory && "ease rotate-90 transition-all"}`}
              />
            </div>

            {showCategory && (
              <div className="animate-slide_up rounded-[4px] bg-white px-4 py-5 drop-shadow-xl">
                <form action="">
                  <div className="relative flex">
                    <Input
                      type="search"
                      className="min-w-[100%] rounded-[5px] px-7 py-[4px]"
                      placeholder="Search for category"
                    />{" "}
                    <img
                      src={searchicon}
                      alt=""
                      className="absolute bottom-2 z-10 w-[2rem] px-2"
                    />
                  </div>
                </form>
                <div className="mt-3  h-[10rem] overflow-y-auto bg-white ">
                  <div className="flex cursor-pointer flex-col gap-3 text-[14px] font-normal text-[#5F5F5F]">
                    {catergoryCheckbox.map((catergory) => (
                      <label htmlFor="" key={catergory.id}>
                        <Input
                          type="checkbox"
                          value={catergory.title}
                          id={catergory.catId}
                          name={catergory.catId}
                          className="mr-2"
                          checked={isChecked === catergory.title}
                          onChange={(e) => setIsChecked(e.target.value)}
                        />
                        {catergory.title}
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full pt-10">
          <div className="space-y-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-5">
            <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
              <span>Location</span>
              <img src={linkarrow} alt="next" className="cursor-pointer" />
            </div>
            <div className=" flex w-full flex-col gap-2">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="location"
                  value="Physical"
                  id="onsite"
                  onChange={(e) => setLocation(e.target.value)}
                  checked={location === "Physical"}
                  className="onsite"
                />
                <label
                  htmlFor="onsite"
                  className="text-base font-normal text-lightgray"
                >
                  On site
                </label>
              </div>

              <input
                type="text"
                placeholder="Type your location"
                className="border-[0.5px] border-[#AEAEAE] px-[15px] py-[10px] outline-0"
              />
            </div>
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                value="Remote"
                name="location"
                id="remote"
                checked={location === "Remote"}
                onChange={(e) => setLocation(e.target.value)}
              />
              <label
                htmlFor="remote"
                className="text-base font-normal text-lightgray"
              >
                Remote
              </label>
            </div>
          </div>
        </div>
        <div className="w-full pt-10">
          <div className="space-y-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-5">
            <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
              <span>Price range</span>
              <img src={linkarrow} alt="next" className="cursor-pointer" />
            </div>
            <div className="flex w-full items-center justify-between">
              <span className="text-xs font-normal text-lightgray">
                &lt; ₦{min}
              </span>
              <span className="text-xs font-normal text-lightgray">₦{max}</span>
            </div>
            <RangeSlider
              min={2000}
              max={50000}
              step={500}
              onInput={(e) => setRangeValues(e)}
            />
          </div>
        </div>
        <div className="w-full pt-10">
          <div className="gap-4 rounded-[5px] border-[0.5px] border-[#AEAEAE] px-4 py-3">
            <div className="flex w-full items-center justify-between text-lg font-medium text-lightgray">
              <span>Rating</span>
              <img src={linkarrow} alt="next" className="cursor-pointer" />
            </div>
            {stars.map((star) => {
              return (
                <div className="flex flex-col items-start" key={star.id}>
                  <Ratings
                    star={star}
                    value={star.value}
                    isSelected={seledtedRatings === star.value}
                    onCheckboxChange={(e) =>
                      setSelectedRatings(+e.target.value)
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full md:w-[70%]">
        <div className="mb-5 flex justify-start gap-3 md:text-[30px] text-[16px]  text-[#002D3A]">
          Search results for
          <span className=" font-semibold capitalize text-[#002D3A]">
            {" "}
            "{searchParams !== "" && searchParams}"
          </span>
        </div>
        <div className="mx-auto flex gap-2">
          <div
            className={`${active === 0 && "flex justify-center border-[2px] border-[#0096C1] bg-[#E6F5F9] font-[500] text-[#0096C1]"} "flex cursor-pointer justify-center rounded-[10px] border-[1px] px-2 py-1  text-center md:w-[7rem]`}
            onClick={() => handleTabSwitch(0)}
          >
            All
          </div>
          <div
            className={`${active === 1 && "flex justify-center border-[2px] border-[#0096C1] bg-[#E6F5F9] font-[500] text-[#0096C1]"} "flex w-[7rem] cursor-pointer justify-center rounded-[10px] border-[1px] px-2  py-1 text-center`}
            onClick={() => handleTabSwitch(1)}
          >
            Near you
          </div>
          <div
            className={`${active === 2 && "flex justify-center border-[2px] border-[#0096C1] bg-[#E6F5F9] font-[500] text-[#0096C1]"} "flex w-[7rem] cursor-pointer justify-center rounded-[10px] border-[1px] px-2  py-1 text-center`}
            onClick={() => handleTabSwitch(2)}
          >
            Popular
          </div>
          <div
            className={`${active === 3 && "flex justify-center border-[2px] border-[#0096C1] bg-[#E6F5F9] font-[500] text-[#0096C1]"} "flex w-[7rem] cursor-pointer justify-center rounded-[10px] border-[1px] px-2  py-1 text-center`}
            onClick={() => handleTabSwitch(3)}
          >
            Saved
          </div>
        </div>
        <div className=" hidden md:flex md:flex-col">
          <div className="mt-4">
            {allJobs.length === 0 && (
              <div className="mx-auto flex w-full flex-col items-center justify-center gap-4">
                <img src={nothingFound} alt="" />
                <h3 className=" text-[25px] font-semibold text-[#002D3A]">
                  Match not found
                </h3>
                <p className="w-[461px] text-[18px] font-normal">
                  Sorry, the keyword you entered cannot be found, please check
                  again or search with another keyword
                </p>
              </div>
            )}
            {allJobs.length > 0 && (
              <div className=" grid grid-cols-3 gap-4">
                {allJobs.map((vendor,index) => (
                       <DesktopVendor
                       key={vendor.id}
                       to={`/cardContent/${index}`}
                       id={vendor.id}
                       image={vendor.image}
                       vendor={vendor.vendor}
                       name={vendor.name}
                       title={vendor.title}
                       service={vendor.service}
                       location={vendor.location}
                       rating={vendor.rating}
                       rating_count={vendor.rating_count}
                       price={vendor.price}
                       bgcolor={vendor.bgcolor}
                       handleLike={()=>handleLike(vendor.id)}
                       isLike={isLike}
                     />
                ))}
              </div>
            )}
          </div>
        </div>
        
          <div className="mt-4 md:hidden">
            {allJobs.length === 0 && (
              <div className="mx-auto flex w-full flex-col items-center justify-center gap-4">
                <img src={nothingFound} alt="" />
                <h3 className=" text-[25px] font-semibold text-[#002D3A]">
                  Match not found
                </h3>
                <p className="md:w-[461px] text-[18px] text-center font-normal">
                  Sorry, the keyword you entered cannot be found, please check
                  again or search with another keyword
                </p>
              </div>
            )}
            {filteredMobileVendors.length > 0 && (
              <div className=" flex flex-col gap-4">
                {filteredMobileVendors.map((vendor,index) => (
                      <MobileVendors key={vendor.id} 
                      to={`/cardContent/${index}`}
                      id={vendor.id}
                      image={vendor.image}
                      vendor={vendor.vendor}
                      name={vendor.name}
                      title={vendor.title}
                      service={vendor.service}
                      location={vendor.location}
                      rating={vendor.rating}
                      rating_count={vendor.rating_count}
                      price={vendor.price}
                      bgcolor={vendor.bgcolor}
                      handleLike={()=>handleLike(vendor.id)}
                      isLike={isLike} />
                ))}
              </div>
            )}
          </div>
        {
          allJobs < allJobs.length ? (   <div
            className="mx-auto mt-7 flex cursor-pointer justify-center rounded-[7px] border-[2px] border-[#0096C1] bg-[#E6F5F9] py-2 font-[500] text-[#0096C1] transition-all ease-in-out hover:scale-105 md:w-[40%]"
            onClick={handleSeeAllVendors}
          >
            Load more services
          </div>):""
        }
     
      </div>
    </div>
  );
};

export default SearchCompnent;
