import React from "react";
import Container from "../../../container";
import { Link } from "react-router-dom";
import { linkIcon } from "../../../../../constants/images";

const LegalDetails = () => {
  return (
    <Container>
      <div className="mx-auto px-0 py-20 md:w-[80%] md:px-10">
        <h1 className="text-center text-[25px] font-medium text-[#002D3A] md:text-start">Legal</h1>

        <div className="mt-8 flex w-full flex-col gap-y-6 md:w-[80%]">
          <Link to={"#"} className="rounded-[5px] border border-[#AEAEAE] py-2">
            <div className="flex items-center justify-between px-4 py-2 text-sm md:text-base">
              <span className="text-sm font-medium text-[#181818] md:text-lg">Privacy policy</span>
              <img src={linkIcon} alt="link" className="" />
            </div>
          </Link>
          <Link to={"#"} className="rounded-[5px] border border-[#AEAEAE] py-2">
            <div className="flex items-center justify-between px-4 py-2 text-sm md:text-base">
              <span className="text-sm font-medium text-[#181818] md:text-lg">Terms of service</span>
              <img src={linkIcon} alt="link" className="" />
            </div>
          </Link>
          </div>
      </div>
    </Container>
  );
};

export default LegalDetails;
