import React, { useState } from "react";
import CheckWorkModal from "./checkworkModal";
import WarnningBanner from "./cards/banner";
import CheckWorkContent from "./cards/checkWorkContent";
import General_Modal from "../../../shared/modals";
import ThankYouModal from "./sucessModal";
import DisputeModal from "./checkworkModal/dispute/disputeModal";
import OpenDispute from "./checkworkModal/dispute/openDispute";
import ThankYouForPro from "./checkworkModal/dispute/openDispute/thankYouPro";
const CheckWork = () => {
  const [showModal, setShowModal] = useState(null);

  const handleOnClose = () => setShowModal(null);

  return (
    <div className="">
      <WarnningBanner />
      <CheckWorkContent setShowModal={setShowModal} />

      {showModal === "rateVendor" && (
        <General_Modal onClose={handleOnClose}>
          <CheckWorkModal setShowModal={setShowModal} />
        </General_Modal>
      )}

      {showModal === "disputeModal" && (
        <General_Modal onClose={handleOnClose}>
          <DisputeModal setShowModal={setShowModal} />
        </General_Modal>
      )}

      {showModal === "thanksModal" && (
        <General_Modal onClose={handleOnClose}>
          <ThankYouModal setShowModal={setShowModal} />
        </General_Modal>
      )}

      {showModal === "diputeform" && (
        <General_Modal onClose={handleOnClose}>
          <OpenDispute setShowModal={setShowModal} />
        </General_Modal>
      )}

      {showModal === "thankYouforPro" && (
        <General_Modal onClose={handleOnClose}>
          <ThankYouForPro setShowModal={setShowModal} />
        </General_Modal>
      )}
    </div>
  );
};

export default CheckWork;
