import React from "react";
import { whiteSearch, whitelogo } from "../../../../constants/images";
import { useDispatch } from "react-redux";
import { Input } from "../../../../components/ui";
import { handleDisputeSearch } from "../../../../features/searchSlice";
import { clearAccessToken, setAccessToken } from "../../../redux/slice/login";
import { useNavigate } from "react-router-dom";
import { store } from "../../../../redux/store";
import { persistStore, purgeStoredState } from "redux-persist";

const DisputNavBar = () => {
  // const persistor = persistStore(store);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const searchParam = e.target.value;
    dispatch(handleDisputeSearch(searchParam));
  };
  // const access = JSON.parse(localStorage.getItem("persist:token"));
  // delete access.accessToken;
;
  const handleLogOut = () => {
    // const access = JSON.parse(localStorage.getItem("persist:token"));
    // delete access.accessToken;
    // localStorage.setItem("persist:token", JSON.stringify(access));
    // dispatch(clearAccessToken());
    // localStorage.removeItem("persist:token");
    dispatch(setAccessToken(null))
    navigate("/dispute");
  };

  return (
    <div className="flex items-center justify-between bg-[#002D3A] px-10 py-3">
      <div className="">
        <img src={whitelogo} alt="" className="w-24" />
      </div>
      <div className="w-[35%]">
        <form action="">
          <div className="relative flex w-full">
            <Input
              type="search"
              onChange={handleInputChange}
              className="min-w-[100%] rounded-[5px] bg-inherit px-8 py-1 text-[white]"
              placeholder="Search"
            />{" "}
            <img src={whiteSearch} alt="" className="absolute bottom-2 z-10 px-2" />
          </div>
        </form>
      </div>
      <button className="text-white" onClick={handleLogOut}>
        LogOut
      </button>
      <div className="flex flex-col items-end text-white">
        <h3 className="font-semibold">Dispute resolution team</h3>
        <span className="text-[12px] font-normal">admin</span>
      </div>
    </div>
  );
};

export default DisputNavBar;
