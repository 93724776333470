import React from "react";
import { projectsData } from '../data';
import NoProject from '../noProjects';
import Card from '../card';
import MobileCard from "../card/moblieCard";

const DefaultProject = ({ activeStatus }) => {
  
  const filteredData = projectsData.filter((project) => {
    if (activeStatus === 'All') return true; // Show all projects if 'All' is selected
    return project.status === activeStatus;  // Filter based on activeStatus
  });

  return (
    <div>
      <div className="">
        {filteredData.length === 0 && <NoProject />} {/* Show NoProject if no projects */}
        
        {filteredData.length > 0 && (
          <>
            {/* Desktop View */}
            <div className="md:flex flex-col gap-6 hidden">
              {filteredData.map((projectData, index) => (
                <Card
                  key={projectData.id}
                  to={`/projectCardContent/${index}`}
                  id={projectData.id}
                  title={projectData.title}
                  address={projectData.address}
                  name={projectData.name}
                  ratings={projectData.ratings}
                  date={projectData.date}
                  budget={projectData.budget}
                  image={projectData.image}
                  status={projectData.status}
                />
              ))}
            </div>
            {/* Mobile View */}
            <div className="md:hidden flex flex-col gap-6">
              {filteredData.map((projectData, index) => (
                <MobileCard
                  key={projectData.id}
                  to={`/projectCardContent/${index}`}
                  id={projectData.id}
                  title={projectData.title}
                  address={projectData.address}
                  name={projectData.name}
                  ratings={projectData.ratings}
                  date={projectData.date}
                  budget={projectData.budget}
                  image={projectData.image}
                  status={projectData.status}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DefaultProject;
