import React from "react";
import { Button } from "../../../../../../components/ui";
import { editIcon, profile, star } from "../../../../../../constants/images";

const ProfileContentModal = () => {
  return (
    <div className="md:absolute relative md:top-3/4 md:ml-10 flex md:w-[30%] flex-col md:gap-y-8">
      <div className="md:rounded-[5px] md:bg-primary md:py-14 md:drop-shadow-xl">
        <div className="flex flex-col items-center justify-center gap-y-1 md:gap-y-2">
          <div className="relative">
            <img src={profile} className="md:w-[100px] w-[50px] rounded-[50%]" alt="" />
            <img src={editIcon} alt="edit" className="absolute w-[16px] md:w-[30px] bottom-0 right-0 cursor-pointer" />
          </div>
          <span className="text-lg md:text-xl font-semibold text-secondary">John Doe</span>
          <span className="font-lightgray text-base md:text-lg text-secondary">Plumber</span>
          <div className="flex items-center gap-x-2">
            <img src={star} alt="ratings" />
            <span className="text-[10px] md:text-sm font-semibold">
              4.8 <span className="font-normal">(31)</span>
            </span>
          </div>
          <div className="grid gap-x-4 grid-cols-3">
            <div className="flex flex-col items-center rounded-[5px] bg-primary px-2 py-3 drop-shadow-lg">
              <span className="text-xl font-semibold text-lightblue">34</span>
              <span className="text-xs md:text-sm font-normal text-secondary">Completed jobs</span>
            </div>
            <div className="flex flex-col items-center rounded-[5px] bg-primary px-2 py-3 drop-shadow-lg">
              <span className="text-xl font-semibold text-lightblue">5</span>
              <span className="text-xs md:text-sm font-normal text-secondary">Ongoing jobs</span>
            </div>
            <div className="flex flex-col items-center rounded-[5px] bg-primary px-2 py-3 drop-shadow-lg">
              <span className="text-xl font-semibold text-lightblue">0</span>
              <span className="text-xs md:text-sm font-normal text-secondary">Cancelled jobs</span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:relative hidden md:block md:top-0 md:px-0">
        <Button className="mx-auto w-full" backgroundColor="transparent" textColor="#0096C1">
          Edit profile details
        </Button>
      </div>
    </div>
  );
};

export default ProfileContentModal;
