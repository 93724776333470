import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TableBody from "../tableBody"
import TableHead from "../tableHead"
import { formatDate } from "../../../../utils/dateUtils";

const AllDispute = ({allDisputes,setCurrentPage,currentPage,itemsPerPage}) => {
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = allDisputes?.slice(indexOfFirstItem, indexOfLastItem);
  const currentDataLength = currentData?.length;
  const totalPages = Math.ceil(allDisputes?.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const goToPreviousPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  const goToNextPage = () => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));


  return (
    <div className="">
    <table className="w-full" >
      
     {allDisputes&& <TableHead />}

      {
       allDisputes? currentData.map(data=>{
          return(
        <TableBody key={data.dispute_id} {...data} date_of_complain={formatDate(data?.date_of_complain)} to={`disputereview/${data.dispute_id}`} />)}):
        new Array(1,2,3,4,5,6,7).map(()=><Skeleton height={'10vh'} width={'94vw'}/>)
      }
      

    </table>
    <div className="mt-4 flex justify-between px-5 py-4">
            <div className="text-[18px] text-[#002D3A]">
              <span>Showing</span> <span>{currentDataLength}</span> <span>entries</span>
            </div>
            <div className="flex items-center">
              <button
                onClick={goToPreviousPage}
                disabled={currentPage === 1}
                className={`mx-1 rounded border px-3 py-1 ${
                  currentPage === 1 ? "cursor-not-allowed bg-gray-300 text-gray-500" : "bg-white text-blue-500"
                }`}
              >
                Previous
              </button>
              {allDisputes?[...Array(totalPages)].map((_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`mx-1 rounded border px-3 py-1 ${
                    currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-white text-blue-500"
                  }`}
                >
                  {i + 1}
                </button>
              )):null}
              <button
                onClick={goToNextPage}
                disabled={currentPage === totalPages}
                className={`mx-1 rounded border px-3 py-1 ${
                  currentPage === totalPages ? "cursor-not-allowed bg-gray-300 text-gray-500" : "bg-white text-blue-500"
                }`}
              >
                Next
              </button>
            </div>
    </div>

    </div>
  )
}

export default AllDispute


