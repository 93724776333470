import React from "react";
import Nav from "../shared/nav";
import SideBar from "../shared/sidebar";

const Container = ({ children }) => {
  return (
    <div className="">
      <Nav />

      <div className="flex w-full">
        <div className="z-50 hidden w-[15%] md:flex lg:flex">
          <SideBar />
        </div>
        <div className="w-full md:w-[85%] lg:w-[85%] ">{children}</div>
      </div>
      <div className="fixed bottom-0 z-50  w-full md:hidden">
        <SideBar />
      </div>
    </div>
  );
};

export default Container;
