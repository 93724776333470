import React from "react";
import { attention } from "../../../../../../../../../../constants/images";
import { Link } from "react-router-dom";
const ThankYouForPro = () => {
  return (
    <div className="">

      <div className="">
        <div className="">
          <img src={attention} alt="Attention" />
        </div>
        <p className="text-[18px] leading-[25px] mt-2">
          Thank you for providing the necessary documentation. Your dispute will be reviewed and you
          will be notified with the result once it is done or if we need additional information.
        </p>
        <Link to="/project/track-dispute">
        <h5 className="text-[#0096C1] font-medium mt-9">Track dispute</h5>
        </Link>
      </div>
    </div>


  );
};

export default ThankYouForPro;
