import React from "react";
import {
  twitterGray,
  facebookGray,
  instagramGray,
  websiteGray,
  emailGray,
  linkIcon,
} from "../../../../../../../constants/images";
const Contact = () => {
  return (
    <div className="px-5 py-3">
      <div className="flex justify-between border-t-2 ">
        <div className="flex flex-col space-y-5 pt-3 text-[16px] text-[#5F5F5F]">
          <div className="flex items-center space-x-2">
            {" "}
            <img src={emailGray} alt="emailGray" className="w-4" />
            <span>Email</span>
          </div>
          <div className="flex items-center space-x-2">
            {" "}
            <img src={websiteGray} alt="websiteGray" className="w-4" />
            <span>Website</span>
          </div>
          <div className="flex items-center space-x-2">
            {" "}
            <img src={twitterGray} alt="twitterGray" className="w-4" />
            <span>Twitter</span>
          </div>
          <div className="flex items-center space-x-2">
            {" "}
            <img src={instagramGray} alt="instagramGray" className="w-4" />
            <span>Instagram</span>
          </div>
          <div className="flex items-center space-x-2">
            {" "}
            <img src={facebookGray} alt="facebookGray" className="w-4" />
            <span>Facebook</span>
          </div>
        </div>
        <div className="flex flex-col space-y-5 pt-3 text-[16px] text-[#002D3A]">
          {[
            "customersupport@gywd.com",
            "gywd.com",
            "@gywd",
            "@getyourworkdone",
            "@getyourworkdone",
          ].map((tx, i) => (
            <div key={i} className="">
              <a className="hidden md:block" href="#">
                {...tx}
              </a>{" "}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Contact;
