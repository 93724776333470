import React from "react";
import { Button } from "../../../../../components/ui";
import { useDispatch } from "react-redux";
import { setShowVendorSwitch, setVendorSwitch } from "../../../../../features/overlay";
import { useNavigate } from "react-router-dom";
import { category } from "../../../../../features/categorySlice";

const UserSwitchModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCancel = () => {
    dispatch(setShowVendorSwitch(false));
  };
  const handleSwitch = () => {
    dispatch(setVendorSwitch(true));
    dispatch(category("consumer"))
    navigate("/signup");
  };
  return (
    <>
      <div className="fixed z-[99] h-screen w-screen bg-black bg-opacity-35" />
      <div className="fixed bottom-0 left-0 top-[30%] z-[999] w-full animate-slide_up rounded-tl-[20px] rounded-tr-[20px] bg-primary md:bottom-[10%] md:left-[35%] md:top-[35%] md:mx-0 md:h-fit md:w-[30%] md:animate-none md:rounded-[5px]">
        <span
          className="absolute right-6 top-2 text-xl font-medium hover:cursor-pointer md:right-10"
          onClick={handleCancel}
        >
          x
        </span>
        <div className="w-full px-6 py-8">
          <div className="flex flex-col gap-y-10">
            <span className="text-center font-semibold text-secondary md:text-xl">
              Switch to consumer
            </span>
            <span className="text-start font-medium text-secondary md:text-lg">
              Are you sure you want to switch from Vendor to Consumer?
            </span>
            <span className="text-start font-normal text-[#181818] md:text-base">
              You will be able to find and hire skilled vendors who meet your service requirements
            </span>
            <div className="absolute bottom-10 left-0 right-0 flex w-full gap-x-2 px-6 md:static md:mx-auto md:px-0">
              <Button
                backgroundColor={"transparent"}
                textColor={"#0096C1"}
                className="w-full"
                onClick={handleCancel}
              >
                No
              </Button>

              <Button className="w-full" onClick={handleSwitch}>
                Yes
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSwitchModal;
