import React, { useState } from "react";
import Navbar from "../../_components/ui/navbar";
import SideBar from "../../_components/ui/sidebar";
import SettingsContent from "./_components/settings-content";
import SettingsMobileContent from "./_components/settings-content/mobile";
import LogoutModal from "./_components/logout_modal";
import CloseAccountModal from "./_components/close-account_modal";

const VendorDashboardSettings = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showCloseAccountModal, setShowCloseAccountModal] = useState(false);

  return (
    <div className="relative">
      <LogoutModal showLogout={showLogoutModal} setShowLogout={setShowLogoutModal} />
      <CloseAccountModal
        showCloseAccount={showCloseAccountModal}
        setShowCloseAccount={setShowCloseAccountModal}
      />
      <Navbar />
      <SideBar />
      <SettingsContent
        setShowLogoutModal={setShowLogoutModal}
        setShowCloseAccountModal={setShowCloseAccountModal}
      />
      <SettingsMobileContent setShowLogoutModal={setShowLogoutModal} setShowCloseAccountModal={setShowCloseAccountModal} />
    </div>
  );
};

export default VendorDashboardSettings;
