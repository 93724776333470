import React from "react";
import { Button } from "../../../components/ui";

const RouteNotFoundError = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center bg-gray-100 p-20">
      <h1 className="mb-4 text-4xl font-bold">Access Denied</h1>
      <p className="mb-6 text-lg">The requested route is restricted or does not exist.</p>
      <div className="flex space-x-4">
        <Button
          onClick={() => window.history.back()}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default RouteNotFoundError;
