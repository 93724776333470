import React, { useState } from "react";
import { left, logout } from "../../../../../../constants/images";
import Toggle from "../../../../../../components/ui/toggle";
import { Link } from "react-router-dom";
import { BiHide } from "react-icons/bi";
import { RxEyeOpen } from "react-icons/rx";
import { Button } from "../../../../../../components/ui";

const SettingsContent = ({ setShowLogoutModal, setShowCloseAccountModal }) => {
  const [onlineStatus, setOnlineStatus] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [passwordSecurityExpanded, setPasswordSecurityExpanded] = useState(true);
  const [notificationSettingsExpanded, setNotificationSettingsExpanded] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [passwordErrors, setPasswordErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const handlePasswordChange = (e) => {
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePasswordToggle = (passwordType) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [passwordType]: !prevShowPassword[passwordType],
    }));
  };

  const validatePasswords = () => {
    const errors = {};
    if (!passwords.currentPassword) {
      errors.currentPassword = "Current password is required";
    }
    if (!passwords.newPassword) {
      errors.newPassword = "New password is required";
    } else if (passwords.newPassword.length < 8) {
      errors.newPassword = "New password must be at least 8 characters";
    }
    if (!passwords.confirmNewPassword) {
      errors.confirmNewPassword = "Confirm new password is required";
    } else if (passwords.confirmNewPassword !== passwords.newPassword) {
      errors.confirmNewPassword = "Passwords do not match";
    }
    setPasswordErrors(errors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validatePasswords();
  };

  const handleToggle = (type) => {
    switch (type) {
      case "onlineStatus":
        setOnlineStatus((prevStatus) => !prevStatus);
        break;
      case "darkMode":
        setDarkMode((prevMode) => !prevMode);
        break;
      case "twoFactorAuth":
        setTwoFactorAuth((prevAuth) => !prevAuth);
        break;
      default:
        break;
    }
  };

  const handleExpand = (section) => {
    switch (section) {
      case "passwordSecurity":
        setNotificationSettingsExpanded(false);
        setPasswordSecurityExpanded(true);
        break;
      case "notificationSettings":
        setPasswordSecurityExpanded(false);
        setNotificationSettingsExpanded(true);
        break;
      default:
        break;
    }
  };
  const [notificationSettings, setNotificationSettings] = useState({
    messages: false,
    serviceRequest: false,
    projectUpdates: false,
    ratingReminders: false,
  });
  const handleNotificationToggle = (type) => {
    setNotificationSettings((prevSettings) => ({
      ...prevSettings,
      [type]: !prevSettings[type],
    }));
  };

  const handleShowChangePassword = () => {
    setPasswordSecurityExpanded(false);
    setShowChangePassword(true);
  };
  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleCloseAccountClick = () => {
    setShowCloseAccountModal(true);
  };

  return (
    <div className="relative hidden h-[120vh] max-w-full items-start justify-center pl-0 pr-0 pt-[8rem] md:float-right md:mx-auto md:ml-auto md:mr-0 md:block md:w-[81%] md:pt-40">
      <Link to="/vendor-dashboard/home" className="block md:hidden">
        <img src={left} alt="go back" className="absolute left-4 w-[10px]" />
      </Link>
      <div className="mt-10 flex w-full flex-col items-start justify-between md:mt-0 md:px-20">
        <span className="mx-4 text-xl font-medium text-secondary md:mx-0">Settings</span>

        {/* // main content */}
        <div className="flex w-full justify-between">
          <div className="mt-10 flex flex-col">
            {/* // side bar */}

            <div className="gap-y- mb-10 flex flex-col">
              <div
                className={`w-[330px] border border-b-0  border-[#AEAEAE] px-4 py-5 hover:cursor-pointer ${ passwordSecurityExpanded && 'border-l-8 border-l-secondary'}`}
                onClick={() => handleExpand("passwordSecurity")}
              >
                <div className="flex items-center justify-between">
                  <span
                    className={`transition-colors ${
                      passwordSecurityExpanded
                        ? "text-base font-medium text-secondary"
                        : "font-normal text-lightgray"
                    }`}
                  >
                    Password and security
                  </span>
                  <img
                    src={left}
                    alt="open"
                    className={`transition-transform duration-300 ${
                      passwordSecurityExpanded ? "rotate-0" : "rotate-90"
                    }`}
                  />
                </div>
              </div>

              <div
                className={`w-[330px] border border-[#AEAEAE] px-4 py-5 hover:cursor-pointer ${ notificationSettingsExpanded && 'border-l-8 border-l-secondary'}`}
                onClick={() => handleExpand("notificationSettings")}
              >
                <div className="flex items-center justify-between">
                  <span
                    className={`transition-colors ${
                      notificationSettingsExpanded
                        ? "text-base font-medium text-secondary"
                        : "font-normal text-lightgray ml-[0.3rem]"
                    }`}
                  >
                    Notification settings
                  </span>
                  <img src={left} alt="open" />
                </div>
              </div>
            </div>

            <div className="mb-10 flex flex-col gap-y-2">
              <div className=" w-[330px] border border-[#AEAEAE] px-4 py-5 ">
                <div className="flex items-center justify-between">
                  <span className="text-base font-medium text-[#181818]">Online status</span>
                  <Toggle
                    defaultChecked={onlineStatus}
                    onChange={() => handleToggle("onlineStatus")}
                  />
                </div>
              </div>

              <div className=" border border-[#AEAEAE] px-4 py-5">
                <div className="flex items-center justify-between">
                  <span className="text-base font-medium text-[#181818]">Enable dark mode </span>
                  <Toggle defaultChecked={darkMode} onChange={() => handleToggle("darkMode")} />
                </div>
              </div>
            </div>

            <div className="gap-y- mb-10 flex flex-col">
              <div className=" w-[330px] border border-b-0 border-[#AEAEAE] px-4 py-5 ">
                <div className="flex items-center justify-between">
                  <span className="text-base font-medium text-[#181818]">Account management</span>
                  <img src={left} alt="open" />
                </div>
              </div>

              <div className="w-[330px] border  border-b-0 border-[#AEAEAE] px-4 py-5">
                <div className="flex flex-col">
                  <div className="flex w-full items-center justify-between" onClick={handleCloseAccountClick}>
                    <span className="text-base font-medium text-lightgray">Close account</span>
                    <img src={left} alt="open" />
                  </div>
                  <span className="text-sm text-lightgray">
                    This will permanently close your account
                  </span>
                </div>
              </div>
              <div className=" border border-[#AEAEAE] px-4 py-5">
                <div className="flex items-center gap-x-2" onClick={handleLogoutClick}>
                  <img src={logout} alt="open" />
                  <span className="text-base font-medium text-[#FF0000]">Logout</span>
                </div>
              </div>
            </div>
          </div>
          {/* // side bar ends */}

          {/* opened content */}
          <div className="w-1/2">
            {/* password and security content */}
            {passwordSecurityExpanded && !notificationSettingsExpanded && (
              <div className="flex w-full flex-col gap-y-2">
                <div
                  className="flex w-full items-center justify-between py-5"
                  onClick={handleShowChangePassword}
                >
                  <span className="text-base font-medium text-[#181818]">Change password</span>
                  <img src={left} alt="open" />
                </div>
                <hr color="#AEAEAE" className="w-full" />
                <div className="flex w-full flex-col gap-y-2 py-5">
                  <div className="flex w-full items-center justify-between">
                    <span className="text-base font-medium text-[#181818]">
                      Two-factor authentication
                    </span>
                    <Toggle
                      defaultChecked={twoFactorAuth}
                      onChange={() => handleToggle("twoFactorAuth")}
                    />
                  </div>
                  <span className="mt-3 text-sm font-normal text-lightgray">
                    <span className="font-medium">Recommended.</span>We will send an authentication
                    code via SMS or email when using an unrecognized device or browser to log in
                  </span>
                </div>
                <hr color="#AEAEAE" className="w-full" />
              </div>
            )}

            {notificationSettingsExpanded && !passwordSecurityExpanded && (
              <div className="flex w-full flex-col gap-y-6">
                <span className="text-lg font-medium text-[#181818]">
                  Turn on push notifications
                </span>
                <span className="text-sm font-normal text-lightgray">
                  Allow GYWD to send you notifications to quickly see updates about your orders and
                  activities
                </span>
                <hr color="#AEAEAE" className="w-full" />
                <div className="flex w-full flex-col gap-y-4 py-5">
                  <span className="text-lg font-medium text-[#181818]">Email notifications</span>
                  <hr color="#AEAEAE" className="w-full" />
                  <div className="flex flex-col gap-y-5">
                    <div className="mt-4 flex w-full items-center justify-between">
                      <span className="text-base font-medium text-lightgray">Messages</span>
                      <Toggle
                        defaultChecked={notificationSettings.messages}
                        onChange={() => handleNotificationToggle("messages")}
                      />
                    </div>
                    <hr color="#AEAEAE" className="w-full" />
                    <div className="flex w-full items-center justify-between">
                      <span className="text-base font-medium text-lightgray">Service request</span>
                      <Toggle
                        defaultChecked={notificationSettings.serviceRequest}
                        onChange={() => handleNotificationToggle("serviceRequest")}
                      />
                    </div>
                    <hr color="#AEAEAE" className="w-full" />
                    <div className="flex w-full items-center justify-between">
                      <span className="text-base font-medium text-lightgray">Project updates</span>
                      <Toggle
                        defaultChecked={notificationSettings.projectUpdates}
                        onChange={() => handleNotificationToggle("projectUpdates")}
                      />
                    </div>
                    <hr color="#AEAEAE" className="w-full" />
                    <div className="flex w-full items-center justify-between">
                      <span className="text-base font-medium text-lightgray">Rating reminders</span>
                      <Toggle
                        defaultChecked={notificationSettings.ratingReminders}
                        onChange={() => handleNotificationToggle("ratingReminders")}
                      />
                    </div>
                    <hr color="#AEAEAE" className="w-full" />
                  </div>
                </div>
              </div>
            )}
            {!notificationSettingsExpanded && !passwordSecurityExpanded && showChangePassword && (
              <div className="flex w-full flex-col gap-y-6">
                <div className="flex w-full items-center justify-between py-2">
                  <span className="text-base font-medium text-[#181818]">Change password</span>
                  <img src={left} alt="open" className="rotate-90" />
                </div>
                <div className="flex w-full flex-col gap-y-4 py-2">
                  <div className="relative flex flex-col gap-y-2">
                    <label
                      htmlFor="current_password"
                      className="text-base font-normal text-[#181818]"
                    >
                      Enter current password
                    </label>
                    <input
                      type={showPassword.currentPassword ? "text" : "password"}
                      name="currentPassword"
                      id="current_password"
                      value={passwords.currentPassword}
                      onChange={handlePasswordChange}
                      className="rounded-[5px] border border-[#181818] px-4 py-2 outline-none"
                    />
                    {showPassword.currentPassword ? (
                      <RxEyeOpen
                        className="absolute right-4 top-10 cursor-pointer"
                        color="#5F5F5F"
                        size={24}
                        onClick={() => handlePasswordToggle("currentPassword")}
                      />
                    ) : (
                      <BiHide
                        className="absolute right-4 top-10 cursor-pointer"
                        color="#5F5F5F"
                        size={24}
                        onClick={() => handlePasswordToggle("currentPassword")}
                      />
                    )}
                    {passwordErrors.currentPassword && (
                      <span className="text-sm text-red-500">{passwordErrors.currentPassword}</span>
                    )}
                  </div>

                  <div className="relative flex flex-col gap-y-2">
                    <label htmlFor="new_password" className="text-base font-normal text-[#181818]">
                      Enter new password
                    </label>
                    <input
                      type={showPassword.newPassword ? "text" : "password"}
                      name="newPassword"
                      id="new_password"
                      value={passwords.newPassword}
                      onChange={handlePasswordChange}
                      className="rounded-[5px] border border-[#181818] px-4 py-2 outline-none"
                    />
                    {showPassword.newPassword ? (
                      <RxEyeOpen
                        className="absolute right-4 top-10 cursor-pointer"
                        color="#5F5F5F"
                        size={24}
                        onClick={() => handlePasswordToggle("newPassword")}
                      />
                    ) : (
                      <BiHide
                        className="absolute right-4 top-10 cursor-pointer"
                        color="#5F5F5F"
                        size={24}
                        onClick={() => handlePasswordToggle("newPassword")}
                      />
                    )}
                    {passwordErrors.newPassword && (
                      <span className="text-sm text-red-500">{passwordErrors.newPassword}</span>
                    )}
                  </div>

                  <div className="relative flex flex-col gap-y-2">
                    <label
                      htmlFor="confirm_new_password"
                      className="text-base font-normal text-[#181818]"
                    >
                      Confirm new password
                    </label>
                    <input
                      type={showPassword.confirmNewPassword ? "text" : "password"}
                      name="confirmNewPassword"
                      id="confirm_new_password"
                      value={passwords.confirmNewPassword}
                      onChange={handlePasswordChange}
                      className="rounded-[5px] border border-[#181818] px-4 py-2 outline-none"
                    />
                    {showPassword.confirmNewPassword ? (
                      <RxEyeOpen
                        className="absolute right-4 top-10 cursor-pointer"
                        color="#5F5F5F"
                        size={24}
                        onClick={() => handlePasswordToggle("confirmNewPassword")}
                      />
                    ) : (
                      <BiHide
                        className="absolute right-4 top-10 cursor-pointer"
                        color="#5F5F5F"
                        size={24}
                        onClick={() => handlePasswordToggle("confirmNewPassword")}
                      />
                    )}
                    {passwordErrors.confirmNewPassword && (
                      <span className="text-sm text-red-500">
                        {passwordErrors.confirmNewPassword}
                      </span>
                    )}
                  </div>
                  <Button onClick={handleSubmit}>Update</Button>
                  <div className="flex w-full flex-col gap-y-2 py-5">
                    <div className="flex w-full items-center justify-between">
                      <span className="text-base font-medium text-[#181818]">
                        Two-factor authentication
                      </span>
                      <Toggle
                        defaultChecked={twoFactorAuth}
                        onChange={() => handleToggle("twoFactorAuth")}
                      />
                    </div>
                    <span className="mt-3 text-sm font-normal text-lightgray">
                      <span className="font-medium">Recommended.</span>We will send an
                      authentication code via SMS or email when using an unrecognized device or
                      browser to log in
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsContent;
