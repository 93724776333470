import React from "react";
import { Button } from "../../../../../../../../components/ui";
import { submitted } from "../../../../../../../../constants/images";

const CheckWorkContent = ({setShowModal}) => {
  return (
    <div>
      <div className="mt-7 flex md:flex-row flex-col md:space-x-10">
        <div className="md:w-[50%] w-full">
          <h3 className="text-[18px] font-semibold ">Plumbing service for my bathroom</h3>
          <span className="mt-5 md:text-[14px] text-[12px] text-[#5F5F5F]">
            Submitted: <span className="md:text-[16px] text-[14px] font-medium text-[#181818]">23 Dec 2023</span>
          </span>
          <div className="mt-4 w-[50%] border-[1px] border-[#AEAEAE] px-3 py-2">
            <span className="">Documents</span>
            <div className="mx-auto">
              <img src={submitted} alt="" className="w-full rounded-md object-contain" />
            </div>
          </div>
        </div>
        <div className="md:w-[50%] w-full">
          <h3 className="md:text-[16px] text-[14px] font-medium text-[#002D3A]">Note</h3>
          <span className="md:text-[16px] text-[14px] md:leading-[18.77px] leading-[16.42px] ">
            I have finished the work. This is the picture. Make sure you keep in mind what I told
            you about how to manage it
          </span>
        </div>
      </div>
      <div className="mx-auto md:w-[30%] w-full">
        <Button className="mt-6 w-full" children="Rate vendor" onClick={() => setShowModal('rateVendor')} />
      </div>
    </div>
  );
};

export default CheckWorkContent;
