import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { jobsData } from "../../data";
import ReactStars from "react-rating-stars-component";
import {
  vendorRound,
  LikeFill,
  LikeUnfill,
  DisLikeUnfill,
  DisLikeFill,
  locationFilled,
  projectchat,
} from "../../../../../constants/images";
import Container from "../../../container";
import { Button } from "../../../../../components/ui";

const JobApplication = () => {
  const { id } = useParams();
  const job = jobsData.find((job) => job.id === parseInt(id));

  const [filter, setFilter] = useState("all");
  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  if (!job) {
    return <div className="text-center">Job not found</div>;
  }

  const filteredApplications = job.applications.filter(
    (app) => filter === "all" || app.status === filter,
  );

  return (
    <Container>
      <div className="py-20 md:px-10 md:py-16 ">
        <h2 className="text-center text-[18px] font-semibold md:text-start md:text-[22px]">
          Applications ({job.applications.length})
        </h2>
        <div className="my-4">
          <div className="flex justify-between  rounded-md bg-white px-4 py-3 drop-shadow-md">
            <button
              onClick={() => setFilter("all")}
              className={`mr-4 ${filter === "all" ? "rounded-sm bg-[#0096C1] px-3 py-1 text-white" : "px-3 py-1"}`}
            >
              All
            </button>
            <button
              onClick={() => setFilter("liked")}
              className={`mr-4 ${filter === "liked" ? "rounded-sm bg-[#0096C1] px-3 py-1 text-white" : "px-3 py-1"}`}
            >
              Liked
            </button>
            <button
              onClick={() => setFilter("messaged")}
              className={`mr-4 ${filter === "messaged" ? "rounded-sm bg-[#0096C1] px-3 py-1 text-white" : "px-3 py-1"}`}
            >
              Messaged
            </button>
            <button
              onClick={() => setFilter("declined")}
              className={`${filter === "declined" ? "rounded-sm bg-[#0096C1] px-3 py-1 text-white" : "px-3 py-1"}`}
            >
              Declined
            </button>
          </div>
          <div className="mt-5 px-5 md:px-0">
            <label htmlFor="" className="pr-3">
              sort:
            </label>
            <select
              onChange={(e) => console.log(`Sorting by: ${e.target.value}`)}
              className="border border-gray-300 p-2"
            >
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
        </div>
        <div className="applications-list">
          {filteredApplications.length ? (
            filteredApplications.map((app) => (
              <div key={app.id} className="rounded border-[1px] px-6 py-5 md:mb-4">
                <div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                      <div>
                        <img src={vendorRound} alt="" />
                      </div>
                      <div>
                        <p className="text-[14px] font-bold md:text-lg">{app.name}</p>
                        <p className=" text-[12px] md:text-[14px]">{app.profession}</p>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <div className="">
                        {app.status === "liked" ? (
                          <img src={LikeFill} alt="LikeFill" />
                        ) : (
                          <img src={LikeUnfill} alt="LikeUnfill" />
                        )}
                      </div>
                      <div className="">
                        {app.status === "declined" ? (
                          <img src={DisLikeFill} alt="DisLikeFill" />
                        ) : (
                          <img src={DisLikeUnfill} alt="DisLikeUnfill" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex items-center justify-between border-b-2 pb-2">
                    <div className="mt-3 flex space-x-2">
                      <div>
                        <img src={locationFilled} alt="locationBlue" />
                      </div>
                      <p className="text-[12px] md:text-[16px]">{app.location}</p>
                      <p className="text-[12px] md:text-[16px]">

                      <ReactStars
                      count={1}
                      onChange={ratingChanged}
                      size={40}
                      activeColor="#ffd700"
                      edit={false}
                      value={1}
                    />{app.rating} ({app.reviewCount})
                      </p>
                    </div>
                    <div className="hidden space-x-2 md:flex">
                      <div className="flex items-center rounded-sm border-[1px] border-[#0096C1] px-2 py-1 cursor-pointer">
                        <img src={projectchat} alt="projectchat" className="w-4" />
                      </div>
                      <div className="rounded-sm bg-[#0096C1] px-2 py-1 text-[14px] font-medium text-white cursor-pointer">

                        Hire
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-3 text-[12px] text-[#5F5F5F] md:text-[14px]">Cover letter:</p>
                <p className="text-[14px] leading-[16px] text-[#002D3A] md:text-[18px] md:leading-[21px]">
                  {app.coverLetter}
                </p>
                <div className="mt-5 flex space-x-3 md:hidden">
                  <Button
                    children="Message"
                    backgroundColor="#ffff"
                    textColor="#0096C1"
                    className="w-full"
                  />
                  <Button children="Hire" className="w-full" />
                </div>
              </div>
            ))
          ) : (
            <p>No applications found for this filter</p>
          )}
        </div>
      </div>
    </Container>
  );
};

export default JobApplication;
