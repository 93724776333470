import React, { useEffect, useState } from "react";
import { Pagination } from "../../../../../../../components";
import TransactionsNavbar from "../transactions-navbar";
import { Dropdown } from "../../../../../../../constants/images";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../../../../utils/dateUtils";
import { paginateData } from "../../../../../../../utils/pagination";
import filterTransactions from "../../../../../../../utils/transactionFilter";


const TransactionIdModal = ({ isOpen, onClose, transactionId }) => {

  const handleClickOutside = (event) => {
    if (event.target.className.includes("modal-overlay")) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleClickOutside);
    } else {
      window.removeEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="rounded bg-white p-4">
        <h2 className="mb-4 text-xl font-bold">Transaction ID</h2>
        <p>{transactionId}</p>
        <button className="mt-4 rounded bg-blue-500 px-4 py-2 text-white" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

const TransactionTable = ({ transactionData, setTransactionData }) => {
  const [selectedEntry, setSelectedEntry] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const transaction = useSelector((state) => state.modalOverlay.states.selectedTransaction);
  const transactionSearch = useSelector((state) => state.modalOverlay.states.transactionSearch);

  const transactionDataFiltered = filterTransactions(
    transactionData,
    transaction,
    transactionSearch
  );

  const [openSelect, setOpenSelect] = useState(false);

  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  const { currentData, totalPages } = paginateData(transactionDataFiltered, currentPage, selectedEntry);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleTransactionIdClick = (id) => {
    setSelectedTransactionId(id);
    setIsModalOpen(true);
  };


  const maxEntries = Math.min(transactionDataFiltered.length, 50); 
  const entryOptions = Array.from({ length: maxEntries / 10 }, (_, i) => (i + 1) * 10); 

  return (
    <div className="mx-auto flex w-full flex-col rounded-[10px] bg-primary py-4">
    <div className="w-full">
      <TransactionsNavbar />
      <div className="grid w-full grid-cols-8 space-x-3 bg-[#E8E8E8] px-6 py-4 text-sm font-medium text-[#181818]">
        <span>Transaction ID</span>
        <span>Project ID</span>
        <span>Service Name</span>
        <span>Consumer</span>
        <span>Vendor</span>
        <span>Amount</span>
        <span>Commission</span>
        <span>Date</span>
      </div>

      {currentData.map((value) => (
        <div key={value?.id}>
          <div className="grid w-full grid-cols-8 space-x-3 px-6 py-4">
            <span
              className="w-[100px] cursor-pointer"
              onClick={() => handleTransactionIdClick(value?.transaction_id)}
              title="Click to view full transaction ID"
            >
              <span className="truncate">
                {(value?.transaction_id || "-").slice(0, 8) + "..."}
              </span>
            </span>
            <span className="whitespace-pre-wrap break-words">{value?.project_id || "-"}</span>
            <span className="whitespace-pre-wrap break-words">
              {value?.service_name || "-"}
            </span>
            <span className="whitespace-pre-wrap break-words">
              {value?.consumer_name || ""}
            </span>
            <span className="whitespace-pre-wrap break-words">{value?.vendor_name || "-"}</span>
            <span className="">{value?.amount.toLocaleString() || ""}</span>
            <span className="">{value?.commission || "-"}</span>
            <span className="">{formatDate(value?.transaction_date)}</span>
          </div>
          <hr color="#AEAEAE" className="w-full" />
        </div>
      ))}

      {transactionDataFiltered.length === 0 && (
        <div className="flex w-full items-center justify-center pt-4 text-xl text-secondary">
          No search results available for query
        </div>
      )}
      <div className="flex w-full justify-between px-6 pt-3">
        <div className="flex w-full">
          <div className="relative flex items-center gap-x-1">
            <span>Showing</span>
            <div
              onClick={handleSelect}
              className="flex w-fit items-center gap-x-4 px-5 text-base font-normal text-[#181818] hover:cursor-pointer"
            >
              <span>{selectedEntry}</span>
              <img
                src={Dropdown}
                alt="open"
                className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
              />
            </div>
            {openSelect && (
              <div className="absolute bottom-[100%] left-[40%] w-[30%] animate-slide_up  rounded-[10px] bg-primary px-3 py-2">
                <div className="flex flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
                  {entryOptions.map((entry) => (
                    <span
                      key={entry}
                      className="hover:cursor-pointer"
                      onClick={() => {
                        setSelectedEntry(entry);
                        setOpenSelect(false); 
                      }}
                    >
                      {entry}
                    </span>
                  ))}
                </div>
              </div>
            )}
            <span>entries</span>
          </div>
        </div>
        <div>
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
        </div>
      </div>

      <TransactionIdModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        transactionId={selectedTransactionId}
      />
    </div>
  </div>
  );
};

export default TransactionTable;
