import React from "react";
import Navbar from "../../_components/ui/navbar";
import SideBar from "../../_components/ui/sidebar";
import AlertContent from "./_components/alert-content";
import { useSelector } from "react-redux";
import DisputeDashboard from "./_components/dispute-dashboard";

const VendorDashboardAlerts = () => {
  const disputeDashboard = useSelector((state) => state?.overlay.displayStates.disputeDashboard);
  return (
    <div>
      <Navbar />
      <SideBar />
      {disputeDashboard ? <DisputeDashboard /> : <AlertContent />}
    </div>
  );
};

export default VendorDashboardAlerts;
