import React, { useState } from "react";
import { Button } from "../../../../../../components/ui";
import ExtendForm from "./extendForm";

const ProjectFormsModals = ({ setShowModal }) => {
  const [showFormModal, setShowFormModal] = useState(1);
  const handleNextModal = () => {
    setShowFormModal(showFormModal + 1);
  };
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-50 md:w-[40%] w-full flex-col rounded-md bg-white mx-5 px-7 py-6">
        <span className="cursor-pointer relative left-[35vw] font-medium" onClick={() => setShowModal(false)}>
          {" "}
          X
        </span>

        {showFormModal === 1 && (
          <div>
            <h3 className="md:text-[20px] text-[18px] font-semibold">We apologize for the inconvenience. </h3>
            <p className="mb-4 md:text-[18px] text-[16px] leading-[27px]">
              Do you want to extend the delivery date or raise a dispute for resolution?
            </p>
            <div className="mt-16  flex gap-2">
              <Button
              className='text-[14px]'
                children="Extend delivery date"
                backgroundColor="#FFFFFF"
                textColor="#0096C1"
                onClick={handleNextModal}
              />
              <Button children="Raise dispute" className='text-[14px]'/>
            </div>
          </div>
        )}
        {showFormModal === 2 && <ExtendForm />}
      </div>
    </div>
  );
};

export default ProjectFormsModals;
