import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  states: {
    accessToken: null,
    userType: null,
    userName: null,
  },
};
export const tokenSlice = createSlice({
  name: "token",
  initialState: initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.states.accessToken = action.payload;
    },
    setUserType: (state, action) => {
      state.states.userType = action.payload;
    },
    setUserName: (state, action) => {
      state.states.userName = action.payload;
    },
    clearAccessToken(state) {
      state.accessToken = null;
    },
  },
});

export const { setAccessToken, clearAccessToken, setUserType, setUserName } = tokenSlice.actions;
