import React from "react";
import Navbar from "../../_components/ui/navbar";
import SideBar from "../../_components/ui/sidebar";
import DisputeDashboard from "../alerts/_components/dispute-dashboard";

const VendorDashboardDispute = () => {
  return (
    <div className="relative">
      <Navbar />
      <SideBar />
      <DisputeDashboard />
    </div>
  );
};

export default VendorDashboardDispute;
