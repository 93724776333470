import React, { useState } from "react";
import { Button } from "../../../../../../components/ui";
import AddBankModal from "../add-bank_modal";
import { editIcon, rightarrow } from "../../../../../../constants/images";

const EarningsContent = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [bankDetails, setBankDetails] = useState();
  const [editMode, setEditMode] = useState(false);

  const handleModalClose = (e) => {
    setShowBankDetails(e);
    setEditMode(false);
  };

  const truncateAccountNumber = (accountNo) => {
    return `${"*".repeat(accountNo.length - 4)}${accountNo.slice(-4)}`;
  };

  const handleAddBank = (details) => {
    setBankDetails(details);
  };

  const handleEditBank = () => {
    setShowBankDetails(true);
    setEditMode(true);
  };

  return (
    <div className="relative">
      {showBankDetails && (
        <div className="absolute inset-0 z-50 h-[100vh] bg-black bg-opacity-25" />
      )}
      {showBankDetails && (
        <AddBankModal
          handleModalClose={handleModalClose}
          handleBankDetails={handleAddBank}
          editMode={editMode}
          previousBankDetails={bankDetails}
        />
      )}
      <div className="relative mr-0 h-screen w-full max-w-full items-start justify-center pl-0 pr-0 pt-10 md:float-right md:mx-auto md:ml-auto md:w-[81%] md:pt-20">
        {showTransaction && (
          <div>
            <img
              src={rightarrow}
              onClick={() => setShowTransaction(false)}
              alt="back"
              className="absolute top-12 w-[100px] -rotate-180 cursor-pointer md:top-20"
            />
          </div>
        )}
        {!showTransaction && (
          <div className="px-6 pt-12 md:px-20 md:pt-20">
            <div className="text-start text-xl font-medium text-secondary md:text-2xl">
              Earnings
            </div>
            <div className="flex w-full border-[#AEAEAE] py-4 text-xs font-medium md:mt-10 md:w-5/6 md:border-b-[0.5px] md:border-t-[0.5px] md:px-0 md:text-base">
              <button
                className={`w-full rounded-[5px] px-4 py-2 md:w-[40%] ${activeIndex === 0 ? "bg-[#0096C1] text-white" : "bg-white text-[#5F5F5F]"}`}
                onClick={() => setActiveIndex(0)}
              >
                Overview
              </button>
              <button
                className={`w-full rounded-[5px] px-4 py-2 md:w-[40%] ${activeIndex === 1 ? "bg-[#0096C1] text-white" : "bg-white text-[#5F5F5F]"}`}
                onClick={() => setActiveIndex(1)}
              >
                Withdrawal method
              </button>
            </div>
            {activeIndex === 0 && (
              <div className="mt-8 w-full space-y-8 md:mt-16 md:w-1/2">
                <div className="flex flex-col gap-y-2 rounded-[5px] bg-lightblue px-6 py-2 text-primary">
                  <div className="text-base font-normal">Available balance</div>
                  <div className="flex w-full items-center  justify-between">
                    <span className="text-[35px] font-semibold">₦10,000</span>
                    <span className="h-fit bg-primary px-[10px] py-[5px] text-center text-sm font-medium text-secondary">
                      Get paid
                    </span>
                  </div>
                </div>
                <hr color="#AEAEAE" className="w-full" />

                <div className="flex w-full flex-col justify-between gap-y-4 md:flex-row md:items-center">
                  <div className="flex flex-col gap-y-1">
                    <span className="text-sm font-normal text-[#181818] md:text-base">
                      Pending projects
                    </span>
                    <span className="text-xl font-semibold text-secondary md:text-[30px]">
                      ₦20,000
                    </span>
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <span className="text-sm font-normal text-[#181818] md:text-base">
                      Payment in progress
                    </span>
                    <span className="text-xl font-semibold text-secondary md:text-[30px]">₦0</span>
                  </div>
                </div>
              </div>
            )}

            {activeIndex === 1 && (
              <div className="mt-8 w-full space-y-8 md:mt-16">
                <div className="flex flex-col items-start gap-x-8 gap-y-14 md:flex-row">
                  {!bankDetails ? (
                    <Button
                      backgroundColor="transparent"
                      textColor="#0096C1"
                      className="w-full md:w-[40%]"
                      onClick={() => setShowBankDetails(!showBankDetails)}
                    >
                      Add Bank details
                    </Button>
                  ) : (
                    <div className="flex md:w-[40%] w-full justify-between ">
                      <div className="flex items-center gap-x-2">
                        <div>
                          <img src={bankDetails?.logo} alt="" className="w-[30px] md:w-[40px]" />
                        </div>
                        <div className="flex  flex-col">
                          <span className="text-base font-medium capitalize text-lightgray">
                            {bankDetails?.holdersName}
                          </span>
                          <span className="text-base font-medium text-[#181818]">
                            {truncateAccountNumber(bankDetails?.accountNo)}
                          </span>
                        </div>
                      </div>
                      <div className="cursor-pointer">
                        <img src={editIcon} alt="edit" onClick={handleEditBank} />
                      </div>
                    </div>
                  )}
                  <div className="hidden h-[50vh] w-[1px] bg-[#a3a3a3] md:block" />
                  <hr color="#AEAEAE" className="block w-full md:hidden" />
                  <div className="space-y-4 md:w-1/2 w-full md:space-y-0">
                    <span className="text-lg font-medium text-secondary">Transactions</span>
                    {/* <div className="mx-auto flex w-full items-center justify-center text-base font-normal text-[#181818] md:h-[50vh]">
                    You have no transaction history yet
                  </div> */}
                    <div
                      className="w-full cursor-pointer space-y-2 pt-4"
                      onClick={() => setShowTransaction(true)}
                    >
                      <span className="text-base font-semibold text-secondary">Dec 2023</span>
                      <div className="flex w-full items-end justify-between">
                        <div className="flex w-full flex-col">
                          <span className="text-lightgray">Ref ID: 12345678</span>
                          <span className="text-sm">26 Dec, 09:10</span>
                        </div>
                        <div className="text-base font-medium text-lightgray">₦25,000</div>
                      </div>
                      <hr color="" className="mt-6" />
                    </div>
                    <div className="w-full cursor-pointer pt-4">
                      <span className="text-base font-semibold text-secondary">Dec 2023</span>
                      <div className="flex w-full items-end justify-between">
                        <div className="flex w-full flex-col">
                          <span className="text-lightgray">Ref ID: 12345678</span>
                          <span className="text-sm">26 Dec, 09:10</span>
                        </div>
                        <div className="text-base font-medium text-lightgray">₦25,000</div>
                      </div>
                      <hr color="" className="mt-6" />
                    </div>
                    <div className="w-full cursor-pointer pt-4">
                      <span className="text-base font-semibold text-secondary">Dec 2023</span>
                      <div className="flex w-full items-end justify-between">
                        <div className="flex w-full flex-col">
                          <span className="text-lightgray">Ref ID: 12345678</span>
                          <span className="text-sm">26 Dec, 09:10</span>
                        </div>
                        <div className="text-base font-medium text-lightgray">₦25,000</div>
                      </div>
                      <hr color="" className="mt-6" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {showTransaction && (
          <div className="px-6 pt-12 md:px-20 md:pt-20">
            <div className="flex flex-col gap-y-3">
              <span className="text-center text-lg font-medium text-secondary md:text-start md:text-2xl">
                Transaction details
              </span>
              <div className="flex flex-col gap-y-2 bg-[#F4FDFF] md:w-[40%]">
                <div className="flex w-full items-center  justify-between px-4 py-2">
                  <span className="ext-sm font-normal text-lightgray md:text-base">
                    Reference ID
                  </span>
                  <span className="font-medium text-secondary md:text-lg">12345678</span>
                </div>
                <div className="flex w-full items-center  justify-between px-4 py-2">
                  <span className="ext-sm font-normal text-lightgray md:text-base">Date</span>
                  <span className="font-medium text-secondary md:text-lg">26 Dec, 2023</span>
                </div>
                <div className="flex w-full items-center  justify-between px-4 py-2">
                  <span className="text-sm font-normal text-lightgray md:text-base">Amount</span>
                  <span className="font-medium text-secondary md:text-lg">₦25,000</span>
                </div>
                <div className="flex w-full items-center  justify-between px-4 py-2">
                  <span className="ext-sm md:text-basefont-normal text-lightgray">Type</span>
                  <span className="font-medium text-secondary md:text-lg">Withdrawal</span>
                </div>
                <div className="flex w-full items-center  justify-between px-4 py-2">
                  <span className="ext-sm md:text-basefont-normal text-lightgray">
                    Receiving account
                  </span>
                  <span className="font-medium text-secondary md:text-lg">....5678</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EarningsContent;
