import React from "react";
import Navbar from "../../_components/ui/navbar";
import SideBar from "../../_components/ui/sidebar";
import LegalContent from "./_components/main-content";


const VendorDashboardLegal = () => {
  return (
    <div>
      <Navbar />
      <SideBar />
      <LegalContent />
    </div>
  );
};

export default VendorDashboardLegal;
