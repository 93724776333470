export const notificationdata = [
  {
    id: 1,
    days: "Today",
    notifications: [
      {
        heading: "New Message",
        conntent:
          "You've received a new message from John, your selected plumber. Tap here to view and respond.",
      },
      {
        heading: "Project Update",
        conntent:
          "Your graphic design project with Mary is now in progress. Check the latest updates in the project page",
      },
    ],
  },
  {
    id: 2,
    days: "Yesterday",
    notifications: [
      {
        heading: "New Message",
        conntent:
          "You've received a new message from John, your selected plumber. Tap here to view and respond.",
      },
      {
        heading: "Project Update",
        conntent:
          "Your graphic design project with Mary is now in progress. Check the latest updates in the project page",
      },
      {
        heading: "Service Completion",
        conntent:
          "Alex has completed the home repair service. Review and accept the service to release the payment",
      },
    ],
  },
  {
    id: 3,
    days: "This week",
    notifications: [
      {
        heading: "New Message",
        conntent:
          "You've received a new message from John, your selected plumber. Tap here to view and respond.",
      },
    ],
  },
];


