import React, { useState } from "react";
import { BellIcon, Notify, ProfileImage, SearchIcon } from "../../../constants/images";
import { Notifications } from "../../../constants";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const userType = useSelector((state) => state.token.states.userType);
  const userName = useSelector((state) => state.token.states.userName);

  const handleBellClick = () => {
    setShowNotifications(!showNotifications);
  };
  
  return (
    <div className="flex w-full">
      <div className="relative ml-16 flex w-3/4 items-center justify-center">
        <input
          type="text"
          name="search"
          id="search"
          className="w-1/2 rounded-[5px] border border-[#AEAEAE] px-10 py-2 placeholder:text-xs placeholder:font-medium placeholder:text-[#AEAEAE] md:placeholder:text-sm "
          placeholder="Search"
        />{" "}
        <img
          src={SearchIcon}
          alt="search"
          className="absolute left-[25%] px-2"
        />
      </div>

      <div className="ml-auto flex w-fit items-center gap-x-3 px-8">
        <div className="relative z-20">
          <img
            src={BellIcon}
            alt="notifications"
            className="cursor-pointer"
            onClick={handleBellClick}
          />
          <div className="absolute bottom-[16px] left-[10px] flex h-[20px] w-[20px] items-center justify-center rounded-[50%] bg-lightblue font-semibold text-primary">
            5
          </div>
          {showNotifications && (
            <div className="absolute border border-[#AEAEAE] rounded-[5px] right-[20%] w-[400px] animate-slide_right bg-[#eeeeee]">
              <div className="flex w-full flex-col py-6">
                <div className="flex w-full px-4 justify-between">
                  <span className="text-lg font-semibold text-secondary">
                    Notifications
                  </span>
                  <span className="text-sm font-normal text-[#181818]">
                    5 unread
                  </span>
                </div>
                <div className="flex flex-col">
                  {Notifications.map((value) => (
                    <div className="flex flex-col hover:cursor-pointer " key={value.id}>
                      <div className="flex py-2 px-4 items-center hover:bg-primary transition-all ease-in gap-x-4">
                        <img src={Notify} alt="notification" />
                        <div className="flex flex-col">
                          <span className="text-base font-medium text-[#181818]">
                            {value.title}
                          </span>
                          <span className="text-base font-medium text-lightgray">
                            {value.content}
                          </span>
                        </div>
                      </div>
                      <hr className="w-full my-2" color="#AEAEAE" />
                    </div>
                  ))}
                </div>
              </div>
              <Link to='/admin/notifications' className="shadow-xl text-base font-medium text-[#181818] bg-primary w-full flex items-center justify-center py-2">
                View all
              </Link>
            </div>
          )}
        </div>
        <div className="flex items-center gap-x-2">
          <img
            src={ProfileImage}
            className="w-[50px] rounded-[50%]"
            alt="profile image"
          />
          <div className="flex flex-col">
            <span className="text-sm font-semibold text-[#181818] md:text-base">
             {userName !== null && userName}
            </span>
            <span className="text-xs font-normal text-lightgray md:text-sm">
              {userType}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
