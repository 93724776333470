import React, { useState } from "react";
import { graynotice } from "../../../../../../constants/images";
import { Button } from "../../../../../../components/ui";

const SelectedAlert = ({ selectedId, handleModal, onClick }) => {
  const handleClose = () => {
    handleModal(true);
  };
  return (
    <div className="fixed bottom-[0%] left-0 right-0 z-[999] block w-full md:animate-none animate-slide_up rounded-[5px] rounded-tl-[30px] rounded-tr-[30px] bg-primary px-4 py-8 md:relative md:top-[35%] md:px-10 md:py-12">
      <div
        className="absolute right-[23px] top-2 text-xl font-medium text-[#181818] hover:cursor-pointer md:top-[15px]"
        onClick={handleClose}
      >
        x
      </div>

      <div className="flex flex-col gap-y-3 md:py-0 py-4">
        <span className="text-secondary text-center font-semibold text-lg md:hidden">Dispute Resolution</span>
        <span className="text-lg font-semibold text-lightblue md:text-xl">
          Dispute opened for "Plumbing service for my bathroom"
        </span>
        <span className="text-base font-normal text-[#181818]">
          The consumer Tim Mark has opened a dispute on the job you recently completed for him{" "}
          <br />
          <br /> Here is the details of the consumer's reason for the dispute
        </span>
        <div className="my-2 mb-4 bg-[#efefef] px-2 py-2">
          <span className="md:text-base text-sm font-medium text-[#181818]">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti voluptates similique
            eius, sunt error minima esse obcaecati illum ducimus iure fugit velit molestiae
            exercitationem dolore nam asperiores, vero aut veniam! Aliquam magnam veniam sapiente
            earum nisi vero porro dolorem aperiam eveniet, dolor doloremque totam odit quam sunt ex
            eligendi! Rerum praesentium ipsa eos molestiae fuga ducimus corporis magnam unde?
            Reiciendis!
          </span>
        </div>
        <span className="text-base font-medium text-secondary">
          We would like to hear your side of the story and gather any supporting information you may
          have regarding the matter{" "}
        </span>
        <div className="flex items-center gap-2 py-2">
          <img src={graynotice} alt="notice" />
          <span className="text-sm font-normal text-lightgray">
            Dispute opened for "Plumbing service for my bathroom"
          </span>
        </div>
        <div className="mt-3 w-full">
          <Button className="w-full" onClick={onClick}>Respond</Button>
        </div>
      </div>
    </div>
  );
};

export default SelectedAlert;
