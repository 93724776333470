import React, { useState } from "react";
import ProjectDeliveryExtention from "./cards/project_delivery_extention";
import Container from "../../container";
import { Button } from "../../../../components/ui";
import { sucessMark } from "../../../../constants/images";
import General_Modal from "../shared/modals";
import ReuseAbleSucessCard from "../../reuseAbleSuceesCard";
import DeclineCard from "./cards/declineCard";

const ProjectExtention = () => {
  const [showModal, setShowModal] = useState(null);

  const handleOnClose = () => setShowModal(null);
  return (
    <Container>
      <div className="px-8 py-20">
        <div className="flex flex-col md:flex-row md:space-x-8">
          <div className="w-full md:w-[70%]">
            <ProjectDeliveryExtention />
          </div>
          <div className="w-full md:w-[30%]">
            <h3 className="left-5 text-base md:text-lg">
              Do you approve this project delivery date extension?
            </h3>
            <div className="mt-16 flex space-x-3 md:mt-7">
              <Button
                children="No"
                onClick={() => setShowModal("decline")}
                backgroundColor="#ffff"
                textColor="#0096C1"
                className="w-full"
              />
              <Button children="Yes" className="w-full" onClick={() => setShowModal("aprrove")} />
            </div>
          </div>
        </div>
        {showModal === "aprrove" && (
          <General_Modal onClose={handleOnClose} width="35%">
            <ReuseAbleSucessCard
              button="Track project"
              image={sucessMark}
              heading="You have approved the delivery date extension"
              message="The new delivery date for the service 'Plumbing service for my bathroom' is now December 26, 2023."
              setShowModal={setShowModal}
              //onClick can be added here i have make it dynamic from the button
            />
          </General_Modal>
        )}
        {showModal === "decline" && (
          <General_Modal onClose={handleOnClose} width="40%">
            <DeclineCard />
          </General_Modal>
        )}
      </div>
    </Container>
  );
};

export default ProjectExtention;
