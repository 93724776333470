import React, { useState } from "react";
import {
  deleteIcon,
  dropdown,
  editIcon,
  locationBlue,
  profile,
  starRate,
  uploadIcon,
} from "../../../../../../constants/images";
import { Clock } from "../../../../../../admin/constants/images";
import { useDispatch } from "react-redux";
import { setEditServices } from "../../../../../../features/overlay";

const ProfileTabs = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Service");
  const [openSort, setOpenSort] = useState(false);
  const tabs = [
    { id: 1, name: "Service" },
    { id: 2, name: "Description" },
    { id: 3, name: "Catalog" },
    { id: 4, name: "Reviews" },
  ];
  const [uploadedImages, setUploadedImages] = useState([]);
  const [imageIds, setImageIds] = useState(1);

  const handleDeleteImage = (id) => {
    const updatedImages = uploadedImages.filter((image) => image.id !== id);
    setUploadedImages(updatedImages);
  };

  const handleUploadImage = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
    const fileSizeLimit = 5 * 1024 * 1024; // 5MB

    if (!allowedExtensions.includes(file.type.split("/")[1].toLowerCase())) {
      alert("Only images are allowed.");
      return;
    }

    if (file.size > fileSizeLimit) {
      alert("File size exceeds 5MB limit.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const newImage = {
        image: e.target.result,
        id: imageIds,
      };
      setImageIds(imageIds + 1);
      setUploadedImages((prevImages) => [...prevImages, newImage]);
    };
    reader.readAsDataURL(file);
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const handleEditService = () => {
    dispatch(setEditServices(true));
  };

  return (
    <div className="right-10 flex w-full flex-col px-6 pt-10 md:absolute md:w-[60%]">
      <hr color="#AEAEAE" className="w-full md:hidden" />

      <div className="flex w-full justify-between gap-x-5 py-2 text-sm font-medium text-[#181818] md:text-lg">
        {tabs.map((tab) => (
          <span
            key={tab.id}
            className={`cursor-pointer ${activeTab === tab.name && "underline decoration-lightblue decoration-2 underline-offset-[14px]"} font-semibold text-lightgray`}
            onClick={() => handleTabChange(tab.name)}
          >
            {tab.name}
          </span>
        ))}
      </div>
      <hr color="#AEAEAE" className="w-full" />

      <div className="mt-5">
        {(() => {
          switch (activeTab) {
            case "Service":
              return (
                <div className="flex flex-col gap-y-3">
                  <div className="flex w-full justify-between text-base font-semibold text-secondary md:text-xl">
                    <span>Johnny Plumbing Service</span>
                    <img src={editIcon} alt="edit services" className="cursor-pointer" onClick={handleEditService} />
                  </div>
                  <div className="mb-5 flex w-full flex-col justify-between gap-y-5 md:mb-10 md:flex-row md:gap-y-0">
                    <div className="flex flex-col gap-y-1">
                      <span className="text-xs font-medium text-secondary md:text-sm">
                        Business hours
                      </span>
                      <div className="flex items-center gap-x-2">
                        <div>
                          <img src={Clock} alt="" />
                        </div>
                        <div className="text-xs font-normal text-secondary md:text-sm">
                          Mon - Fri:
                        </div>
                        <div className="text-sm font-semibold text-secondary md:text-base">
                          10:00am - 8:00pm
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <div>
                          <img src={Clock} alt="" />
                        </div>
                        <div className="text-xs font-normal text-secondary md:text-sm">
                          Sat - Sun:
                        </div>
                        <div className="text-sm font-semibold text-secondary md:text-base">
                          12:00pm - 9:00pm
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="text-xs font-medium text-secondary md:text-sm">Address</span>
                      <div className="flex items-center gap-x-2">
                        <div>
                          <img src={locationBlue} alt="" />
                        </div>
                        <div className="text-sm font-semibold text-secondary md:text-base">
                          No 6, Adeniyi close, Yaba, Lagos
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full flex-col gap-y-2 md:w-1/2 md:gap-y-3">
                    <div className="flex justify-between">
                      <span className="text-xs font-normal text-lightgray md:text-sm">
                        Category
                      </span>
                      <span className="text-sm font-medium text-secondary md:text-base">
                        Repair and maintenance
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs font-normal text-lightgray md:text-sm">
                        Specialty
                      </span>
                      <span className="text-sm font-medium text-secondary md:text-base">
                        Plumbing
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs font-normal text-lightgray md:text-sm">
                        Years of experience
                      </span>
                      <span className="text-sm font-medium text-secondary md:text-base">
                        5 years
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-xs font-normal text-lightgray md:text-sm">
                        Base price rate
                      </span>
                      <span className="text-sm font-medium text-secondary md:text-base">
                        ₦10,000
                      </span>
                    </div>
                  </div>
                </div>
              );
            case "Description":
              return (
                <div className="text-sm font-normal text-secondary md:text-lg">
                  Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie,
                  dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus
                  sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget
                  condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora
                  torquent per conubia nostra, per inceptos himenaeos.
                </div>
              );
            case "Catalog":
              return (
                <div className="w-full py-10">
                  <div className="relative grid w-full grid-cols-2 gap-8">
                    {uploadedImages.map((item) => (
                      <div key={item.id} className="relative w-full">
                        <img
                          src={item.image}
                          alt="catalog"
                          className="h-full w-full object-cover"
                        />
                        <img
                          src={deleteIcon}
                          alt="delete"
                          className="absolute bottom-4 right-4 cursor-pointer"
                          onClick={() => handleDeleteImage(item.id)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="absolute bottom-10 right-10 flex w-full items-end justify-end md:relative">
                    <label htmlFor="upload">
                      <img
                        src={uploadIcon}
                        alt="upload image"
                        className="w-[50px] transition-transform ease-in hover:scale-75 hover:cursor-pointer md:w-[80px]"
                      />
                    </label>
                    <input
                      type="file"
                      className="hidden w-[0px]"
                      id="upload"
                      onChange={handleUploadImage}
                    />
                  </div>
                </div>
              );
            case "Reviews":
              return (
                <div className="flex flex-col gap-y-10 pb-10 pt-5">
                  <div className="relative">
                    <div
                      className="flex w-[140px] cursor-pointer items-center justify-between rounded-[20px] border border-[#AEAEAE] px-5 py-2"
                      onClick={() => setOpenSort(!openSort)}
                    >
                      <span className="text-sm font-medium text-lightgray md:text-base">
                        Sort by
                      </span>
                      <img
                        src={dropdown}
                        alt="sort"
                        className={`${openSort && "rotate-180 transition-all ease-in"}`}
                      />
                    </div>

                    {openSort && (
                      <div className="absolute top-14 w-[140px]">
                        <div className="flex flex-col items-center justify-center gap-y-2 bg-primary text-base font-medium">
                          <span>Date</span>
                          <hr color="#181818" className="w-full" />
                          <span>Date</span>
                          <hr color="#181818" className="w-full" />
                          <span>Date</span>
                          <hr color="#181818" className="w-full" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-y-4">
                    <div className="flex w-full justify-between">
                      <div className="flex items-center gap-x-3">
                        <img
                          src={profile}
                          className="w-[40px] rounded-[50%] md:w-[50px]"
                          alt="profile"
                        />
                        <div className="flex flex-col gap-y-1">
                          <span className="text-sm font-semibold text-secondary md:text-lg">
                            Mary Joy
                          </span>
                          <span className="text-xs font-normal text-lightgray md:text-sm">
                            Nov 21, 2023 - Nov 25, 2023
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <img src={starRate} alt="rating" />
                        <span className="text-sm font-semibold text-[#181818]">4.8</span>
                      </div>
                    </div>
                    <div className="text-xs font-normal text-[#181818] md:text-lg">
                      I recently hired John for a plumbing issue at my home, and I couldn't be more
                      satisfied with his service. He responded promptly to my request, arrived on
                      time, and quickly identified and fixed the problem.
                    </div>
                  </div>

                  <div className="flex flex-col gap-y-4">
                    <div className="flex w-full justify-between">
                      <div className="flex items-center gap-x-3">
                        <img
                          src={profile}
                          className="w-[40px] rounded-[50%] md:w-[50px]"
                          alt="profile"
                        />
                        <div className="flex flex-col gap-y-1">
                          <span className="text-sm font-semibold text-secondary md:text-lg">
                            Mary Joy
                          </span>
                          <span className="text-xs font-normal text-lightgray md:text-sm">
                            Nov 21, 2023 - Nov 25, 2023
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <img src={starRate} alt="rating" />
                        <span className="text-sm font-semibold text-[#181818]">4.8</span>
                      </div>
                    </div>
                    <div className="text-xs font-normal text-[#181818] md:text-lg">
                      I recently hired John for a plumbing issue at my home, and I couldn't be more
                      satisfied with his service. He responded promptly to my request, arrived on
                      time, and quickly identified and fixed the problem.
                    </div>
                  </div>

                  <div className="flex flex-col gap-y-4">
                    <div className="flex w-full justify-between">
                      <div className="flex items-center gap-x-3">
                        <img
                          src={profile}
                          className="w-[40px] rounded-[50%] md:w-[50px]"
                          alt="profile"
                        />
                        <div className="flex flex-col gap-y-1">
                          <span className="text-sm font-semibold text-secondary md:text-lg">
                            Mary Joy
                          </span>
                          <span className="text-xs font-normal text-lightgray md:text-sm">
                            Nov 21, 2023 - Nov 25, 2023
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <img src={starRate} alt="rating" />
                        <span className="text-sm font-semibold text-[#181818]">4.8</span>
                      </div>
                    </div>
                    <div className="text-xs font-normal text-[#181818] md:text-lg">
                      I recently hired John for a plumbing issue at my home, and I couldn't be more
                      satisfied with his service. He responded promptly to my request, arrived on
                      time, and quickly identified and fixed the problem.
                    </div>
                  </div>
                </div>
              );
            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
};

export default ProfileTabs;
