import React, { useState } from "react";
import { Button } from "../../components";
import { setAccessToken, setUserName, setUserType } from "../../redux/slice/login";
import { Spinner, logo } from "../../constants/images";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dropdown } from "../../../constants/images";

const availablePositions = [
  {
    id: 0,
    value: "Super Admin",
  },
  {
    id: 1,
    value: "Dispute Resolution",
  },
  {
    id: 2,
    value: "Sales",
  },
  {
    id: 3,
    value: "ID Confirmation",
  },
  {
    id: 4,
    value: "Contractor",
  },
];

const AdminLogin = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPositions, setShowPositions] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState("Select a Position");
  const [details, setDetails] = useState({
    user_type: "",
    identifier: "",
    password: "",
  });
  const [errorState, setErrorState] = useState({
    usernameError: "",
    passwordError: "",
    userTypeError: "",
  });
  const [loginError, setLoginError] = useState();

  const roleMapping = {
    "Super Admin": "super_admin",
    "Dispute Resolution": "dispute_resolution",
    Sales: "sales",
    "ID Confirmation": "id_confirmation",
    Contractor: "contractor",
  };

  const handlePositionSelect = (position) => {
    setSelectedPosition(position);
    setDetails({ ...details, user_type: roleMapping[position] });
    setErrorState((prev) => ({ ...prev, positionError: "" }));
  };

  const validate = () => {
    let isError = false;
    const errors = {
      usernameError: "",
      passwordError: "",
      userTypeError: "",
    };

    if (!details.identifier) {
      isError = true;
      errors.usernameError = "Please enter your username";
    }
    if (!details.password) {
      isError = true;
      errors.passwordError = "Please enter your password";
    }
    if (selectedPosition === "Select a Position") {
      isError = true;
      errors.userTypeError = "Please select your position";
    }
    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorState({
      usernameError: "",
      passwordError: "",
      userTypeError: "",
    });
    const error = validate();
    if (!error) {
      setLoading(true);
      try {
        const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(details),
        });

        if (response.ok) {
          const data = await response.json();
          const userType = data.userType;
          const userName =
            data?.firstName && data?.lastName ? `${data.firstName} ${data.lastName}` : null;
          const accessToken = data.accessToken;
          dispatch(setUserType(userType));
          dispatch(setUserName(userName));
          dispatch(setAccessToken(accessToken));
          if (userType === "Super Admin") {
            navigate("dashboard");
          } else if (userType === "Dispute Resolution") {
            navigate("dispute&resolution");
          } else if (userType === "Sales" || userType === "ID Confirmation") {
            navigate("business&suite");
          } else if (userType === "Contractor") {
            navigate("registered_vendors");
          } else {
          }
          setLoading(false);
        } else if (response.status === 400) {
          setLoginError("Invalid identifier format: Please enter a valid username");
          setLoading(false);
        } else if (response.status === 401) {
          setLoginError("Unauthorized: No matching user found");
          setLoading(false);
        } else if (response.status === 500) {
          setLoginError("Database error, please try again");
          setLoading(false);
        } else {
          console.error("Login failed", response.status);
          setLoading(false);
        }
      } catch (error) {
        console.error("Login failed", error);
      }
    }
  };

  return (
    <div className="relative">
      <div className="absolute left-[7%] top-[5%]">
        <img src={logo} alt="gywde logo" />
      </div>
      <div className="flex h-screen items-center justify-center  bg-secondary">
        <div className="mx-8 flex h-fit w-full flex-col rounded-[5px] bg-primary px-4 py-4 md:mx-auto md:w-1/2 lg:px-6 lg:py-8">
          <span className="text-center font-semibold text-secondary lg:text-[30px]">
            Log in to your dashboard
          </span>
          <form className="space-y-5 pt-3">
            <div className="mx-auto flex w-5/6 flex-col gap-y-1">
              <label htmlFor="username" className="text-sm font-normal text-[#181818] lg:text-base">
                Username
              </label>
              <input
                type="text"
                name="identifier"
                id="identifier"
                onChange={handleForm}
                className="rounded-[5px] border border-[#181818] px-3 py-4"
              />
              <span className="text-xs text-[#e62e2e]">{errorState.usernameError}</span>
            </div>
            <div className="mx-auto flex w-5/6 flex-col gap-y-1">
              <label htmlFor="password" className="text-sm font-normal text-[#181818] lg:text-base">
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                onChange={handleForm}
                className="rounded-[5px] border border-[#181818] px-3 py-4"
              />
              <span className="text-xs text-[#e62e2e]">{errorState.passwordError}</span>
            </div>
            <div className="mx-auto flex w-5/6 flex-col gap-y-1">
              <label htmlFor="position" className="text-base font-normal text-[#181818]">
                Position
              </label>
              <div
                className="relative flex h-[60px] w-full rounded-[5px] border border-[#181818] px-3 py-4"
                onClick={() => setShowPositions(!showPositions)}
              >
                <div className="flex w-full items-center justify-between">
                  <span className="text-base font-normal">{selectedPosition}</span>
                  <img src={dropdown} alt="" />
                </div>
                {showPositions && (
                  <div className="absolute left-0 right-0 top-11 z-50 flex w-full animate-slide_up flex-col gap-y-2 divide-opacity-0 bg-primary py-4 drop-shadow-2xl">
                    {availablePositions.map((position) => (
                      <div
                        key={position.id}
                        onClick={() => handlePositionSelect(position.value)}
                        className="flex gap-x-3 px-3"
                      >
                        <input type="radio" name="position" id={position.value} />
                        <label
                          htmlFor={position.value}
                          className="text-base font-normal text-lightgray"
                        >
                          {position.value}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <span className="text-xs text-[#e62e2e]">{errorState.userTypeError}</span>
            </div>
            <div className="mx-auto flex w-5/6 flex-col items-center justify-center pt-10">
              <Button
                onClick={handleSubmit}
                className={"flex w-full items-center justify-center"}
                disabled={loading}
              >
                {loading ? <img src={Spinner} alt="loading" className="w-[5%]" /> : "Login"}
              </Button>
              {loginError !== "" && (
                <span className="pt-4 text-sm text-[#e62e2e]">{loginError}</span>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
