import React from 'react'
import { Navbar, DiscountCard } from './_components'

const DiscountsPage = ({allDiscount,fetchAllDiscount}) => {
  return (
    <div>
        <Navbar />
        <DiscountCard allDiscount={allDiscount} fetchAllDiscount={fetchAllDiscount}/>
    </div>
  )
}

export default DiscountsPage