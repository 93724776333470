import React from "react";
import Container from "../../../../container";
import {
  vendorRound,
  locationFilled,
  LikeUnfill,
  DisLikeUnfill,
  linkarrow,
} from "../../../../../../constants/images";
import { Button } from "../../../../../../components/ui";

import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";

const HiredVendorApplication = () => {
  const ratingChanged = (newRating) => {
   
    setRating(newRating);
  };

  const navigate = useNavigate();
  const jobApplication = {
    name: "John Doe",
    profession: "Plumber",
    location: "Yaba, Lagos",
    rating: 4.8,
    reviewCount: 31,
    coverLetter: `I can help with what you need. Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus.`,
    attachments: [
      { id: 1, url: "/images/attachment1.jpg" },
      { id: 2, url: "/images/attachment2.jpg" },
    ],
    vendorBid: "₦25,000",
    budget: "₦25,000",
    appliedDate: "26 Nov 2023",
  };

  return (
    <Container>
      <div className="py-20 md:px-10">
        <h2 className="text-center font-medium md:text-start md:text-[25px] ">Job application</h2>
        <div className="flex flex-col space-x-5 md:flex-row">
          <div className="mt-5 w-full rounded-md p-6 md:w-[80%] md:border-[1px] md:border-[#AEAEAE] md:shadow-md">
            <div className="flex items-start justify-between border-b-[1px] border-[#AEAEAE] pb-4">
              <div className="flex items-center space-x-4">
                <img src={vendorRound} alt="Vendor" className="h-12 w-12 rounded-full" />
                <div>
                  <p className="text-lg font-bold">{jobApplication.name}</p>
                  <p className="text-sm text-gray-600">{jobApplication.profession}</p>
                  <div className="mt-1 flex items-center space-x-1">
                    <img src={locationFilled} alt="Location" className="h-4 w-4" />
                    <p className="text-xs text-gray-500">{jobApplication.location}</p>
                    <p className="text-xs text-gray-500">

                    <ReactStars
            count={5}
            onChange={ratingChanged}
            size={40}
            activeColor="#ffd700"
            edit={false}
            value={3}
          /> {jobApplication.rating} ({jobApplication.reviewCount})

                    </p>
                  </div>
                </div>
              </div>
              <div className="flex space-x-4">
                <img src={LikeUnfill} alt="thumbs-up" className="h-6 w-6 cursor-pointer" />
                <img src={DisLikeUnfill} alt="thumbs-down" className="h-6 w-6 cursor-pointer" />
              </div>
            </div>

            <div className="mt-4">
              <h3 className="text-sm font-bold text-gray-700">Cover letter:</h3>
              <p className="mt-2 text-sm text-gray-600">{jobApplication.coverLetter}</p>
            </div>

            <div className="mt-4">
              <h3 className="text-sm font-bold text-gray-700">Attachments:</h3>
              <div className="mt-2 flex space-x-3">
                {jobApplication.attachments.map((attachment) => (
                  <img
                    key={attachment.id}
                    src={attachment.url}
                    alt={`Attachment ${attachment.id}`}
                    className="h-24 w-24 rounded-md border"
                  />
                ))}
              </div>
            </div>

            <div className="mt-5">
              <div className="mt-7 flex w-full cursor-pointer items-center justify-between rounded-[5px] border-[1px] border-[#AEAEAE] px-3 py-3 md:mt-12">
                <span className="text-[18px] text-black"> View vendor profile</span>
                <img src={linkarrow} alt="linkarrow" className="pl-2" />
              </div>
            </div>

            <div className="mt-6 flex h-fit grid-cols-3 flex-col gap-4 rounded-md px-4 py-4 md:hidden md:w-[20%] md:border-[1px] md:border-[#AEAEAE]">
              <div className="text-sm">
                <p className="text-gray-500">Your budget</p>
                <p className="font-medium text-black">{jobApplication.budget}</p>
              </div>
              <div className="text-sm">
                <p className="text-gray-500">Vendor's bid</p>
                <p className="font-medium text-black">{jobApplication.vendorBid}</p>
              </div>
              <div className="text-sm">
                <p className="text-gray-500">Applied</p>
                <p className="font-medium text-black">{jobApplication.appliedDate}</p>
              </div>
            </div>
            <div className="my-5 flex space-x-4">
              <Button
                children="Message"
                className="flex-1 rounded-md border-[1px]  py-2 "
                backgroundColor="white"
                textColor="#0096C1"
              />

              <Button
                children="Hire"
                className="flex-1 rounded-md  py-2"
                onClick={() => navigate("/jobs/hired-vendor")}
              />
            </div>
          </div>

          <div className="mt-6  hidden h-fit grid-cols-3 flex-col gap-4 rounded-md px-4 py-4 md:flex md:w-[20%] md:border-[1px] md:border-[#AEAEAE]">
            <div className="text-sm">
              <p className="text-gray-500">Your budget</p>
              <p className="font-medium text-black">{jobApplication.budget}</p>
            </div>
            <div className="text-sm">
              <p className="text-gray-500">Vendor's bid</p>
              <p className="font-medium text-black">{jobApplication.vendorBid}</p>
            </div>
            <div className="text-sm">
              <p className="text-gray-500">Applied</p>
              <p className="font-medium text-black">{jobApplication.appliedDate}</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HiredVendorApplication;
