import React from "react";
import { jobsData } from "../../data";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../../../container";
import ReactStars from "react-rating-stars-component";
import {
  linkarrow,
  locationFilled,
  vendorRound,
  projectchat,
} from "../../../../../constants/images";
const HireVendors = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const job = jobsData.find((job) => job.id === parseInt(id));
  const ratingChanged = (newRating) => {
    setRating(newRating);
  };
  return (
    <Container>
      <div className="py-16   md:px-10">
        <h2 className="my-5 text-center text-[25px] font-medium text-[#002D3A] md:text-start">
          Hired vendor
        </h2>
        {job?.applications.length ? (
          job?.applications.map((app, index) => (
            <div key={index} className="rounded-md px-6 py-5 md:mb-4 md:border-[1px]">
              <div className="flex flex-col justify-between md:flex-row md:items-center">
                <div className="flex items-center space-x-2">
                  <div>
                    <img src={vendorRound} alt="" />
                  </div>
                  <div>
                    <p className="text-[14px] font-bold md:text-lg">{app.name}</p>
                    <p className="text-[12px] md:text-[14px]">{app.profession}</p>
                  </div>
                </div>

                <div className="mt-3 flex items-center space-x-2">
                  <div>
                    <img src={locationFilled} alt="locationBlue" />
                  </div>
                  <p className="text-[12px] md:text-[16px]">{app.location}</p>
                  <p className="text-[12px] md:text-[16px]">
                    <ReactStars
                      count={1}
                      onChange={ratingChanged}
                      size={40}
                      activeColor="#ffd700"
                      edit={false}
                      value={1}
                    />{" "}
                    {app.rating} ({app.reviewCount})
                  </p>
                </div>

                <div className="hidden cursor-pointer rounded-sm border-[1px] border-[#0096C1] px-3 py-1 text-[#0096C1] md:block">
                  Message
                </div>
                <div className="cursor-pointer md:hidden">
                  <img src={projectchat} alt="projectchat" />
                </div>
              </div>

              <div className="mt-7 flex w-full cursor-pointer items-center justify-between rounded-[5px] border-[1px] border-[#AEAEAE] px-3 py-2 md:mt-12 ">
                <span className="text-[14px] text-black md:text-[18px]">View project details</span>
                <img src={linkarrow} alt="linkarrow" className="pl-2" />
              </div>
            </div>
          ))
        ) : (
          <div className=" flex flex-col items-center justify-center gap-5">
            <p className="text-center text-[20px]">No vendor hired yet</p>
            <div
              className="cursor-pointer rounded-sm border-[1px] border-[#0096C1] px-3 py-2 text-[16px] text-[#0096C1]"
              onClick={() => navigate("/jobs/hired-vendor-application")}
            >
              View applications
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default HireVendors;
