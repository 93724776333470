import React from "react";
import Container from "../../../../container";
import { Button } from "../../../../../../components/ui";

const HireVendor = () => {
  const jobDetails = {
    title: "Plumber needed to repair bathroom faucet",
    location: "Yaba, Lagos",
    date: {
      from: "20 Dec 2023",
      to: "23 Dec 2023",
    },
    budget: "₦25,000",
    description: `I need a plumber to work in my bathroom, Hor ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim.`,
    documentUrl: "/images/attachment1.jpg",
  };

  return (
    <Container>
      <div className="py-20 md:px-10">
        <h2 className="mb-8 text-[25px] font-medium md:text-start text-center">Hire Vendor</h2>
        <div className="grid grid-cols-1 gap-10 md:grid-cols-[2fr,1fr]">
          <div className="rounded-md md:border-[1px] border-[#AEAEAE] p-6 md:shadow-md">
            <h3 className="mb-4 text-xl font-bold">Service request</h3>
            <div className="space-y-4">
              <div>
                <p className="font-medium">Project Title</p>
                <p className="text-gray-600">{jobDetails.title}</p>
              </div>

              <div>
                <p className="font-medium">Location</p>
                <p className="cursor-pointer text-[#0096C1]">{jobDetails.location}</p>
              </div>

              <div>
                <p className="font-medium">Date</p>
                <p className="text-gray-600">
                  From: {jobDetails.date.from} <br />
                  To: {jobDetails.date.to}
                </p>
              </div>

              <div>
                <p className="font-medium">Budget</p>
                <p className="text-gray-600">{jobDetails.budget}</p>
              </div>

              <div>
                <p className="font-medium">Document</p>
                <img
                  src={jobDetails.documentUrl}
                  alt="Document"
                  className="h-24 w-24 rounded-md border"
                />
              </div>

              <div>
                <p className="font-medium">Description</p>
                <p className="text-gray-600">{jobDetails.description}</p>
              </div>

              <div className="flex space-x-4">
                <Button
                  children="Edit"
                  className="flex-1 rounded-md border-[1px] py-2"
                  backgroundColor="white"
                  textColor="#0096C1"
                />
                <Button children="Send to vendor" className="flex-1 rounded-md  py-2" />
              </div>
            </div>
          </div>

          <div className="rounded-md md:border-[1px] md:border-[#AEAEAE] p-6 md:shadow-md h-fit">
            <h3 className="mb-4 text-xl font-bold">Project timeline</h3>
            <ul className="space-y-4 text-gray-600">
              <li className="flex items-center space-x-3">
                <span className="h-4 w-4 rounded-full border-[2px] border-[#0096C1]"></span>
                <span>Vendor confirmation</span>
              </li>
              <li className="flex items-center space-x-3">
                <span className="h-4 w-4 rounded-full border-[2px] border-[#0096C1]"></span>
                <span>Funded (Escrow protection)</span>
              </li>
              <li className="flex items-center space-x-3">
                <span className="h-4 w-4 rounded-full border-[2px] border-gray-400"></span>
                <span>Delivered</span>
              </li>
              <li className="flex items-center space-x-3">
                <span className="h-4 w-4 rounded-full border-[2px] border-gray-400"></span>
                <span>Accepted</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HireVendor;
