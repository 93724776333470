import React, { useState } from "react";
import Container from "../../../container";
import { useSelector } from "react-redux";
import { edit, locationFilled } from "../../../../../constants/images";
import EditProfileDetails from "./editDetails";
import General_Modal from "../../../projects/shared/modals";
import EditAddress from "./editAddress";
import ReactStars from "react-rating-stars-component";
const ProfileDetails = () => {
  const [showModal, setShowModal] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useSelector((state) => state.user);
  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  return (
    <Container>
      <div className="px-16 py-24">
        {isEditing === false ? (
          <>
            <div className="flex justify-between">
              <h2 className="text-[25px] font-medium text-[#002D3A]">Profile</h2>
              <p
                className="cursor-pointer text-base text-[#0096C1]"
                onClick={() => setIsEditing(true)}
              >
                Edit profile
              </p>
            </div>
            <div className="flex flex-col items-center justify-center gap-2 border-b-2   pb-6">
              <img src={user?.avatar} alt="Profile" className="h-20 w-20 rounded-full" />
              <h3 className="text-[20px] font-semibold text-[#002D3A]">{user?.name}</h3>
              <p className="text-[18px] text-[#002D3A]">{user?.email}</p>
              <p className="text-[18px] text-[#002D3A]">{user?.phone}</p>
              <div className="flex space-x-2">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={40}
                  activeColor="#ffd700"
                  edit={false}
                  value={3}
                />{" "}
                4.9 <span className="text-[#5F5F5F]">(24)</span>
              </div>
            </div>
            <div className="mt-5">
              <h3 className="text-[18px] font-semibold text-[#002D3A]">Manage address</h3>
              <div className="flex items-center justify-between">
                <div className="mt-3 flex space-x-3">
                  <img src={locationFilled} alt="locationFilled" />{" "}
                  <p className="text-[16px] text-[#002D3A]">{user?.address}</p>
                </div>
                <img
                  src={edit}
                  alt="editIcon"
                  className="cursor-pointer"
                  onClick={() => setShowModal("address")}
                />
              </div>
            </div>
          </>
        ) : (
          <EditProfileDetails setIsEditing={setIsEditing} />
        )}
      </div>
      {showModal && (
        <General_Modal show={showModal} onClose={() => setShowModal(null)}>
          <EditAddress setShowModal={setShowModal} />
        </General_Modal>
      )}
    </Container>
  );
};

export default ProfileDetails;
