import React from "react";
import { Button } from "../../../components/ui";

const ReuseAbleSucessCard = ({ image, heading, message, button, onClick, btn = "true" }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-5 py-5">
      <div>
        <img src={image} alt="img" className="w-20" />
      </div>
      <h2 className="text-center text-xl font-semibold text-secondary">{heading}</h2>
      <p className="text-center text-base leading-5">{message}</p>
      {btn ? <Button children={button} onClick={onClick} className="mt-4 w-full" /> : null}
    </div>
  );
};

export default ReuseAbleSucessCard;
