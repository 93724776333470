import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: '',
  homeSearchInput: '',
  vendorSearch: '',
  disputeSearch: ''
};
export const searchSlice = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    search: (state, action) => {
      state.search = action.payload
    },
    vendorSearch: (state, action) => {
      state.vendorSearch = action.payload

    },
    handleHomeSearchInput: (state, action) => {
      state.homeSearchInput = action.payload
    },
    handleDisputeSearch: (state, action) => {
      state.disputeSearch = action.payload
    }
  },
});

export const { search, vendorSearch, handleHomeSearchInput,handleDisputeSearch } = searchSlice.actions;
