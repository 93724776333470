import React from 'react'
import { Button, Input } from '../../../../../../../components/ui'

const ExtendForm = () => {
  return (
    <div>
        <h3 className="text-[20px] font-medium">Extend Delivery Date</h3>
        <form action="" className="mt-3 flex flex-col gap-4">
            <div className="">
            <label htmlFor="" className='text-[18px]'>Current delivery date</label>
            <Input className="w-full rounded-md bg-[#F4F4F4] py-2 pl-3 mt-1" placeholder='23 Dec, 2023'/>
            </div>
            <div className="">
            <label htmlFor="" className='text-[18px]'>Proposed new delivery date</label>
            <Input className="w-full rounded-md bg-[#F4F4F4] py-2 pl-3 mt-1" placeholder='Date'/>
            </div>
            <div className="">
            <label htmlFor="" className='text-[18px]'>Reason for extension (Optional)</label>
            <textarea name="" id="" rows='3' className='bg-[#F4F4F4] w-full rounded-md py-2 px-3' placeholder='Why do you need the extension?'>
            </textarea>
            <Button children='Send' className='w-full mt-9'/>
            </div>
        </form>
    </div>
  )
}

export default ExtendForm