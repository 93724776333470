import React from "react";
import Container from "../../../../../../../container";
import { Button } from "../../../../../../../../../components/ui";
import { Link } from "react-router-dom";

const TrackDispute = () => {
  return (
    <Container>
      <div className="mt-20 px-12 ">
        <h2 className="text-center text-[16px] font-medium text-[#002D3A] md:text-start md:text-[20px]">
          Dispute resolution
        </h2>
        <div className="mt-7 flex flex-col md:gap-0 gap-3 justify-between md:flex-row">
          <div className="">
            <h3 className="text-[12px] text-[#5F5F5F] md:text-[16px]">Dispute ID</h3>
            <span className="text-[16px] font-medium text-[#002D3A] md:text-[18px]">GYWDDR101</span>

            <h3 className="mt-5 text-[12px] text-[#5F5F5F] md:text-[16px]">Project Title</h3>
            <span className="text-[16px] font-medium text-[#002D3A] md:text-[18px] ">
              Plumbing service for my bathroom
            </span>
          </div>

          <div className="">
            <h3 className="text-[12px] text-[#5F5F5F] md:text-[16px]">Project ID</h3>
            <span className="text-[16px] font-medium text-[#002D3A] md:text-[18px] ">#783451</span>

            <h3 className="mt-5 text-[16px] text-[#5F5F5F]">Dispute opened</h3>
            <span className="text-[16px] font-medium text-[#002D3A] md:text-[18px]">
              24 Dec 2023
            </span>
          </div>
          <div className="">
            <h3 className="text-[16px] text-[#5F5F5F]">Resolution status</h3>
            <span className="text-[16px] font-medium text-[#0096C1] md:text-[18px]">Pending</span>
          </div>
        </div>
        <div className="mt-10">
            <Link to='/project/dispute-card'>
        <Button children='Go to dashboard' className="w-full"/>
        </Link>
        </div>
      </div>
    </Container>
  );
};

export default TrackDispute;
