import React from "react";
import { Link } from "react-router-dom";
import { left, linkIcon } from "../../../../../../constants/images";

const LegalContent = () => {
  return (
    <div className="relative  h-[120vh] max-w-full items-start justify-center pl-0 pr-0 pt-[8rem] md:float-right md:mx-auto md:ml-auto md:mr-0 md:block md:w-[81%] md:pt-40">
      <Link to="/vendor-dashboard/home" className="relative block md:hidden">
        <img src={left} alt="go back" className="absolute left-4 top-2 w-[10px]" />
      </Link>
      <div className="flex w-full flex-col items-start justify-between px-6 md:px-20">
        <div className="flex w-full justify-center md:justify-start">
          <span className="mx-4 text-center text-xl font-medium text-secondary md:mx-0 md:text-[25px]">
            Legal
          </span>
        </div>
        <div className="mt-8 flex w-full flex-col gap-y-6 md:w-1/2">
          <Link to={"#"} className="rounded-[5px] border border-[#AEAEAE] py-2">
            <div className="flex items-center justify-between px-4 py-2 text-sm md:text-base">
              <span className="text-sm font-medium text-[#181818] md:text-lg">Privacy policy</span>
              <img src={linkIcon} alt="link" className="" />
            </div>
          </Link>
          <Link to={"/about-us"} className="rounded-[5px] border border-[#AEAEAE] py-2">
            <div className="flex items-center justify-between px-4 py-2 text-sm md:text-base">
              <span className="text-sm font-medium text-[#181818] md:text-lg">Terms of service</span>
              <img src={linkIcon} alt="link" className="" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LegalContent;
