import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DisputNavBar from "../navBar";
import InnerCard from "./cards";

const DisputeReview = () => {
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const [reviewData, shoReviewData] = useState(null);
  const { id } = useParams();
  const fetchReview = async () => {
    try {
      const response = await fetch(
      `${import.meta.env.VITE_BASE_URL}/admin/disputes-details/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },

        },
      );

      const data = await response.json();

      shoReviewData(data);
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  useEffect(() => {
    fetchReview();
  }, []);

  return (
    <>
      <div className="sticky top-0 z-40">
        <DisputNavBar />
      </div>
      <div className="mx-10">
        <div className="flex items-center justify-between bg-white px-10 py-3 drop-shadow-md">
          <div className="text-[25px] font-medium text-[#002D3A]">Disputes</div>
          <div className="">
            <button className="rounded-[5px] bg-white  px-3 py-1 text-[#0096C1]">
              Make a decision
            </button>
          </div>
        </div>
        <div className="mt-10">
          <InnerCard reviewData={reviewData} />
        </div>
      </div>
    </>
  );
};

export default DisputeReview;
