import React, { useState } from "react";
import { Pagination } from "../../../../components";
import { Dropdown } from "../../../../constants/images";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../utils/dateUtils";
import { paginateData } from "../../../../utils/pagination";
import { useNavigate } from "react-router-dom";
import useBannedAccounts from "./bannedAccoutHooks/useBannedAcct";

const BannedAccounts = () => {
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const [disputeDetails, setDisputeDetails] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [openSelect, setOpenSelect] = useState(false);
  const navigate = useNavigate();

  const { allBannedAccounts, acctDetails, dispute, showDetails, setAccountId, setShowDetails } =
    useBannedAccounts(accessToken); 

  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  const { currentData, totalPages } = paginateData(allBannedAccounts, currentPage, selectedEntry);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleBannedId = (id, user_id) => {
    setAccountId(id);
  };

  const handleDisputeDetails = (id) => {
    setDisputeDetails(true);
    navigate(`disputereview/${id}`);
  };

  return (
    <div>
      {!disputeDetails && (
        <div className="mb-10 w-full rounded-[10px] rounded-bl-none rounded-br-none bg-primary shadow-lg">
          <div className="w-full px-12 py-4">
            <div className="text-[25px] font-medium text-secondary">Banned accounts</div>
          </div>
        </div>
      )}

      {!showDetails && (
        <div className="grid w-full grid-cols-5 justify-items-start rounded-[5px] bg-[#E8E8E8] px-6 py-4 pl-12 text-sm font-medium text-[#181818]">
          <span className="text-base font-medium text-secondary">Name</span>
          <span className="text-base font-medium text-secondary">User ID</span>
          <span className="text-base font-medium text-secondary">Account status</span>
          <span className="text-base font-medium text-secondary">Ban date</span>
          <span className="text-base font-medium text-secondary">Unban date</span>
        </div>
      )}

      {!showDetails && (
        <div className="rounded-[5px] bg-primary pb-8 drop-shadow-lg">
          {currentData.map((value) => (
            <div
              key={value.id}
              className="transition-colors ease-in-out hover:cursor-pointer hover:bg-[#eceaea]"
              onClick={() => handleBannedId(value.id, value?.user_id)}
            >
              <div className="grid w-full grid-cols-5 justify-items-start px-6 py-4 pl-12">
                <span className="">{value?.business_name}</span>
                <span className="">{value?.user_id}</span>
                <span
                  className={`${value.status === "unbanned" ? "text-[#1de73f]" : "text-[#ec2727]"}`}
                >
                  {value.status}
                </span>
                <span className="">{formatDate(value?.ban_start_date)}</span>
                <span className="">{formatDate(value?.ban_end_date)}</span>
              </div>
              <hr color="#AEAEAE" className="w-full" />
            </div>
          ))}

          {!allBannedAccounts && (
            <div className="flex w-full items-center justify-center pt-4 text-xl text-secondary">
              No search results available for query
            </div>
          )}

          <div className="flex w-full justify-between px-6 pt-3">
            <div className="flex w-full">
              <div className="relative flex items-center gap-x-1">
                <span>Showing</span>
                <div
                  onClick={handleSelect}
                  className="flex w-fit items-center gap-x-4 px-5 text-base font-normal text-[#181818] hover:cursor-pointer"
                >
                  <span>{selectedEntry}</span>
                  <img
                    src={Dropdown}
                    alt="open"
                    className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
                  />
                </div>
                {openSelect && (
                  <div className="absolute bottom-[100%] left-[40%] w-[30%] animate-slide_up  rounded-[10px] bg-primary px-3 py-2">
                    <div className="flex flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
                      {entryOptions.map((entry) => (
                        <span
                          key={entry}
                          className="hover:cursor-pointer"
                          onClick={() => setSelectedEntry(entry)}
                        >
                          {entry}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                <span>entries</span>
              </div>
            </div>
            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
          </div>
        </div>
      )}

      {showDetails && !disputeDetails && acctDetails && (
        <div className="flex w-full flex-col gap-y-6">
          <div className="flex w-5/6 gap-x-14">
            <div className="w-1/2 rounded-[5px] bg-primary">
              <div className="flex w-full justify-between px-3 py-2">
                <span className="text-base text-lightgray">Name</span>
                <span className="text-lg font-medium text-secondary">
                  {acctDetails?.business_name || "-"}
                </span>
              </div>
              <hr color="" className="w-full" />
              <div className="flex w-full justify-between px-3 py-2">
                <span className="text-base text-lightgray">User ID</span>
                <span className="text-lg font-medium text-secondary">
                  {acctDetails?.user_id || "-"}
                </span>
              </div>
              <hr color="" className="w-full" />
              <div className="flex w-full justify-between px-3 py-2">
                <span className="text-base text-lightgray">Status</span>
                <span className="text-lg font-medium text-secondary">
                  {acctDetails?.status || "-"}
                </span>
              </div>
            </div>
            <div className="h-fit w-1/2 rounded-[5px] bg-primary">
              <div className="flex w-full justify-between px-3 py-2">
                <span className="text-base text-lightgray">Ban Date</span>
                <span className="text-lg font-medium text-secondary">
                  {formatDate(acctDetails?.ban_start_date) || "-"}
                </span>
              </div>
              <hr color="" className=" w-full" />
              <div className="flex w-full justify-between px-3 py-2">
                <span className="text-base text-lightgray">Unban Date</span>
                <span className="text-lg font-medium text-secondary">
                  {formatDate(acctDetails?.ban_end_date) || "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col pt-8">
            <div className="mb- w-full rounded-[10px] rounded-bl-none rounded-br-none bg-[#E8E8E8] shadow-lg">
              <div className="w-full px-12 py-4">
                <div className="text-[25px] font-medium text-secondary">Disputes raised</div>
              </div>
            </div>
            <div className="grid w-full grid-cols-4 justify-items-start bg-primary px-6  py-4 pl-12 text-sm font-medium text-[#181818]">
              <span className="text-lg font-medium text-secondary">Project Title</span>
              <span className="text-lg font-medium text-secondary">Project Number</span>
              <span className="text-lg font-medium text-secondary">Date of Complain</span>
              <span className="text-lg font-medium text-secondary">Resolution type</span>
            </div>
            {dispute.map((value) => (
              <div
                key={dispute?.id}
                className="bg-primary drop-shadow-lg transition-colors ease-in-out hover:cursor-pointer  hover:bg-[#eceaea]"
                onClick={() => handleDisputeDetails(value?.dispute_id)}
              >
                <div className="grid w-full grid-cols-4 justify-items-start px-6 py-4 pl-12">
                  <span className="">{value?.project_title || "-"}</span>
                  <span className="">{value?.project_number || "-"}</span>
                  <span className="">{formatDate(value?.date_opened) || "-"}</span>
                  <span
                    className={`${value?.decision_label === "positive" ? "text-[#1de73f]" : "text-[#ec2727]"}`}
                  >
                    {value?.decision_label || "-"}
                  </span>
                </div>
                <hr color="#AEAEAE" className="w-full" />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BannedAccounts;
