import { useState } from "react";
import "./index.css";


const Toggle = ({ label, defaultChecked = false, onChange }) => {
  const [checked, setChecked] = useState(defaultChecked);

  const handleToggle = () => {
    setChecked((prevChecked) => !prevChecked);
    onChange(!checked);
  };

  return (
    <div className="flex items-center">
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={handleToggle} className="hidden" />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default Toggle;
