import React from "react";
import { useParams } from "react-router-dom";
import { jobsData } from "../../data"; 
import {catalog2 } from "../../../../../constants/images";
import Container from "../../../container";

const JobDetails = () => {
  const { id } = useParams(); 
  const job = jobsData.find((job) => job.id === parseInt(id));

  if (!job)
    return (
      <div className="flex h-screen items-center justify-center text-xl font-semibold">
        Job not found
      </div>
    );

  return (
    <Container>
      <div className="px-5 py-16 md:px-12">
        <div className="flex justify-between">
        <h1 className="text-[24px] font-medium text-[#002D3A]">Job details</h1>
        <p className="mt-5 text-[14px] text-gray-500">Posted: 2 days ago</p>
        </div>

        <div className="mt-5">
          <h2 className="text-[18px] font-semibold">Job Title</h2>
          <p className="text-[16px]">{job.title}</p>
        </div>

        <div className="mt-5">
          <h2 className="text-[18px] font-semibold">Description</h2>
          <p className="text-[16px] md:w-[80%]">
            I need a plumber to work in my bathroom, Horem ipsum dolor sit amet,
            consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis
            tellus. Sed dignissim
          </p>
        </div>

        <div className="mt-5">
          <h2 className="text-[18px] font-semibold">Job requirements</h2>
          <ul className="text-[16px]">
            <li>1. Minimum of 5 years experience</li>
            <li>2. Resident of Yaba</li>
            <li>3. Have no ongoing work</li>
          </ul>
        </div>

        <div className="mt-5 grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <h2 className="text-[18px] font-semibold">Location</h2>
            <p className="text-[16px]">{job.location}</p>
          </div>
          <div>
            <h2 className="text-[18px] font-semibold">Budget</h2>
            <p className="text-[16px]">{job.budget}</p>
          </div>
        </div>

        <div className="mt-5 grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <h2 className="text-[18px] font-semibold">Date</h2>
            <p className="text-[16px]">From: 20 Dec 2023</p>
            <p className="text-[16px]">To: {job.posted_date}</p>
          </div>
        </div>

        <div className="mt-5">
          <h2 className="text-[18px] font-semibold">Document</h2>
          <div className="mt-2">
            <img
              src={catalog2}
              alt="Job document"
              className="w-[300px] rounded-md border border-gray-300"
            />
          </div>
        </div>

        
      </div>
    </Container>
  );
};

export default JobDetails;
