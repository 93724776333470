import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { allProjects } from "../../../../../data";
import { Button } from "../../../../../../../components/ui";
import {
  cancel,
  dropdown,
  due,
  left,
  projectchat,
  projectlocation,
  star,
} from "../../../../../../../constants/images";
import {
  setExtensionForm,
  setOverlay,
  setRateConsumer,
  setShowProjectDetails,
} from "../../../../../../../features/overlay";
import ProjectForm from "../../../_components/project-form";
import RatingForm from "../../../_components/ratings-form";
import ProjectAlert from "../../../_components/alert";
import ExtensionForm from "../../../_components/extension-form";

const MobileProjectDetails = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeScreen, setActiveScreen] = useState("summary");
  const overlayValue = useSelector(
    (state) => state?.overlay.displayStates.overlay,
  );
  const rateConsumer = useSelector(
    (state) => state?.overlay.displayStates.rateConsumer,
  );
  const overallRatings = useSelector(
    (state) => state?.overlay.displayStates.ratings,
  );
  const extensionForm = useSelector(
    (state) => state?.overlay.displayStates.extensionForm,
  );
  const dispatch = useDispatch();
  const projectId = useSelector(
    (state) => state?.overlay.displayStates.projectId,
  );

  const handleSelect = () => {
    setShowDropdown(!showDropdown);
  };
  const handleSubmit = () => {
    dispatch(setOverlay(true));
  };
  const handleClose = () => {
    dispatch(setShowProjectDetails(false));
  };
  const handleRatingForm = () => {
    dispatch(setRateConsumer(true));
  };
  const handleExtensionForm = () => {
    dispatch(setExtensionForm(true));
  };
  return (
    <div className="px- relative mx-auto block w-full py-10 md:hidden">
      {overlayValue && (
        <div className="fixed left-0 right-0 top-0 z-20 block h-[160vh] w-full bg-black bg-opacity-25 " />
      )}
      {rateConsumer && (
        <div className="fixed left-0 right-0 top-0 z-20 block h-[160vh] w-full bg-black bg-opacity-25 " />
      )}
      {extensionForm && (
        <div className="fixed left-0 right-0 top-0 z-20 block h-[160vh] w-full bg-black bg-opacity-25 " />
      )}
      {overlayValue && (
        <div className="fixed bottom-[0%] left-0 right-0 z-[99] w-full animate-slide_up ">
          <ProjectForm />
        </div>
      )}
      {extensionForm && (
        <div className="fixed bottom-[0%] left-0 right-0 z-[99] w-full animate-slide_up ">
          <ExtensionForm />
        </div>
      )}
      {rateConsumer && (
        <div className="fixed bottom-[0%] left-0 right-0 z-[99] w-full animate-slide_up ">
          <RatingForm />
        </div>
      )}

      {allProjects
        .filter((project) => project.id === projectId)
        .map((value) =>
          activeScreen === "summary" ? (
            <div className="relative">
              <img
                src={left}
                alt="back"
                className="absolute w-[3%] rotate-180"
                onClick={handleClose}
              />
              <span className="flex justify-center text-lg font-semibold text-secondary">
                Project details
              </span>
              <div className="flex flex-col gap-y-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-x-2">
                    <img
                      src={value.image}
                      alt="profile image"
                      className="w-[50px]"
                    />
                    <div className="flex flex-col">
                      <span className="text-base font-semibold text-secondary">
                        {value.hire}
                      </span>
                      <div className="flex items-center gap-x-2">
                        <img src={star} alt="" />
                        <span className="text-xs font-semibold text-[#181818]">
                          {value.rating}
                        </span>
                        <span className="text-xs text-[#181818]">
                          ({value.count})
                        </span>
                      </div>
                    </div>
                  </div>
                  <img
                    src={projectchat}
                    alt="chat"
                    className="w-[10%] rounded-[5px] border border-lightblue p-2"
                  />
                </div>
                <span className="text-xs text-lightgray">
                  Project number:{" "}
                  <span className="text-base">{value.project_number}</span>
                </span>
                <span className="text-base font-medium text-secondary">
                  {value.title}
                </span>
                {value?.due && (
                  <ProjectAlert
                    bg="#FFF8D1"
                    text="This project is due within the next 24 hours. If you've completed the project, please ensure to submit it for review. If you need more time, you can request an extension but note that extensions are subject to approval by the customer"
                    sub="Keep in mind that if the delivery date passes without submission or approved extension, the project will not be considered complete, and you risk not receiving payment."
                    icon={due}
                  />
                )}
                {value?.passed && (
                  <ProjectAlert
                    bg="#FFE7E7"
                    text="The submission deadline for this project has passed, and no submission can be made at this time, which means you risk not being paid"
                    sub="The consumer will decide on whether to extend or end the project and you will be notified of the decision."
                    icon={cancel}
                  />
                )}
                <div className="flex flex-col ">
                  <div className="flex justify-between">
                    <div className="flex w-full flex-col gap-y-3">
                      <span className="text-xs text-lightgray">
                        Status:{" "}
                        <span
                          className={`text-sm ${value.project_status === "Ongoing" ? "text-[#0096C1]" : value.project_status === "Completed" ? "text-[#36C100]" : value.project_status === "Cancelled" ? "text-[#C12B00]" : ""}`}
                        >
                          {value.project_status}
                        </span>
                      </span>
                      <div className="flex flex-col">
                        <span className="text-xs text-lightgray">Location</span>
                        <span className="text-sm font-medium text-[#181818]">
                          {value.location_status}
                        </span>
                        <div className="flex items-center gap-x-2">
                          <img src={projectlocation} alt="location" />
                          <span className="text-sm font-medium text-[#181818]">
                            {value.location}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-lightgray">Date</span>
                        <span className="text-xs text-[#181818]">
                          From{" "}
                          <span className="text-sm font-medium">
                            {value.start_date}
                          </span>
                        </span>
                        <span className="text-xs text-[#181818]">
                          To{" "}
                          <span className="text-sm font-medium">
                            {value.end_date}
                          </span>
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-lightgray">Budget</span>
                        <span className="text-sm font-medium text-[#181818]">
                          {value.budget}
                        </span>
                      </div>
                      <div className="flex w-3/4 flex-col">
                        <span className="text-xs text-lightgray">Note</span>
                        <span className="text-sm font-medium text-[#181818]">
                          {value.description}
                        </span>
                      </div>
                      <div className="flex flex-col gap-y-2">
                        <span className="text-xs text-lightgray">Document</span>
                        <img
                          src={value.document}
                          className="w-1/2"
                          alt="attachment"
                        />
                      </div>
                      <div className="relative flex flex-col border border-[#AEAEAE]">
                        <div className="flex items-center justify-between px-3 py-2">
                          <span className="text-xs font-normal text-lightgray">
                            View project timeline
                          </span>
                          <img
                            onClick={handleSelect}
                            src={left}
                            className={`${showDropdown && "rotate-90"} transition ease-in`}
                            alt="view project"
                          />
                        </div>
                        {showDropdown && (
                          <div className="mt-2 flex animate-slide_up flex-col px-3 py-2 hover:cursor-pointer ">
                          
                          </div>
                        )}
                      </div>
                      <div
                        className="relative flex flex-col border border-[#AEAEAE]"
                        onClick={() => setActiveScreen("submitted")}
                      >
                        <div className="flex items-center justify-between px-3 py-2">
                          <span className="text-xs font-normal text-lightgray">
                            View submitted work
                          </span>
                          <img
                            onClick={handleSelect}
                            src={left}
                          
                            alt="view work"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col gap-y-2">
                        <span className="text-base font-medium text-secondary">
                          Job feedback
                        </span>
                        <hr className="w-full" />
                        <div className="flex flex-col gap-y-1">
                          <span className="text-sm font-medium text-[#181818]">
                            Consumer’s feedback to you
                          </span>
                          <div className="flex justify-between gap-x-2">
                            <span className="text-xs font-medium text-lightgray">
                              Overall rating
                            </span>
                            <div className="flex items-center gap-x-2">
                              <ReactStars
                                color={"#ffd700"}
                                count={value.count}
                              />
                              <span className="text-xs font-medium text-lightgray">
                                {value.count}
                              </span>
                            </div>
                          </div>
                          <hr className="w-full" />
                          {/* <div className="flex justify-between gap-x-2 py-1">
                            <span className="text-sm font-medium text-[#181818]">
                              Your feedback to consumer
                            </span>
                            <span className="text-xs font-medium text-lightgray">
                              No feedback given
                            </span>
                          </div> */}
                          <div className={`flex justify-between gap-x-2 py-1 ${overallRatings ? 'flex-col' : 'flex-row'}`}>
                            <span className="text-sm font-medium text-[#181818]">
                              Your feedback to consumer
                            </span>
                            {!overallRatings ? (
                              <span className="text-xs font-medium text-lightgray">
                                No feedback given
                              </span>
                            ) : (
                              <>
                             
                              <div className="flex items-center justify-between gap-x-2">
                                <span className="text-xs font-medium text-lightgray">
                                  Overall ratings
                                </span>
                                <div className="flex items-center gap-x-2">
                                  <ReactStars
                                    key={overallRatings}
                                    count={5}
                                    name="overall"
                                    value={overallRatings}
                                    // size={}
                                    activeColor="#FFE234"
                                  />
                                  <span className="text-xs font-medium text-[#181818]">
                                    {overallRatings}
                                  </span>
                                </div>
                              </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <Button
                        width="100%"
                        padding={"10px"}
                        className="!font-medium"
                        onClick={handleRatingForm}
                      >
                        Rate consumer
                      </Button>
                      <div className="flex w-full gap-x-3">
                        <Button
                          backgroundColor="transparent"
                          textColor="#0096C1"
                          padding={"10px"}
                          onClick={handleExtensionForm}
                          className="w-full !text-sm !font-medium"
                        >
                          Request extension
                        </Button>
                        <Button
                          // width="160px"
                          padding={"10px"}
                          className="w-full !font-medium"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                    {/* <div className="w-1/2 border border-[#AEAEAE]">
                    <div className="flex flex-col gap-y-5 px-10 py-5">
                      <span className="text-lg font-semibold text-secondary">
                        Project timeline
                      </span>
                      <div className="flex flex-col gap-y-8">
                        <div className="flex gap-x-3">
                          <div className="flex flex-col">
                            <span>Project funded</span>
                            <span>18 Dec 2023</span>
                          </div>
                        </div>
                        <div className="flex gap-x-3">
                          <span>Deliver work</span>
                        </div>
                        <div className="flex gap-x-3">
                          <span>Consumer review and approval</span>
                        </div>
                        <div className="flex gap-x-3">
                          <span>Accepted</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          ) : activeScreen === "submitted" ? (
            <div className="flex flex-col items-start gap-y-6">
              <img
                src={left}
                alt="back"
                className="w-[3%] rotate-180"
                onClick={() => setActiveScreen("summary")}
              />
              <span className="text-base font-medium text-secondary">
                {value.title}
              </span>
              <span className="text-xs text-[#181818]">
                Submitted:{" "}
                <span className="text-sm font-medium">{value.end_date}</span>
              </span>
              <div className="flex w-5/6 flex-col">
                <span className="text-xs text-lightgray">Note</span>
                <span className="text-sm font-medium text-[#181818]">
                  {value.note}
                </span>
              </div>
              <div className="flex flex-col gap-y-2">
                <span className="text-xs text-lightgray">Document</span>
                <img src={value.submitted} className="w-1/2" alt="attachment" />
              </div>
              <Button
                padding={"10px"}
                className="!w-full !font-medium"
                onClick={handleSubmit}
              >
                Update
              </Button>
            </div>
          ) : (
            ""
          ),
        )}
    </div>
  );
};

export default MobileProjectDetails;
