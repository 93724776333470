import React from 'react'
import Navbar from '../../_components/ui/navbar'
import SideBar from '../../_components/ui/sidebar'
import MainDashboard from './_components/main-dashboard'

const VendorDashboardProfile = () => {
  return (
    <div>
    <Navbar />
    <SideBar />
    <MainDashboard />
  </div>
  )
}

export default VendorDashboardProfile