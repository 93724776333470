import React, { useState } from 'react';

const Navbar = ({ setActiveStatus }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleStatusClick = (index, status) => {
    setActiveIndex(index);
    setActiveStatus(status); // Update the active status in Projects component
  };

  return (
    <div className='w-full bg-white drop-shadow-md py-3'>
      <div className="flex justify-around text-[18px] overflow-x-auto whitespace-nowrap no-scrollbar px-2 cursor-pointer">
        <div
          className={`${activeIndex === 0 && "bg-[#0096C1] text-white py-1 rounded-[5px] px-4"} py-1 px-4`}
          onClick={() => handleStatusClick(0, 'All')}
        >All</div>
        <div
          className={`${activeIndex === 1 && "bg-[#0096C1] text-white py-1 rounded-[5px] px-4"} py-1 px-4`}
          onClick={() => handleStatusClick(1, 'Ongoing')}
        >Ongoing</div>
        <div
          className={`${activeIndex === 2 && "bg-[#0096C1] text-white py-1 rounded-[5px] px-4"} py-1 px-4`}
          onClick={() => handleStatusClick(2, 'Completed')}
        >Completed</div>
        <div
          className={`${activeIndex === 3 && "bg-[#0096C1] text-white py-1 rounded-[5px] px-4"} py-1 px-4`}
          onClick={() => handleStatusClick(3, 'Cancelled')}
        >Cancelled</div>
      </div>
    </div>
  );
};

export default Navbar;
