import React, { useState } from "react";
import { Button } from "../../../../../components/ui";
import { useNavigate } from "react-router-dom";

const SwitchTOVendor = ({ setOpenModal }) => {
  const navigate = useNavigate();
  return (
    <div className="mt-5 flex flex-col items-center justify-center">
      <h1 className="text-[20px] font-semibold text-[#002D3A]">Switch to vendor</h1>
      <p className="mt-7 text-[18px] font-medium text-[#002D3A]">
        Are you sure you want to switch from Consumer to Vendor?{" "}
      </p>
      <p className="mt-4 text-[16px]">
        {" "}
        We will be needing some additional details from you. It won’t take long
      </p>
      <div className="my-7 flex w-full space-x-4">
        <Button
          className="w-full"
          backgroundColor="#ffff"
          textColor="#0096C1"
          children="No"
          onClick={() => setOpenModal(null)}
        />{" "}
        <Button children="Yes" className="w-full" onClick={() => navigate("/signup")} />
      </div>
    </div>
  );
};

export default SwitchTOVendor;
