import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

const VendorSide = ({ reviewData }) => {
  const datatype = reviewData?.dispute_file_url?.includes("[");
  const files =
    datatype && reviewData?.dispute_file_url
      ? JSON.parse(reviewData.dispute_file_url)
      : [reviewData?.dispute_file_url];

  const docViewerFiles = files.map((file) => ({ uri: file }));

  const handleDownload = (url) => {
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      alert("Popup blocked! Please allow popups for this website.");
    }
  };

  return (
    <>
      <div className="px-5 py-7">
        <div className="flex gap-20">
          <div className="text-[18px] text-[#5F5F5F]">
            <h3 className="">Vendor name</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">
              {reviewData?.vendor_name || <Skeleton height={30} />}
            </span>
          </div>
          <div className="text-[18px] text-[#5F5F5F]">
            <h3 className="">Email address</h3>
            <span className="text-[20px] font-medium text-[#002D3A]">
              {reviewData?.vendor_email || <Skeleton height={30} />}
            </span>
          </div>
        </div>
        <h3 className="mt-5 text-[25px] leading-[29.33px] text-[#5F5F5F]">Submitted Evidences</h3>
        <div className="mt-3 text-[18px]">
          {reviewData?.vendor_response === null ? (
            <p className="text-gray-500">No response yet</p>
          ) : (
            <p className="text-green-500">{reviewData.vendor_response}</p>
          )}
        </div>
        <div className="mt-3 text-[18px]">
          {reviewData?.vendor_response_file_url === null ? (
            <p className="text-gray-500">No files submitted yet</p>
          ) : (
            docViewerFiles.map((file, index) => (
              <div
                key={index}
                className="flex h-fit w-[185px] flex-col items-center justify-center rounded-[5px] bg-[#EAEAEA] p-2"
              >
                <div className="h-[110.06px] w-[165px] overflow-hidden object-contain">
                  <DocViewer documents={[file]} pluginRenderers={DocViewerRenderers} />
                </div>
                <span className="mt-1 text-[#181818]">Document {index + 1}</span>
                {file.uri ? (
                  <button
                    onClick={() => handleDownload(file.uri)}
                    className="mt-2 text-[#0096C1] underline"
                  >
                    Download
                  </button>
                ) : null}
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default VendorSide;
