import React from "react";
import Navbar from "../../_components/ui/navbar";
import SideBar from "../../_components/ui/sidebar";
import OfferContent from './_components/offer-content'

const VendorDashboardOffers = () => {
  return (
    <div>
      <Navbar />
      <SideBar />
      <OfferContent />
    </div>  
  );
};

export default VendorDashboardOffers;
