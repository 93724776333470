import React, { useState } from "react";
import { Link } from "react-router-dom";
import SelectedAlert from "../selected-alert";
import { allNotifications } from "../../../../../../admin/constants";
import { RoundedBell } from "../../../../../../admin/constants/images";
import ResponseModal from "../response-modal";
import SuccessModal from "../success-modal";

const AlertContent = () => {
  const [selected, setSelected] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);

  const handleAlert = (e) => {
    setSelected(e);
    setShowAlert(true);
  };

  const handleModal = () => {
    setShowAlert(false);
    // setShowResponse(true)
  };

  const handleRequest = () => {
    setShowResponse(true);
    setShowAlert(false);
  };

  const handleSuccess = (success) => {
    if (success) {
      setShowSubmit(true);
      setShowAlert(false);
      setShowResponse(false);
    }
  };
  return (
    <div className="relative mr-0 h-screen w-full max-w-full items-start justify-center pl-0 pr-0 pt-10 md:float-right md:mx-auto md:ml-auto md:w-[81%] md:pt-20">
      {(showAlert || showResponse || showSubmit) && (
        <div className="fixed left-0 right-0 top-0 z-[99] block h-[160vh] w-full bg-black bg-opacity-25" />
      )}
      {showAlert && !showResponse && (
        <div className="bottom fixed left-[33%] top-[25%] z-[99] h-fit w-[40%] overflow-hidden bg-primary">
          <SelectedAlert handleModal={handleModal} selectedId={selected} onClick={handleRequest} />
        </div>
      )}
      {!showAlert && showResponse && (
        <div className="bottom fixed left-[33%] top-[25%] z-[99] h-fit w-[35%] overflow-hidden bg-primary">
          <ResponseModal
            cancel={() => setShowResponse(false)}
            onSuccess={handleSuccess}
            handleClose={() => setShowResponse(false)}
            handleModal={handleRequest}
          />
        </div>
      )}
      {showSubmit && (
        <div className="bottom fixed left-[33%] top-[25%] z-[99] h-fit w-[35%] overflow-hidden bg-primary">
          <SuccessModal handleClose={() => setShowSubmit(false)} />
        </div>
      )}
      <div className="pt-12 md:pt-10">
        <div className="pl-4 text-xl font-semibold text-secondary md:pl-10 md:text-2xl">Alerts</div>
        {allNotifications.length === 0 && (
          <div className="flex h-[80vh] flex-col items-center justify-center gap-y-4">
            <span className="text-lg font-medium text-secondary">No notifications yet</span>
            <span className="text-[#181818]">
              Get updates on your ongoing projects, new messages, and exciting promotions right here
            </span>
            <Link to="/vendor-dashboard/jobs" className="font-semibold text-lightblue">
              Browse jobs
            </Link>
          </div>
        )}
        <div className="flex w-full flex-col gap-y-4 py-5">
          {allNotifications.map((value) => (
            <div
              className="flex w-full flex-col gap-y-6 hover:cursor-pointer"
              key={value.id}
              onClick={() => handleAlert(value.id)}
            >
              <span className="px-4 font-semibold text-secondary md:px-10 md:text-xl">
                {value.day}
              </span>
              <hr color="#aEAEAE" className="w-full" />
              {value.notification.map((notification) => (
                <div>
                  <div
                    className="flex w-full items-center justify-between px-3 md:px-16"
                    key={notification.id}
                  >
                    <div className="flex w-full items-center gap-x-2 md:gap-x-3">
                      {notification.read && (
                        <div className="h-[8px] w-[8px] rounded-[50%] bg-lightblue" />
                      )}
                      <img
                        src={RoundedBell}
                        alt={notification.title}
                        className="w-[20px] md:w-[30px]"
                      />
                      <div className="flex w-full flex-col gap-y-2">
                        <span className="text-base font-semibold text-[#181818]">
                          {notification.title}
                        </span>
                        <span className="text-sm font-normal text-[#181818] md:w-3/4 md:text-base">
                          {notification.content}
                        </span>
                      </div>
                    </div>
                    <div className="flex w-[110px] justify-end text-sm font-normal text-lightgray">
                      {notification.time}
                    </div>
                  </div>
                  <hr color="#aEAEAE" className="my-3 w-full" />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlertContent;
