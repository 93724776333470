import React, { useState } from "react";
import { Logout } from "../../../../constants/images";
import { NavLink } from "react-router-dom";
import {
  AlertIcon,
  JobIcon,
  ChatIcon,
  ProjectIcon,
  EarningIcon,
} from "../../../vendor-dashboard/_components/ui/sidebar/icons";
import { useDispatch } from "react-redux";
import { setSeeMoreJobs, setShowServiceRequest } from "../../../../features/overlay";
import ServiceIcon from "./icons/serviceIcon";
import HomeIcon from "./icons/HomeIcon";


const SideBar = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);
  const handleClick = () => {
    dispatch(setShowServiceRequest(false));
    dispatch(setSeeMoreJobs(false));
  };
  const sideLinks = [
    {
      id: 0,
      title: "Home",
      route: "dashboard",
      icon: <HomeIcon />,
    },
    {
      id: 1,
      title: "Service",
      route: "service",
      icon: <ServiceIcon />,
    },
    {
      id: 2,
      title: "Projects",
      route: "projects",
      icon: <ProjectIcon />,
    },
    {
      id: 3,
      title: "Chat",
      route: "chat",
      icon: <ChatIcon />,
    },
    {
      id: 4,
      title: "Alerts",
      route: "alerts",
      icon: <AlertIcon />,
    },
    {
      id: 5,
      title: "Jobs",
      route: "jobs",
      icon: <JobIcon />,
    },
  ];
  return (
    <div className="fixed bottom-0 left-0 right-0  float-left flex w-auto items-center justify-center bg-white px-4 drop-shadow-md md:bottom-[-10.5%] md:left-auto md:right-auto md:mt-28 md:block md:h-screen md:w-[16%] md:px-0">
      <div className="flex justify-between md:flex-col md:px-0">
        <div className="flex w-full flex-row items-center py-3 pt-7 text-[16px] md:flex-col md:items-start md:gap-x-4 md:gap-y-2 md:px-4 md:py-1 md:text-base">
          {sideLinks.map((link) => (
            <NavLink
              to={`/${link.route}`}
              onClick={handleClick}
              className={({ isActive }) =>
                isActive
                  ? "flex w-3/4 cursor-pointer flex-col items-center gap-x-3 rounded-[5px] bg-lightblue px-3 py-1 text-white md:flex md:flex-row md:items-start md:py-3"
                  : "flex w-3/4 cursor-pointer  flex-col items-center gap-x-3 bg-none px-2 md:flex-row md:items-start md:px-3 md:py-3"
              }
            >
              <div>{link.icon}</div>
              <span className="text-sm font-medium md:text-base">{link.title}</span>
            </NavLink>
          ))}
        </div>
        <div
          className={`${active === 6 && "rounded-[5px] bg-[#0096C1] px-2 py-2 text-white"} mt-24  hidden cursor-pointer items-center gap-x-2 px-2 md:ml-4  md:flex`}
          onClick={() => handleTabSwitch(6)}
        >
          <img src={Logout} alt="" />
          <span className="">Logout</span>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
