import React, { useState } from "react";
import { disputeData } from "./data";
import CardDispute from "./card";
import Container from "../../../../../../../container";
import ReuseableDropdown from "../../../../../../shared/reuseable-dropdown/index.";

const DisputeCard = () => {
  const [newestSort, setNewestSort] = useState(false);
  const [categorySort, setCategorySort] = useState(false);
  const [statusSort, setStatusSort] = useState(false);

  return (
    <Container>
      <div className="mt-32 px-12">
        <h2 className="text-center text-[16px] font-medium text-[#002D3A] md:text-start md:text-[25px]">
          Dispute resolution
        </h2>

        <div className="hide-scrollbar mt-7 flex items-center gap-4 overflow-x-auto md:overflow-hidden">
          <span>Sort:</span>
          <ReuseableDropdown
            label="Newest"
            isOpen={newestSort}
            handleToggle={() => setNewestSort(!newestSort)}
            options={["Newest first", "Oldest first"]}
          />

          <ReuseableDropdown
            label="Category"
            isOpen={categorySort}
            handleToggle={() => setCategorySort(!categorySort)}
            options={["All Service categories"]}
          />

          <ReuseableDropdown
            label="Status"
            isOpen={statusSort}
            handleToggle={() => setStatusSort(!statusSort)}
            options={["All", "Unresolved", "Resolving", "Resolved"]}
          />
        </div>

        <div className="mt-7 flex flex-col gap-5">
          {disputeData.map((dispute, index) => (
            <CardDispute key={index} {...dispute} />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default DisputeCard;
