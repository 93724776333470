import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  profileicon,
  wallet,
  offers,
  settings,
  help,
  legal,
  dispute,
  switchh,
  Logout,
} from "../../../constants/images";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import General_Modal from "../projects/shared/modals";
import SwitchTOVendor from "./_components/switch";

const Menu = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [openModal, setOpenModal] = useState(null);
  const menuVariants = {
    open: { x: 0, opacity: 1, transition: { duration: 0.5 } },
    closed: { x: "100%", opacity: 0, transition: { duration: 0.5 } },
  };

  const handleClick = () => {
    setOpenModal("switch");
    setIsOpen(!isOpen);
  };

  return (
    <>
      <motion.div
        className="fixed right-3 top-12 z-50 h-fit bg-white shadow-lg md:right-10 md:top-10 md:w-[20%]"
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={menuVariants}
      >
        <div className="h-full border-[1px] border-[#B5B5B5] px-6 py-4 text-[#052538]">
          <div className="mb-4 border-b border-[#B5B5B5] pb-4">
            <div className="flex items-center gap-4">
              <img src={user?.avatar} alt="Profile" className="h-10 w-10 rounded-full" />
              <div>
                <p className="text-base font-semibold">{user?.name}</p>
                <p className="text-sm text-[#6D6D6D]">{user?.role}</p>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-4 border-b border-[#B5B5B5] pb-6">
            <MenuItem
              icon={profileicon}
              text="Profile"
              onClick={() => navigate("/profile-details")}
            />
            <MenuItem icon={wallet} text="Payments" onClick={() => navigate("/payment-details")} />
            <MenuItem icon={offers} text="Offers" onClick={() => navigate("/offers-details")} />
          </div>

          <div className="flex flex-col space-y-4 border-b border-[#B5B5B5] py-6">
            <MenuItem
              icon={settings}
              text="Settings"
              onClick={() => navigate("/consumer-settings-details")}
            />
            <MenuItem
              icon={help}
              text="Help & support"
              onClick={() => navigate("/consumer-help/support")}
            />
            <MenuItem
              icon={dispute}
              text="Dispute resolution"
              onClick={() => navigate("/consumer-dispute-card")}
            />
            <MenuItem icon={legal} text="Legal" onClick={() => navigate("/consumer-legal")} />
            <MenuItem icon={switchh} text="Switch to vendor" onClick={() => handleClick()} />
          </div>

          <div className="mt-auto pt-6">
            <MenuItem icon={Logout} text="Logout" />
          </div>
        </div>
      </motion.div>
      {openModal === "switch" && (
        <General_Modal onClose={() => setOpenModal(null)}>
          <SwitchTOVendor setOpenModal={setOpenModal} />
        </General_Modal>
      )}
    </>
  );
};

const MenuItem = ({ icon, text, onClick }) => (
  <div className="flex cursor-pointer items-center gap-4" onClick={onClick}>
    <img src={icon} alt={`${text} icon`} className="h-4 w-4" />
    <p className="text-sm">{text}</p>
  </div>
);

export default Menu;
