import React from 'react'
import { warning } from '../../../../../../../../constants/images'

const WarnningBanner = () => {
  return (
    <div className="mt-5 bg-[#E6F5F9] px-4 py-3">
    <div className="">
      <img src={warning} alt="warning" />
    </div>
    <span className="md:text-[16px] leading-[20px] text-[14px] ">
      You have <span className="font-semibold text-[#002D3A]">48 hours</span>  to review and rate John Doe. Once it expires, you won't be able to rate,
      and payment will be processed automatically. Rating John Doe is crucial if you need to
      raise a dispute.
    </span>
  </div>
  )
}

export default WarnningBanner