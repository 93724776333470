import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editIcon } from "../../../../../../constants/images";
import { Button, Input } from "../../../../../../components/ui";
import { setUser } from "../../../../../../features/consumerSlice"; 

const EditProfileDetails = ({setIsEditing}) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
  
    const [formData, setFormData] = useState({
      name: user?.name || "",
      email: user?.email || "",
      phone: user?.phone || "",
      avatar: user?.avatar || "",
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const imageURL = URL.createObjectURL(file);
        setFormData({ ...formData, avatar: imageURL });
      }
    };
  

    const handleSubmit = (e) => {
      e.preventDefault();
      dispatch(setUser(formData));
      setIsEditing(false) 
    };
  
    return (
      <div>
        <h2 className="text-[25px] font-medium text-[#002D3A]">Edit Profile</h2>
        <div className="relative flex items-center justify-center border-b-2 pb-6">
          <img
            src={formData.avatar}
            alt="Profile"
            className="h-20 w-20 rounded-full object-cover"
          />
          <label htmlFor="avatar-upload" className="absolute bottom-7 right-[33vw] h-7 w-7 cursor-pointer">
            <img src={editIcon} alt="Edit Icon" />
          </label>
          <input
            type="file"
            id="avatar-upload"
            accept="image/*"
            className="hidden"
            onChange={handleImageChange}
          />
        </div>
        <div className="mx-auto mt-5 w-[70%]">
          <form onSubmit={handleSubmit} className="flex flex-col gap-3">
            <div>
              <label htmlFor="name">Name</label>
              <Input
                type="text"
                className="mt-2 w-full rounded-sm py-2 pl-3"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="email" className="mt-3">Email</label>
              <Input
                type="email"
                className="mt-2 w-full rounded-sm py-2 pl-3"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor="phone">Phone Number</label>
              <Input
                type="text"
                className="mt-2 w-full rounded-sm py-2 pl-3"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <Button type="submit" children="Update" className="mt-5" />
          </form>
        </div>
      </div>
    );
  };
  
  export default EditProfileDetails;