import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { Back, Location } from "../../../../../../../constants/images";
import { setShowProject } from "../../../../../../../redux/slice/modalOverlay";
import { formatDate } from "../../../../../../../utils/dateUtils";
import ProjectTimeLine from "../projectTimeline";

const ProjectDetails = () => {
  const [projectDetails, setProjectDetails] = useState();
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.modalOverlay.states.projectId);
  const accessToken = useSelector((state) => state?.token.states.accessToken)
  const budgetFormat = projectDetails?.budget.toLocaleString();
  const fetchProjectDetails = async (id) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/service-request/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setProjectDetails(data || []);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const handleBackClick = () => {
    dispatch(setShowProject(false));
  };

  useEffect(() => {
    fetchProjectDetails(projectId);
  }, [projectId]);

  const datatype = projectDetails?.document_url.includes("[");
  const files =
    datatype && projectDetails?.document_url
      ? JSON.parse(projectDetails?.document_url)
      : [projectDetails?.document_url];
  const docViewerFiles = files.map((file) => ({ uri: file }));
  return (
    <>
      <div className="flex justify-between rounded-[10px] bg-primary px-10 py-12 pt-10 shadow-lg">
        <div className="flex w-[70%] flex-col gap-y-5">
          <div className="flex items-center justify-start gap-x-2">
            <img src={Back} alt="back" className="hover:cursor-pointer" onClick={handleBackClick} />
            <span className="text-base font-medium text-[#181818]">Back</span>
          </div>
          <span className="text-sm text-lightgray">
            Status:{" "}
            <span
              className={`text-sm font-medium lg:text-base ${projectDetails?.status === "ongoing" ? "text-[#0096C1]" : projectDetails?.project_status === "completed" ? "text-[#36C100]" : projectDetails?.project_status === "cancelled" ? "text-[#C12B00]" : ""}`}
            >
              {projectDetails?.status || "-"}
            </span>
          </span>
          <div className="flex flex-col">
            <span className="text-sm text-lightgray">Project title</span>
            <span className="text-lg font-medium text-[#181818]">
              {projectDetails?.title || "-"}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-sm text-lightgray">Location</span>
            <span className="text-base font-medium text-[#181818]">
              {projectDetails?.location || "-"}
            </span>
            <div className="flex items-center gap-x-2">
              <img src={Location} alt="location" />
              <span className="text-base font-medium text-[#181818]">
                {projectDetails?.location || "-"}
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-sm text-lightgray">Date</span>
            <span className="text-sm text-lightgray">
              From{" "}
              <span className="text-sm font-medium text-[#181818]">
                {formatDate(projectDetails?.start_date) || "-"}
              </span>
            </span>
            <span className="text-sm text-lightgray">
              To{" "}
              <span className="text-sm font-medium text-[#181818]">
                {formatDate(projectDetails?.end_date) || "-"}
              </span>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-sm text-lightgray">Budget</span>
            <span className="text-sm font-medium text-[#181818]">{budgetFormat || "-"}</span>
          </div>

          <div className="flex flex-col items-start gap-y-2">
            <span className="text-sm text-lightgray">Document</span>
            <div className="mt-2 flex gap-7">
              {docViewerFiles.length > 0 ? (
                docViewerFiles.map((file, index) => (
                  <div
                    key={index}
                    className="flex h-fit w-[185px] flex-col items-center justify-center rounded-[5px] bg-[#EAEAEA] p-2"
                  >
                    <div className="h-[110.06px] w-[165px] overflow-hidden object-contain">
                      <DocViewer documents={[file]} pluginRenderers={DocViewerRenderers} />
                    </div>
                    <span className="mt-1 text-[#181818]">Document {index + 1}</span>
                  </div>
                ))
              ) : (
                <Skeleton height={110} width={165} count={2} />
              )}
            </div>
          </div>
          <div className="flex w-3/4 flex-col">
            <span className="text-sm text-lightgray">Note</span>
            <span className="font-medium text-[#181818] lg:text-lg">
              {projectDetails?.notes || "-"}
            </span>
          </div>
        </div>
        <ProjectTimeLine projectDetails={projectDetails} />
      </div>
    </>
  );
};

export default ProjectDetails;
