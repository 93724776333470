import React, { useState } from "react";
import Container from "../../../container";
import LogoutModal from "../../../../vendor-dashboard/_pages/settings/_components/logout_modal";
import CloseAccountModal from "../../../../vendor-dashboard/_pages/settings/_components/close-account_modal";
import SettingsMobileContent from "../../../../vendor-dashboard/_pages/settings/_components/settings-content/mobile";
import SettingsContent from "./_components/settings-content";


const ConsumerSettings = () => {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showCloseAccountModal, setShowCloseAccountModal] = useState(false);

  return (
    <Container>
    <div className="relative">
      <LogoutModal showLogout={showLogoutModal} setShowLogout={setShowLogoutModal} />
      <CloseAccountModal
        showCloseAccount={showCloseAccountModal}
        setShowCloseAccount={setShowCloseAccountModal}
      />
      <SettingsContent
        setShowLogoutModal={setShowLogoutModal}
        setShowCloseAccountModal={setShowCloseAccountModal}
      />
      <SettingsMobileContent setShowLogoutModal={setShowLogoutModal} setShowCloseAccountModal={setShowCloseAccountModal} />
    </div>
    </Container>
  );
};

export default ConsumerSettings;
