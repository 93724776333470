import { useState } from "react"


const Checkbox = ({handleActive,active}) => {


  return (
    <div className="flex gap-x-28 " >
    <div className="flex items-center gap-2">
      <input type="checkbox" 
      checked={active === 1}
      onChange={() => handleActive(1)}
      />
 
    <label
      htmlFor="Give partial award"
      className="text-[18px] font-normal "
    >
      Give full award to consumer
    </label>
      </div>
      <div className="flex items-center gap-2">
      <input type="checkbox" 
      checked={active === 2}
      onChange={() => handleActive(2)}
      />
    
    <label
      htmlFor="Give partial award"
      className="text-[18px] font-normal "
    >
      Give full award to vendor
    </label>
      </div>
      <div className="flex items-center gap-2">
      <input type="checkbox" 
      checked={active === 3}
      onChange={() => handleActive(3)}
      />

    <label
      htmlFor="Give partial award"
      className="text-[18px] font-normal "
    >
      Give partial award
    </label>
      </div>
    </div>
  )
}

export default Checkbox