import React, { useState } from "react";
import { logo, logoutIcon } from "../../../constants/images";
import { useDispatch, useSelector } from "react-redux";
import { sideLinks } from "../../../constants";
import {
  setActiveTable,
  setShowAdvertModal,
  setShowAgentModal,
  setShowBannedAccounts,
  setShowDiscountModal,
  setShowDispute,
  setShowEditAgent,
  setShowPermisionsModal,
  setShowProject,
  setShowVendor,
} from "../../../redux/slice/modalOverlay";
import { Link } from "react-router-dom";
import { setAccessToken } from "../../../redux/slice/login";

const Sidebar = () => {
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState();
  const userType = useSelector((state) => state.token.states.userType);

  const filteredLinks = sideLinks.filter((link) => {
    const excludedLinks = {
      "Super Admin": ["Registered Vendors", "Banned Accounts"],
      "Dispute Resolution": [
        "Dashboard",
        "Business Suite",
        "Registered Vendors",
        "Banned Accounts",
      ],
      "ID Confirmation": [
        "Dashboard",
        "Dispute Resolution",
        "Registered Vendors",
        "Banned Accounts",
      ],
      Contractor: ["Dashboard", "Dispute Resolution", "Business Suite", "Banned Accounts"],
      Sales: ["Dashboard", "Registered Vendors", "Dispute Resolution"],
    };

    return !excludedLinks[userType]?.includes(link.link);
  });

  const handleReset = () => {
    dispatch(setShowProject(false));
    dispatch(setShowVendor(false));
    dispatch(setShowDispute(false));
    dispatch(setActiveTable("transaction"));
    dispatch(setShowBannedAccounts(false));
    dispatch(setShowAgentModal(false));
    dispatch(setShowAdvertModal(false));
    dispatch(setShowDiscountModal(false));
    dispatch(setShowEditAgent(false));
    dispatch(setShowPermisionsModal(false));
  };

  const handleLogout = () => {
    dispatch(setAccessToken(null));
  };

  return (
    <div className="flex w-full flex-col gap-y-6">
      <div className="flex w-full justify-center pt-8">
        <img src={logo} alt="gywde logo" />
      </div>
      <div className="w-full bg-primary bg-opacity-25 p-[0.5px]" />

      <div className="mr-3 flex flex-col gap-y-8 px-2">
        {filteredLinks.map((link) => (
          <Link
            to={`/admin/${link.to}`}
            className={`mx-auto flex w-full items-center gap-x-3 rounded-[5px] px-3 py-4 transition-all ease-in hover:cursor-pointer hover:bg-primary hover:bg-opacity-20 ${link.id === activeId && "bg-primary bg-opacity-20"}`}
            key={link.id}
            onClick={() => {
              setActiveId(link.id);
              handleReset();
            }}
          >
            <img
              src={link.icon}
              alt={link.link}
              className={link.link === "Registered Vendors" ? "w-[25px]" : "w-auto"}
            />
            <span className="text-base font-medium text-primary">{link.link}</span>
          </Link>
        ))}
        <div
          className={`mx-auto mt-20 flex w-full items-center gap-x-3 rounded-[5px] px-3 py-4 transition-all ease-in hover:cursor-pointer hover:bg-primary hover:bg-opacity-20`}
          onClick={handleLogout}
        >
          <img src={logoutIcon} alt="" className="w-[25px]" />
          <span className="text-base font-medium text-primary">Log out</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
