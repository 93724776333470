import React from "react";
import { Provisions } from "../../../../constants";
import Card from "./_components/card";

const ApartSection = () => {
  return (
    <div className="overflow-hidden py-10">
      <div className="flex flex-col items-center">
        <span className="text-[25px] font-semibold text-secondary md:text-[30px]  lg:text-[40px]">
          What Sets Us Apart
        </span>
        <span className="text-base text-lightgray md:text-lg md:text-center w-[70%]">
          At GYWDE, we are pioneers in providing skilled and semi-skilled services tailored for all
          industries and our mission is to bridge the gap between service consumer and service
          provider.
        </span>
      </div>
      <div className="grid grid-cols-1 gap-4 px-10 py-10 md:grid-cols-2 md:px-10 lg:grid-cols-4 lg:gap-2 lg:px-36">
        {Provisions.map((provision) => (
          <Card provision={provision} key={provision.id} />
        ))}
      </div>
    </div>
  );
};

export default ApartSection;
