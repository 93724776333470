const TableHead = () => {
    return (
      <thead>
      <tr className="border-b-[0.5px] border-[#AEAEAE]">
      <th className="text-start py-4 px-10">Project Title</th>
      <th className="text-start py-4 px-10">Project ID</th>
      <th className="text-start py-4 px-10">Date Of Complains</th>
      <th className="text-start py-4 px-10">Status</th>
    </tr>
      </thead>
    )
  }
  
  export default TableHead
  