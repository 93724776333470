import React, { useEffect, useState } from "react";
import AutoComplete from "../../../../../../components/autoComplete";
import { Button } from "../../../../../../components/ui";

const AddBankModal = ({ handleModalClose, handleBankDetails, previousBankDetails, editMode }) => {
  const [closeModal, setCloseModal] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);
  const [showBankList, setShowBankList] = useState(false);
  const [bankName, setBankName] = useState("");
  const [errorState, setErrorState] = useState({
    bankNameError: "",
    accountNoError: "",
    holdersNameError: "",
  });
  const [bankList, setBankList] = useState([]);
  const [details, setDetails] = useState({
    account_no: editMode ? previousBankDetails.accountNo : "",
    holders_name: editMode ? previousBankDetails.holdersName : "",
  });

  const [selectedBank, setSelectedBank] = useState(editMode ? previousBankDetails : {});

  useEffect(() => {
    fetch("https://nigerianbanks.xyz")
      .then((response) => response.json())
      .then((data) => {
        setBankList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleBank = (bank) => {
    setSelectedBank(bank);
    setShowBankList(false);
  };

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const validate = () => {
    let isError = false;
    const errors = {
      bankNameError: "",
      accountNoError: "",
      holdersNameError: "",
    };
    if (!selectedBank) {
      isError = true;
      errors.bankNameError = "Please select bank name";
    }
    if (!details.account_no) {
      isError = true;
      errors.accountNoError = "Please enter account number";
    }
    if (details.account_no.length > 10) {
      isError = true;
      errors.accountNoError = "Please enter a valid account number";
    }
    if (!details.holders_name) {
      isError = true;
      errors.holdersNameError = "Please enter holder's name";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };
  const handleAddBank = () => {
    const error = validate();
    setNotFoundError(true); // set to true if unable to retrieve bank details
    if (!error) {
      const bankDetails = {
        ...selectedBank,
        accountNo: details.account_no,
        holdersName: details.holders_name,
      };
      handleBankDetails(bankDetails);
      handleModalClose(closeModal);
    }
  };
  const handleClose = () => {
    handleModalClose(closeModal);
  };
  return (
    <div className="top0 fixed bottom-[0%] left-0 right-0 z-[50] mx-auto block h-[70vh] animate-slide_up rounded-tl-[30px] rounded-tr-[30px] bg-primary px-4 py-8 md:bottom-[30%] md:top-[15%] md:h-fit md:w-[40%] md:animate-none md:rounded-[5px] md:px-10 md:py-12">
      <div className="absolute right-10 top-2 text-2xl hover:cursor-pointer" onClick={handleClose}>
        x
      </div>
      <div className="flex flex-col justify-center gap-y-4 lg:px-10 lg:py-8">
        <span className={`py-2 text-start text-xl font-semibold text-secondary`}>
          Add bank details
        </span>
        <div className="flex flex-col">
          <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
            Bank Name
          </label>
          <AutoComplete
            data={bankList}
            onSelect={(bank) => setSelectedBank(bank)}
            defaultValue={selectedBank.name}
            placeholder="Search for bank"
          />
          <span className="text-start text-sm text-red-500">{errorState.bankNameError}</span>
        </div>
        <div className="flex flex-col">
          <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
            Account number
          </label>
          <input
            type="text"
            name="account_no"
            id="account_no"
            value={details.account_no}
            onChange={handleChange}
            className={`w-full border px-2 py-4 outline-none ${notFoundError ? "border-[#FF0000]" : "border-[#AEAEAE]"}`}
          />
          <span className="text-start text-sm text-red-500">{errorState.accountNoError}</span>
        </div>
        <div className="flex flex-col">
          <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
            Account holder’s name
          </label>
          <input
            type="text"
            name="holders_name"
            id="holders_name"
            value={details.holders_name}
            onChange={handleChange}
            className="w-full border border-[#AEAEAE] px-2 py-4 outline-none"
          />
          {notFoundError && (
            <span className="mt-2 text-start text-sm text-red-500 md:text-base">
              We are unable to retrieve the account name based on the provided Bank Name and Account
              Number. Please kindly review and correct the information provided
            </span>
          )}
          <span className="text-start text-sm text-red-500">{errorState.holdersNameError}</span>
        </div>
        <div className="mt-4">
          <Button className="w-full" onClick={handleAddBank}>
            Add bank account
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddBankModal;
