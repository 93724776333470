import React from "react";
import { AgentTable, Navbar } from "./_components";

const AgentsPage = ({fetchAllAgent,allAgent}) => {
  return (
    <div>
      <Navbar />
      <AgentTable fetchAllAgent={fetchAllAgent} allAgent={allAgent}/>
    </div>
  );
};

export default AgentsPage;
